import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Api_url } from "../environent";
import "../style/CreateClients.css";
const CreateClient = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    companyName: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    city: "",
    phone: "",
    address: "",
  });
  const [formErrors, setFormErrors] = useState({
    companyName: "",
    name: "",
    email: "",
    phone: "",
    city: "", 
    address: "",
    password: "",
    confirmPassword: "",
    
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = { ...formErrors };

    switch (name) {
      case "companyName":
        errors.companyName =
          value.length < 3
            ? "Company Name must be at least 3 characters long"
            : "";
        break;
      case "name":
        errors.name =
          value.length < 3 ? "Name must be at least 3 characters long" : "";
        break;
      case "email":
        errors.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? "Invalid email address"
          : "";
        break;
        case "phone":
        errors.phone = !/^\d{10}$/.test(value)
          ? "Please enter a valid 10-digit Indian phone number"
          : "";
        break;
        case "city":
          errors.city = "";
          break;
        case "address":
          errors.address = "";
          break;
          case "password":
            errors.password = value.length < 6 ? "Password must be at least 6 characters long" : "";
            if (formData.confirmPassword && value !== formData.confirmPassword) {
              errors.confirmPassword = "Passwords do not match";
            } else {
              errors.confirmPassword = "";
            }
            break;
          case "confirmPassword":
            errors.confirmPassword = value !== formData.password ? "Passwords do not match" : "";
            break;
      default:
        break;
    }

    setFormErrors(errors);
    setFormData({ ...formData, [name]: value });

    // Clear the loading state if eror
    if (Object.values(errors).some((error) => error !== "")) {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

     // Check for form errors
     if (Object.values(formErrors).some((error) => error !== "")) {
      toast.error("Please fix the form errors before submitting.");
      setLoading(false);
      return;
    }

    // Check for empty fields
    if (!formData.companyName) {
      toast.error("Company Name is required.");
      setLoading(false);
      return;
    }
    if (!formData.name) {
      toast.error("Name is required.");
      setLoading(false);
      return;
    }
    if (!formData.email) {
      toast.error("Email is required.");
      setLoading(false);
      return;
    }
    if (!formData.phone) {
      toast.error("Phone number is required.");
      setLoading(false);
      return;
    }
    if (!formData.city) {
      toast.error("City is required.");
      setLoading(false);
      return;
    }
   
    if (!formData.address) {
      toast.error("Address is required.");
      setLoading(false);
      return;
    }
   
    if (!formData.password) {
      toast.error("Password is required.");
      setLoading(false);
      return;
    }
    if (!formData.confirmPassword) {
      toast.error("Confirm Password is required.");
      setLoading(false);
      return;
    }

    // Phone number validity
    if (!/^\d{10}$/.test(formData.phone)) {
      toast.error("Please enter a valid 10-digit phone number.");
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        Api_url + "Client/createClient",
        {
          ...formData,
          role: "client_admin",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Success:", response.data);

      // Reset form after successful submission
      setFormData({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        city: "",
        phone: "",
        address: "",
      });

      // Redirect to the home page
      navigate("/dashboard/AllClient");
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "Email already exists"
      ) {
        toast.error("Email already exists");
      } else {
        toast.error("An error occurred while creating the client.");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mt-4">
      <ToastContainer />
      <h2
        style={{
          backgroundColor: "#21b062",
          color: "white",
          fontWeight: "bold",
          padding: "5px",
          borderRadius: "4px",
          fontSize: "20px",
        }}
      >
        Client Client
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
            <div className="form-group col-md-6">
              <label>
                Company Name<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  formErrors.companyName && "is-invalid"
                }`}
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
              {formErrors.companyName && (
                <div className="invalid-feedback">{formErrors.companyName}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                Client Name<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.name && "is-invalid"}`}
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {formErrors.name && (
                <div className="invalid-feedback">{formErrors.name}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                Email<span className="required">*</span>
              </label>
              <input
                type="email"
                className={`form-control ${formErrors.email && "is-invalid"}`}
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {formErrors.email && (
                <div className="invalid-feedback">{formErrors.email}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                Phone<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.phone && "is-invalid"}`}
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              {formErrors.phone && (
                <div className="invalid-feedback">{formErrors.phone}</div>
              )}
          </div>
            <div className="form-group col-md-6">
              <label>
                City<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.city && "is-invalid"}`}
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
              {formErrors.city && (
                <div className="invalid-feedback">{formErrors.city}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                Address<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.address && "is-invalid"}`}
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
              {formErrors.address && (
                <div className="invalid-feedback">{formErrors.address}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                Password
                <span className="required">*</span>
              </label>
              <input
                type="password"
                className={`form-control ${
                  formErrors.password && "is-invalid"
                }`}
                name="password"
                onChange={handleChange}
                required
              />
              {formErrors.password && (
                <div className="invalid-feedback">{formErrors.password}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                Confirm Password
                <span className="required">*</span>
              </label>
              <input
                type="password"
                className={`form-control ${
                  formErrors.confirmPassword && "is-invalid"
                }`}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              {formErrors.confirmPassword && (
                <div className="invalid-feedback">
                  {formErrors.confirmPassword}
                </div>
              )}
            </div>
        </div>
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            type="submit"
            className={`custom-button ${loading ? "loading" : ""}`}
            onClick={handleSubmit}
            disabled={loading}
          >
             {loading ? "Creating..." : "Create"}
          </button>
          <button
            type="button"
            className="custom-button"
            onClick={handleBack}
            style={{ marginLeft: "10px" }}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateClient;
