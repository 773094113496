import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Api_url } from '../environent';
import "../style/InternallDetailsPage.css";
import Loader from './loader';
import Modal from 'react-modal';

Modal.setAppElement("#root");

const InternalDetails = () => {
  const { id } = useParams();
  const [ticket, setTicket] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Api_url}internal/getAlIintTktBy/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setTicket(response.data.ticket);
      } catch (error) {
        console.error('Error fetching ticket details:', error);
      }
    };

    fetchData();
  }, [id]);

  if (!ticket) {
    return <Loader />;
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="ticket">
      <div className="ticket--center">
        <div className="ticket--center--row">
          <div className="ticket--center--col">
            <strong>Ticket Details</strong>
            <span className="ticket--info--content">Subject: {ticket.subject}</span>
          </div>
          <div className="ticket--center--col">
            <span className="ticket--info--title">Assigned Employee</span>
            <span className="ticket--info--content">Name: {ticket.assignedEmployee?.name || 'NA'}</span>
            <span className="ticket--info--content">Email: {ticket.assignedEmployee?.email || 'NA'}</span>
            <span className="ticket--info--content">Phone: {ticket.assignedEmployee?.phone || 'NA'}</span>
            <span className="ticket--info--content">Address: {ticket.assignedEmployee?.address || 'NA'}</span>
            <span className="ticket--info--content">Manager: {ticket.assignedEmployee?.manager || 'NA'}</span>
            <span className="ticket--info--content">Role: {ticket.assignedEmployee?.role || 'NA'}</span>
          </div>
        </div>
        <div className="ticket--center--row">
          <div className="ticket--center--col">
            <span className="ticket--info--title">General Information</span>
            <span className="ticket--info--content">Client: {ticket.clientName}</span>
            <span className="ticket--info--content">Project Name: {ticket.projectName}</span>
            <span className="ticket--info--content">Project Site: {ticket.projectSite}</span>
            <span className="ticket--info--content">Project Location: {ticket.projectLocation}</span>
            <span className="ticket--info--content">Project Type: {ticket.projectType}</span>
            <span className="ticket--info--content">Error Type: {ticket.errorType}</span>
            <span className="ticket--info--content">Error Details: {ticket.errorDetails}</span>
            <span className="ticket--info--content">Remark: {ticket.remark}</span>
          </div>
          <div className="ticket--center--col">
            <span className="ticket--info--title">Created By</span>
            <span className="ticket--info--content">Name: {ticket.createdBy?.name || 'NA'}</span>
            <span className="ticket--info--content">Email: {ticket.createdBy?.email || 'NA'}</span>
            <span className="ticket--info--content">Phone: {ticket.createdBy?.phone || 'NA'}</span>
            <span className="ticket--info--content">Address: {ticket.createdBy?.address || 'NA'}</span>
            <span className="ticket--info--content">Role: {ticket.createdBy?.role || 'NA'}</span>
          </div>
        </div>
        <div className="ticket--center--row">
          <div className="ticket--center--col">
            <span className="ticket--info--title">Additional Information</span>
            <span className="ticket--info--content">Role: {ticket.role}</span>
            <span className="ticket--info--content">Created At: {new Date(ticket.createdAt).toLocaleString()}</span>
            <span className="ticket--info--content">Updated At: {new Date(ticket.updatedAt).toLocaleString()}</span>
          </div>
          <div className="ticket--center--col">
            <span className="ticket--info--title">Images</span>
            <div className="ticket-images">
              <img
                src={`${Api_url}${ticket.image}`}
                alt="Ticket image"
                className="ticket-image"
                onClick={openModal}
              />
              <img
                src={`${Api_url}${ticket.complete_image}`}
                alt="Completed task image"
                className="ticket-image"
                onClick={openModal}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ticket--end">
        <img
          src="https://www.vlaccess.com/images/vlaccess/vlaccess_logo_tm.png"
          alt="VLAccess Logo"
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-content">
          <img
            src={`${Api_url}${ticket.complete_image}`}
            alt="complete image"
            className="modal-image"
          />
          <button onClick={closeModal} className="modal-close-button">
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default InternalDetails;
