import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { Api_url } from "../environent";
import "../style/AllUsers.css";
import "../style/style2.css";
import Loader from "./loader";

const AllClient = () => {
  const navigate = useNavigate();
  const [allClients, setAllClients] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 10;

  const [searchCompanyName, setSearchCompanyName] = useState("");
  const [searchClientName, setSearchClientName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchAddress, setSearchAddress] = useState("");

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const filteredClients = allClients.filter((client) => {
    return (
      client.companyName.toLowerCase().includes(searchCompanyName.toLowerCase()) &&
      client.name.toLowerCase().includes(searchClientName.toLowerCase()) &&
      client.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
      client.phone.toLowerCase().includes(searchPhone.toLowerCase()) &&
      client.address.toLowerCase().includes(searchAddress.toLowerCase())
    );
  });
  const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getAllClients = async () => {
    try {
      const token = localStorage.getItem("token");
      setLoading(true);
      const response = await axios.get(Api_url + "Client/getAllClients", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllClients(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const editUser = (id) => {
    navigate(`/dashboard/EditClient/${id}`);
  };

  const deleteUser = async (clientId) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this client?"
      );
      if (confirmDelete) {
        const token = localStorage.getItem("token");
        await axios.delete(Api_url + `Client/deleteClientById?id=${clientId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAllClients((prevClients) =>
          prevClients.filter((client) => client._id !== clientId)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllClients();
  }, []);

  const createClient = () => {
    navigate("/dashboard/CreateClient");
  };

  return (
    <div className="container-fluid full_div">
      <div className="d-flex justify-content-between">
        <div style={{marginBottom:"10px"}}>
          <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
            All Clients
          </p>
        </div>
        <div>
          <Button
            style={{
              backgroundColor: "#21b062",
              color: "#fff",
              fontSize: "14px",
            }}
            onClick={createClient}
            variant="contained"
          >
            Create
          </Button>
        </div>
      </div>
      <div>
        <div className="col" style={{ width: "100%" }}>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive project-list table-bordered">
                      <table className="table table-striped table-centered table-nowrap">
                        <thead>
                          <tr>
                          <th scope="col">Sl. No.</th>
                            <th scope="col" style={{width: "15%"}}>
                              Company Name
                              <input
                                type="text"
                                placeholder="Search Company Name"
                                value={searchCompanyName}
                                onChange={(e) => setSearchCompanyName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Client Name
                              <input
                                type="text"
                                placeholder="Search Client Name"
                                value={searchClientName}
                                onChange={(e) => setSearchClientName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Email
                              <input
                                type="text"
                                placeholder="Search Email"
                                value={searchEmail}
                                onChange={(e) => setSearchEmail(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Phone
                              <input
                                type="text"
                                placeholder="Search Phone"
                                value={searchPhone}
                                onChange={(e) => setSearchPhone(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Address
                              <input
                                type="text"
                                placeholder="Search Address"
                                value={searchAddress}
                                onChange={(e) => setSearchAddress(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentClients.map((client,index) => (
                            <tr key={client._id}>
                             <td>{index + 1}</td>
                              <td>{client.companyName}</td>
                              <td>{client.name}</td>
                              <td>{client.email}</td>
                              <td>{client.phone}</td>
                              <td>{client.address}</td>
                              <td>
                                <div className="d-flex">
                                  <Link
                                    className="btn btn-sm btn-outline-primary mr-2"
                                    to={`/dashboard/AllClient/ClientUsers/${client._id}`}
                                    title="View"
                                    style={{
                                      fontSize: "18px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <i className="bi bi-eye"></i>
                                  </Link>
                                  <Link
                                    to={`/dashboard/EditClient/${client._id}`}
                                    className="btn btn-sm btn-outline-success mr-2"
                                    title="Edit"
                                    style={{
                                      fontSize: "18px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </Link>
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() => deleteUser(client._id)}
                                    title="Delete"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="pt-3">
                      <ul
                        className="pagination justify-content-end mb-0"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          left: "0",
                          display: "flex",
                          justifyContent: "center",
                          listStyleType: "none",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          style={{
                            transition: "background-color 0.3s",
                            marginRight: "5px",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage > 1 && paginate(currentPage - 1)
                            }
                            className="page-link"
                            tabIndex="-1"
                            aria-disabled="true"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor:
                                currentPage === 1 ? "#ccc" : "#fff",
                            }}
                          >
                            Previous
                          </button>
                        </li>
                        {Array.from(
                          {
                            length: Math.ceil(filteredClients.length / clientsPerPage),
                          },
                          (_, i) => (
                            <li
                              key={i}
                              className={`page-item ${
                                currentPage === i + 1 ? "active" : ""
                              }`}
                              style={{
                                transition: "background-color 0.3s",
                                marginRight: "5px",
                              }}
                            >
                              <button
                                onClick={() => paginate(i + 1)}
                                className="page-link"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  backgroundColor:
                                    currentPage === i + 1
                                      ? "#007bff"
                                      : "#fff",
                                  borderColor: "#ccc",
                                }}
                              >
                                {i + 1}
                              </button>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${
                            currentPage ===
                            Math.ceil(filteredClients.length / clientsPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          style={{
                            transition: "background-color 0.3s",
                            marginLeft: "5px",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage <
                                Math.ceil(filteredClients.length / clientsPerPage) &&
                              paginate(currentPage + 1)
                            }
                            className="page-link"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor:
                                currentPage ===
                                Math.ceil(filteredClients.length / clientsPerPage)
                                  ? "#ccc"
                                  : "#fff",
                            }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllClient;
