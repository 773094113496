import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from 'react-select'; // Import react-select
import { Api_url } from "../environent";
import { toast } from 'react-toastify'; // Import react-toastify for notifications
import Loader from './loader';
const AssignProject = () => {
  const { userId: paramsUserId } = useParams(); // Get the user ID from URL params
  const [projects, setProjects] = useState([]); // State for projects without managers
  const [selectedProjects, setSelectedProjects] = useState([]); // State for selected projects
  const [loading, setLoading] = useState(false); // Loading state
  const [localUserId, setLocalUserId] = useState(""); // State for local storage user ID

  // Fetch user ID from local storage and projects without manager
  useEffect(() => {
    const storedUserId = localStorage.getItem('id');
    setLocalUserId(storedUserId);
    console.log(paramsUserId);
    const fetchProjectsWithoutManager = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${Api_url}ClientUser/client/${storedUserId}/projects`, { 
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response.ok) {
          setProjects(data.projects); // Set projects in state
        } else {
          console.error('Error fetching projects:', data.message);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    if (storedUserId) {
      fetchProjectsWithoutManager();
    }
  }, []);

  // Prepare options for react-select
  const projectOptions = projects.map((project) => ({
    value: project._id,
    label: project.Project_Name,
  }));

  // Handle form submit to assign selected projects to the user
  const handleAssignProjects = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${Api_url}ClientUser/assignProjectsToClientUser/${paramsUserId}`, {
        method: 'PUT', // Use PUT method
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          projectIds: selectedProjects.length > 0 ? selectedProjects.map(p => p.value) : [] // Handle both single and multiple selections
        }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Projects assigned successfully:', data);
        toast.success('Projects assigned successfully!');
        setSelectedProjects([]); // Clear selected projects after successful assignment
        window.location.reload(); 
      } else {
        console.error('Error assigning projects:', data.message);
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error assigning projects:', error);
      toast.error('Error assigning projects. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="col-md-12">
    <div className="card shadow-sm mt-4">
      <div className="card-body">
        {/* <h5 className="card-title mb-4" style={{ textAlign: 'left' }}>
          Assign Project to User ID: {paramsUserId}
        </h5> */}
        <form onSubmit={handleAssignProjects}>
          <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="col-md-8 mb-3">
              <label htmlFor="projectSelect" className="form-label">
                Select Projects
              </label>
              <Select
                id="projectSelect"
                options={projectOptions}
                value={selectedProjects}
                onChange={setSelectedProjects}
                isMulti
                placeholder="Choose projects..."
                className="assign-select"
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  control: (provided) => ({
                    ...provided,
                    borderColor: '#ced4da',
                    boxShadow: 'none',
                    '&:hover': {
                      borderColor: '#80bdff',
                    },
                  }),
                }}
              />
            </div>
            <div className="col-md-4 text-right">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                style={{ marginTop: '2rem', width: "200px" }}
                disabled={loading}
              >
                {loading ? (
                  <Loader
                    type="ThreeDots"
                    color="#fff"
                    height={25}
                    width={25}
                  />
                ) : (
                  'Assign Projects'
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  );
};

export default AssignProject;
