import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import "../style/AllUsers.css";
import "../style/style2.css";
import { Api_url } from "../environent";
import Loader from "./loader";

const AllProjects = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [allProjects, setAllProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 10;

  const [searchFilters, setSearchFilters] = useState({
    companyName: "",
    projectName: "",
    clientName: "",
    projectSite: "",
    projectLocation: "",
    projectType: "",
  });

  const getAllProjects = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }
  
      const response = await axios.get(Api_url + "project/getAllProjects", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
  
      // Sort the projects by 'createdAt' in descending order
      const sortedProjects = response.data.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
  
      setAllProjects(sortedProjects);
      setFilteredProjects(sortedProjects);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };
  

  const deleteProject = async (id) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this project?"
      );
      if (!confirmed) {
        return;
      }

      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      const response = await axios.delete(
        Api_url + `project/deleteProject/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      getAllProjects();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        alert("Failed to delete project. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  const createProject = () => {
    navigate("/dashboard/CreateProject");
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    filterProjects({ ...searchFilters, [name]: value });
  };

  const filterProjects = (filters) => {
    const filtered = allProjects.filter((project) => {
      return (
        (filters.companyName === "" ||
          (project.client &&
            project.client.companyName
              .toLowerCase()
              .includes(filters.companyName.toLowerCase()))) &&
        (filters.projectName === "" ||
          project.Project_Name.toLowerCase().includes(
            filters.projectName.toLowerCase()
          )) &&
        (filters.clientName === "" ||
          (project.client &&
            project.client.name
              .toLowerCase()
              .includes(filters.clientName.toLowerCase()))) &&
        (filters.projectSite === "" ||
          project.project_site
            .toLowerCase()
            .includes(filters.projectSite.toLowerCase())) &&
        (filters.projectLocation === "" ||
          project.project_location
            .toLowerCase()
            .includes(filters.projectLocation.toLowerCase())) &&
        (filters.projectType === "" ||
          project.project_type
            .toLowerCase()
            .includes(filters.projectType.toLowerCase()))
      );
    });
    setFilteredProjects(filtered);
  };

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container-fluid full_div">
      <div className="d-flex justify-content-between">
        <div  style={{marginBottom:"10px"}}>
          <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
            All Projects
          </p>
        </div>
        <div>
          <Button
            style={{
              backgroundColor: "#21b062",
              color: "#fff",
              fontSize: "14px",
            }}
            onClick={createProject}
            variant="contained"
          >
            Create
          </Button>
        </div>
      </div>

      <div>
        <div className="col" style={{ width: "100%" }}>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive project-list table-bordered">
                      <table className="table table-striped table-centered table-nowrap">
                        <thead>
                          <tr>
                          <th scope="col">Sl. No.</th>
                            <th scope="col" style={{ width: "15%" }}>
                              Company Name
                              <input
                                type="text"
                                name="companyName"
                                placeholder="Search Company Name"
                                value={searchFilters.companyName}
                                onChange={handleSearchChange}
                                className="form-control form-control-sm"
                              />
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              Project Name
                              <input
                                type="text"
                                name="projectName"
                                placeholder="Search Project Name"
                                value={searchFilters.projectName}
                                onChange={handleSearchChange}
                                className="form-control form-control-sm"
                              />
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              Client Name
                              <input
                                type="text"
                                name="clientName"
                                placeholder="Search Client Name"
                                value={searchFilters.clientName}
                                onChange={handleSearchChange}
                                className="form-control form-control-sm"
                              />
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              Project Site
                              <input
                                type="text"
                                name="projectSite"
                                placeholder="Search Project Site"
                                value={searchFilters.projectSite}
                                onChange={handleSearchChange}
                                className="form-control form-control-sm"
                              />
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              Project Location
                              <input
                                type="text"
                                name="projectLocation"
                                placeholder="Search Project Location"
                                value={searchFilters.projectLocation}
                                onChange={handleSearchChange}
                                className="form-control form-control-sm"
                              />
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              Project Type
                              <input
                                type="text"
                                name="projectType"
                                placeholder="Search Project Type"
                                value={searchFilters.projectType}
                                onChange={handleSearchChange}
                                className="form-control form-control-sm"
                              />
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentProjects.map((project, index) => (
                            <tr key={project._id}>
                             <td>{index + 1}</td>
                              <td className="py-1">
                                {project.client
                                  ? project.client.companyName
                                  : "N/A"}
                              </td>
                              <td className="py-1">{project.Project_Name}</td>
                              <td>
                                {project.client ? project.client.name : "N/A"}
                              </td>
                              <td className="py-1">{project.project_site}</td>
                              <td className="py-1">{project.project_location}</td>
                              <td className="py-1">{project.project_type}</td>
                              <td className="py-1">
                                <div className="d-flex">
                                  <Link
                                    to={`/dashboard/EditProject/${project._id}`}
                                    className="btn btn-sm btn-outline-success mr-2"
                                    title="Edit"
                                    style={{
                                      fontSize: "18px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </Link>
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() => deleteProject(project._id)}
                                    title="Delete"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="pt-3">
                      <ul
                        className="pagination justify-content-end mb-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          listStyleType: "none",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          style={{
                            transition: "background-color 0.3s",
                            marginRight: "5px",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage > 1 && paginate(currentPage - 1)
                            }
                            className="page-link"
                            style={{
                              backgroundColor:
                                currentPage === 1 ? "#f0f0f0" : "#fff",
                              border: "1px solid #ddd",
                              borderRadius: "3px",
                              padding: "5px 10px",
                            }}
                          >
                            Previous
                          </button>
                        </li>
                        {[...Array(Math.ceil(filteredProjects.length / projectsPerPage)).keys()].map(
                          (number) => (
                            <li
                              key={number + 1}
                              className={`page-item ${
                                currentPage === number + 1 ? "active" : ""
                              }`}
                              style={{
                                transition: "background-color 0.3s",
                                marginRight: "5px",
                              }}
                            >
                              <button
                                onClick={() => paginate(number + 1)}
                                className="page-link"
                                style={{
                                  backgroundColor:
                                    currentPage === number + 1
                                      ? "#007bff"
                                      : "#fff",
                                  color:
                                    currentPage === number + 1 ? "#fff" : "#007bff",
                                  border: "1px solid #ddd",
                                  borderRadius: "3px",
                                  padding: "5px 10px",
                                }}
                              >
                                {number + 1}
                              </button>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${
                            currentPage ===
                            Math.ceil(filteredProjects.length / projectsPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          style={{
                            transition: "background-color 0.3s",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage <
                                Math.ceil(
                                  filteredProjects.length / projectsPerPage
                                ) && paginate(currentPage + 1)
                            }
                            className="page-link"
                            style={{
                              backgroundColor:
                                currentPage ===
                                Math.ceil(filteredProjects.length / projectsPerPage)
                                  ? "#f0f0f0"
                                  : "#fff",
                              border: "1px solid #ddd",
                              borderRadius: "3px",
                              padding: "5px 10px",
                            }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllProjects;
