import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Api_url } from "../environent";

const ClientCreateTicket = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        description: "",
        priority: "",
        file: null,
    });
    const [formErrors, setFormErrors] = useState({
        description: "",
        priority: "",
        file: "",
    });

    const [projects, setProjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [devices, setDevices] = useState([]);
    const [selectedClient, setSelectedClient] = useState(localStorage.getItem("id") || "");
    const [selectedProject, setSelectedProject] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedDevice, setSelectedDevice] = useState("");
    const [errorType, setErrorType] = useState("");
    const [clientData, setClientData] = useState({});
    const [projectData, setProjectData] = useState({});
    const [categoryids, setCategoryids] = useState("");
    const [deviceData, setDeviceData] = useState({});
    const [image, setImage] = useState(null);

    // Fetch projects when client is selected
    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(Api_url + `Client/getProjectsByClientId/${selectedClient}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setProjects(response.data.projects);
            } catch (error) {
                console.error("Error fetching project data:", error);
            }
        };
        // Fetch client details based on client ID
        const fetchClientDetails = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(Api_url + `Client/getClientById/${selectedClient}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setClientData(response.data);
            } catch (error) {
                console.error("Error fetching client details:", error);
            }
        };
        fetchClientDetails();
        fetchProjectData();
    }, [selectedClient]);

    // Fetch categories when a project is selected
    useEffect(() => {
        if (selectedProject) {
            const fetchCategories = async () => {
                try {
                    const response = await axios.get(Api_url + `Category/getCategoriesInProject/${selectedProject}`);
                    setCategories(response.data);
                } catch (error) {
                    console.error("Error fetching categories:", error);
                }
            };

            fetchCategories();
        } else {
            setCategories([]);
            setDevices([]);
        }
    }, [selectedProject]);

    // Fetch devices when both a project and category are selected
    useEffect(() => {
        if (selectedProject && selectedCategory) {
            const fetchDevices = async () => {
                try {
                    const response = await axios.get(Api_url + `device/getDevicesByProjectAndCategory/${selectedProject}/${selectedCategory}`);
                    setDevices(response.data);
                } catch (error) {
                    console.error("Error fetching devices:", error);
                }
            };
            fetchDevices();
        } else {
            setDevices([]);
        }
    }, [selectedProject, selectedCategory]);
    useEffect(() => {
        if (selectedDevice) {
            const fetchDeviceDetails = async () => {
                try {
                    const token = localStorage.getItem("token");
                    const response = await axios.get(Api_url + `Device/getDeviceById/${selectedDevice}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setDeviceData(response.data);
                } catch (error) {
                    console.error("Error fetching device details:", error);
                }
            };

            fetchDeviceDetails();
        } else {
            setDeviceData({});
        }
    }, [selectedDevice]);
    const handleDropdownChange = (e) => {
        const { name, value } = e.target;

        if (name === "projectName") {
            setSelectedProject(value);
            setSelectedCategory("");
            setSelectedDevice("");
        } else if (name === "categoryName") {
            setSelectedCategory(value);
            setSelectedDevice("");
        } else if (name === "deviceName") {
            setSelectedDevice(value);
        }
    };

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle file upload
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setFormData((prevData) => ({
            ...prevData,
            file: file,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validate form fields
        const errors = {};
        if (!formData.description) errors.description = "Description is required";
        if (!formData.priority) errors.priority = "Priority is required";
        if (!image) errors.file = "File is required";

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setLoading(false);
            return;
        }

        try {
            const token = localStorage.getItem("token");
            const formDataObject = new FormData();
            formDataObject.append("companyId", selectedClient);
            formDataObject.append("projectId", selectedProject);
            formDataObject.append("categoryId", selectedCategory);
            formDataObject.append("deviceId", selectedDevice);
            formDataObject.append("errorDetails", formData.description);
            formDataObject.append("priority", formData.priority);
            formDataObject.append("errorType", errorType);
            formDataObject.append("createdBy", localStorage.getItem("id") || "");
            formDataObject.append("role", localStorage.getItem("role") || "");
            if (image) {
                formDataObject.append("image", image);
            }

            const response = await axios.post(Api_url + "Ticket/createTicket", formDataObject, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log("Success:", response.data);

            // Reset form after submission
            setFormData({
                description: "",
                priority: "",
                file: null,
            });
            setSelectedClient("");
            setSelectedProject("");
            setSelectedCategory("");
            setSelectedDevice("");
            setErrorType("");
            setImage(null);
            navigate("/dashboard");
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred while creating the ticket.");
        } finally {
            setLoading(false);
        }
    };
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <div className="container mt-4">
            <ToastContainer />
            <h2
                style={{
                    backgroundColor: "#21b062",
                    color: "white",
                    fontWeight: "bold",
                    padding: "5px",
                    borderRadius: "4px",
                    fontSize: "20px",
                }}
            >
                Create Ticket
            </h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label>
                            Company Name<span className="required">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={clientData.companyName || ""}
                            readOnly
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label>
                            Project<span className="required">*</span>
                        </label>
                        <select
                            name="projectName"
                            className="form-select form-select-lg"
                            value={selectedProject}
                            onChange={handleDropdownChange}
                            required
                        >
                            <option value="">Select a Project</option>
                            {projects.map((project) => (
                                <option key={project._id} value={project._id}>
                                    {project.Project_Name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Category Dropdown */}
                    <div className="form-group col-md-6">
                        <label>
                            Category<span className="required">*</span>
                        </label>
                        <select
                            name="categoryName"
                            className="form-select form-select-lg"
                            value={selectedCategory}
                            onChange={handleDropdownChange}
                            required
                        >
                            <option value="">Select a Category</option>
                            {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>
                <div className="form-group col-md-6">
                    <label>
                        Device<span className="required">*</span>
                    </label>
                    <select
                        name="deviceName"
                        className="form-select form-select-lg"
                        value={selectedDevice}
                        onChange={handleDropdownChange}
                        required
                    >
                        <option value="">Select a Device</option>
                        {devices.map((device) => (
                            <option key={device._id} value={device._id}>
                                {device.Device_name}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedDevice && (
                    <div className="device-details">
                        <div className="form-group col-md-6">
                            <label htmlFor="deviceMake">Make</label>
                            <input
                                type="text"
                                className="form-control"
                                id="deviceMake"
                                value={deviceData.make || ''}
                                readOnly
                            />
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="deviceModelNo">Model No</label>
                            <input
                                type="text"
                                className="form-control"
                                id="deviceModelNo"
                                value={deviceData.model_no || ''}
                                readOnly
                            />
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="deviceSerialNo">Serial No</label>
                            <input
                                type="text"
                                className="form-control"
                                id="deviceSerialNo"
                                value={deviceData.serial_no || ''}
                                readOnly
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="deviceMAC">MAC Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="deviceMAC"
                                value={deviceData.mac || 'N/A'}
                                readOnly
                            />
                        </div>
                    </div>
                )}
                <div className="form-group col-md-6">
                    <label>
                        Error Type<span className="required">*</span>
                    </label>
                    <select
                        className="form-select form-select-lg"
                        name="errorType"
                        value={errorType}
                        onChange={(e) => setErrorType(e.target.value)}
                    >
                        <option value="" disabled>Select Error Type</option>
                        <option value="Hardware">Hardware</option>
                        <option value="Software">Software</option>
                        <option value="Network">Network</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="form-group col-md-6">
                    <label>
                        Description<span className="required">*</span>
                    </label>
                    <textarea
                        name="description"
                        className="form-control"
                        value={formData.description}
                        onChange={handleChange}
                        required
                    ></textarea>
                    {formErrors.description && (
                        <div className="text-danger">{formErrors.description}</div>
                    )}
                </div>

                <div className="form-group col-md-6">
                    <label>
                        Priority<span className="required">*</span>
                    </label>
                    <select
                        name="priority"
                        className="form-select form-select-lg"
                        value={formData.priority}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select a Priority</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                    </select>
                    {formErrors.priority && (
                        <div className="text-danger">{formErrors.priority}</div>
                    )}
                </div>

                <div className="form-group col-md-6">
                    <label>
                        File<span className="required">*</span>
                    </label>
                    <input
                        type="file"
                        className="form-control"
                        name="file"
                        accept=".jpg,.png,.jpeg,.pdf"
                        onChange={handleFileChange}
                        required
                    />
                    {formErrors.file && (
                        <div className="text-danger">{formErrors.file}</div>
                    )}
                </div>

                <div
                    style={{
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <button
                        type="submit"
                        className={`custom-button ${loading ? "loading" : ""}`}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Creating..." : "Create"}
                    </button>
                    <button
                        type="button"
                        className="custom-button"
                        onClick={handleBack}
                        style={{ marginLeft: "10px" }}
                    >
                        Back
                    </button>
                </div>
                </div>
            </form>
        </div>
    );
};

export default ClientCreateTicket;
