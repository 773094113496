import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Api_url } from "../environent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  faBuilding,
  faUserCircle,
  faPhone,
  faCalendarAlt,
  faUserTie,
  faExclamationTriangle,
  faBug,
  faComments,
  faTools,
  faMicrochip,
  faBarcode,
  faLaptop,
  faMapMarkerAlt,
  faProjectDiagram,
  faFileImage,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "./loader";

const TaskDetailsPage = () => {
  const { id } = useParams();
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const response = await axios.get(`${Api_url}Ticket/getTaskById/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.data) {
          setTask(response.data.task);
        }
      } catch (error) {
        console.error("Error fetching task details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTaskDetails();
  }, [id]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${Api_url}auth/getAllEmployees`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    // Determine if the user is a manager
    const userRole = localStorage.getItem("role");
    if (userRole === "manager") {
      setIsManager(true);
    }
  }, []);

  const handleAssignEmployee = async () => {
    if (!selectedEmployee) return;
    const token = localStorage.getItem("token");
    try {
      setLoading(true);
      console.log("Sending request to assign employee:", selectedEmployee.value, selectedDate);
      const response = await axios.put(
        `${Api_url}Ticket/assignEmployee/${id}`,
        {
          employeeId: selectedEmployee.value,
          assignedDate: selectedDate.toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response received:", response.data);

      // If the task is supposed to update state
      setTask((prevTask) => ({
        ...prevTask,
        assignedEmployee: {
          _id: selectedEmployee.value,
          name: selectedEmployee.label,
        },
        assignedDate: selectedDate,
      }));

    } catch (error) {
      console.error("Error assigning task to employee:", error.response || error);
    } finally {
      setLoading(false);
    }
  };



  if (loading) return <Loader />;
  if (!task) return <p>No Tickets found.</p>;

  const {
    companyId,
    projectId,
    deviceId,
    errorDetails,
    priority,
    errorType,
    createdBy,
    remark,
    createdAt,
    image,
    assignedTo,
    assignedEmployee,
    assignedManager,
  } = task;

  const employeeOptions = employees.map((employee) => ({
    value: employee._id,
    label: employee.name,
  }));
  const imageUrl = image.startsWith("http") ? image : `${Api_url}${image}`;

  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <div className="col-12">
          <div className="col-md-3">
            <div className="mb-4 text-left">
              <p className="text-left card-title ">General Information</p>
              <p className="text-left">
                <FontAwesomeIcon icon={faBuilding} color="#17a2b8" /> Company:{" "}
                {companyId?.companyName || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faUserCircle} color="#007bff" /> Contact
                Person: {companyId?.name || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faPhone} color="#28a745" /> Phone:{" "}
                {companyId?.phone || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faUserTie} color="#6c757d" /> Created by:{" "}
                {createdBy?.name || "NA"}
              </p>
              <p className="text-left">
                {createdAt && (
                  <>
                    <FontAwesomeIcon icon={faCalendarAlt} color="#ff9800" />{" "}
                    Created Date: {new Date(createdAt).toLocaleDateString()}
                  </>
                )}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faUserTie} color="#17a2b8" /> Assigned
                Employee: {assignedTo?.name || "NA"}
              </p>
              {/* <p className="text-left">
                <FontAwesomeIcon icon={faUserTie} color="#ffc107" /> Assigned
                Manager: {assignedManager?.name || "NA"}
              </p> */}
              <div className="card mt-3">
                <div className="card-body">
                  <h5 className="card-title">Description</h5>
                  <p className="card-text text-left">{errorDetails}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-4">
              <p className="text-left card-title ">Device Information</p>
              <p className="text-left">
                <FontAwesomeIcon icon={faMicrochip} color="#6f42c1" /> Device
                Name: {deviceId?.Device_name || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faTools} color="#343a40" /> Make:{" "}
                {deviceId?.make || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faLaptop} color="#dc3545" /> Model:{" "}
                {deviceId?.model_no || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faBarcode} color="#28a745" /> Serial No:{" "}
                {deviceId?.serial_no || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faCalendarAlt} color="#17a2b8" />{" "}
                Warranty Start Date:{" "}
                {deviceId?.warranty?.ome_warranty_start_date
                  ? new Date(
                    deviceId.warranty.ome_warranty_start_date
                  ).toLocaleDateString()
                  : "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faCalendarAlt} color="#dc3545" />{" "}
                Warranty End Date:{" "}
                {deviceId?.warranty?.ome_warranty_end_date
                  ? new Date(
                    deviceId.warranty.ome_warranty_end_date
                  ).toLocaleDateString()
                  : "NA"}
              </p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-4 text-left">
              <p className="text-left card-title ">Project Information</p>
              <p className="text-left mt-3 mb-3">
                <FontAwesomeIcon icon={faProjectDiagram} color="#007bff" />{" "}
                Project Name: {projectId?.Project_Name || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faMapMarkerAlt} color="#17a2b8" /> Site:{" "}
                {projectId?.project_site || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faMapMarkerAlt} color="#28a745" />{" "}
                Location: {projectId?.project_location || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faProjectDiagram} color="#ffc107" />{" "}
                Type: {projectId?.project_type || "NA"}
              </p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-4">
              <p className="text-left card-title ">Additional Information</p>
              <p className="text-left">
                <FontAwesomeIcon icon={faExclamationTriangle} color="#ff9800" />{" "}
                Priority: {priority || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faBug} color="#dc3545" /> Error Type:{" "}
                {errorType || "NA"}
              </p>
              <p className="text-left">
                <FontAwesomeIcon icon={faFileImage} color="#007bff" />{" "}
                {image && (
                  <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                    <img
                      src={imageUrl}
                      alt="Task related"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  </a>
                )}
              </p>
            </div>
          </div>

          {isManager && (
            <div className="col-md-12">
              <div className="card shadow-sm mt-4">
                <div className="card-body">
                  <h5 className="card-title mb-4" style={{ textAlign: 'left' }}>
                    Assign Ticket to Employee
                  </h5>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="employeeSelect" className="form-label">
                        Select Employee
                      </label>
                      <Select
                        id="employeeSelect"
                        options={employeeOptions}
                        value={selectedEmployee}
                        onChange={setSelectedEmployee}
                        placeholder="Choose an employee..."
                        className="assign-select"
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          control: (provided) => ({
                            ...provided,
                            borderColor: '#ced4da',
                            boxShadow: 'none',
                            '&:hover': {
                              borderColor: '#80bdff',
                            },
                          }),
                        }}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="assignDate" className="form-label">
                        Select Date
                      </label>
                      <input
                        type="date"
                        id="assignDate"
                        className="form-control"
                        value={selectedDate.toISOString().split("T")[0]}
                        onChange={(e) => setSelectedDate(new Date(e.target.value))}
                        required
                      />
                    </div>
                  </div>
                  <div className="text-left">
                    <button
                      onClick={handleAssignEmployee}
                      className="btn btn-primary btn-lg "
                      style={{ marginTop: '2rem', width: "200px", marginLeft: "42%" }}
                    >
                      Assign Ticket
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default TaskDetailsPage;
