import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/CreateManager.css";
import { Api_url } from "../environent";

const CreateManager = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = { ...formErrors };

    switch (name) {
      case "name":
        errors.name =
          value.length < 3 ? "Name must be at least 3 characters long" : "";
        break;
      case "email":
        errors.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? "Invalid email address"
          : "";
        break;
      case "password":
        errors.password =
          value.length < 6 ? "Password must be at least 6 characters long" : "";
        break;
      case "confirmPassword":
        errors.confirmPassword =
          value !== formData.password ? "Passwords do not match" : "";
        break;
      case "phone":
        errors.phone = !/^\d{10}$/.test(value)
          ? "Please enter a valid 10-digit phone number"
          : "";
        break;
      case "address":
        errors.address = "";
        break;
      default:
        break;
    }

    setFormErrors(errors);
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (Object.values(formErrors).some((error) => error !== "")) {
      toast.error("Please fix the form errors before submitting.");
      setLoading(false);
      return;
    }
    if (!formData.name) {
      toast.error("Name is required.");
      setLoading(false);
      return;
    }

    if (!formData.email) {
      toast.error("Email is required.");
      setLoading(false);
      return;
    }
    if (!formData.password) {
      toast.error("Password is required.");
      setLoading(false);
      return;
    }

    if (!formData.confirmPassword) {
      toast.error("Confirm Password is required.");
      setLoading(false);
      return;
    }
    if (!formData.phone) {
      toast.error("Phone number is required.");
      setLoading(false);
      return;
    }
    if (!formData.address) {
      toast.error("Address is required.");
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        Api_url + "man/createManager",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Success:", response.data);
      setFormData({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        address: "",
      });
      navigate("/dashboard/AllManager");
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "Email already exists"
      ) {
        toast.error("Email already exists");
      } else {
        toast.error("Please fix the form errors before submitting.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mt-4">
      <ToastContainer />
      <h2 className="form-title">Manager</h2>
      <form onSubmit={handleSubmit} style={{ marginBottom: "40px" }}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Name<span className="required">*</span>:
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.name && "is-invalid"}`}
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {formErrors.name && (
                <div className="invalid-feedback">{formErrors.name}</div>
              )}
            </div>
            <div className="form-group">
              <label>
                Email<span className="required">*</span>:
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.email && "is-invalid"}`}
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {formErrors.email && (
                <div className="invalid-feedback">{formErrors.email}</div>
              )}
            </div>
            <div className="form-group">
              <label>
                Address<span className="required">*</span>:
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.address && "is-invalid"}`}
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
              {formErrors.address && (
                <div className="invalid-feedback">{formErrors.address}</div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Phone<span className="required">*</span>:
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.phone && "is-invalid"}`}
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {formErrors.phone && (
                <div className="invalid-feedback">{formErrors.phone}</div>
              )}
            </div>
            <div className="form-group">
              <label>
                Password<span className="required">*</span>:
              </label>
              <input
                type="password"
                className={`form-control ${
                  formErrors.password && "is-invalid"
                }`}
                name="password"
                onChange={handleChange}
                required
              />
              {formErrors.password && (
                <div className="invalid-feedback">{formErrors.password}</div>
              )}
            </div>
            <div className="form-group">
              <label>
                Role<span className="required">*</span>:
              </label>
              <select
                className="form-control"
                name="role"
                value={formData.role}
                onChange={handleChange}
              >
                <option value="manager">Manager</option>
                <option value="employee">Employee</option>
              </select>
            </div>
            <div className="form-group">
              <label>
                Confirm Password<span className="required">*</span>:
              </label>
              <input
                type="password"
                className={`form-control ${
                  formErrors.confirmPassword && "is-invalid"
                }`}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              {formErrors.confirmPassword && (
                <div className="invalid-feedback">
                  {formErrors.confirmPassword}
                </div>
              )}
            </div>
          </div>
        </div>
        
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            type="submit"
            className={`custom-button ${loading ? "loading" : ""}`}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <div className="loading">
                <span></span>
                <span></span>
                <span></span>
              </div>
            ) : (
              "Create"
            )}
          </button>
          <button
            type="button"
            className="custom-button"
            onClick={handleBack}
            style={{ marginLeft: "10px" }}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateManager;
