import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../style/button.css";
import { Api_url } from "../environent";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
    city: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
    city: "",
  });

  const [isPasswordEditable, setIsPasswordEditable] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${Api_url}auth/fetchUserById/${id}`);
        setFormData(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validation
    switch (name) {
      case "name":
        setErrors({
          ...errors,
          name: value.length < 3 ? "Name must be at least 3 characters long" : "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: value.length < 6 ? "Password must be at least 6 characters long" : "",
          confirmPassword: value !== formData.confirmPassword ? "Passwords do not match" : "",
        });
        break;
      case "confirmPassword":
        setErrors({
          ...errors,
          confirmPassword: value !== formData.password ? "Passwords do not match" : "",
        });
        break;
      case "phone":
        setErrors({
          ...errors,
          phone: !/^\d{10}$/.test(value) ? "Please enter a valid 10-digit phone number" : "",
        });
        break;
      case "address":
        setErrors({ ...errors, address: "" });
        break;
      case "city":
        setErrors({ ...errors, city: "" });
        break;
      default:
        break;
    }
  };

  const handlePasswordEditToggle = () => {
    setIsPasswordEditable(!isPasswordEditable);

    // Clear the password field when entering edit mode
    if (!isPasswordEditable) {
      setFormData({
        ...formData,
        password: "",
        confirmPassword: "",
      });

      // Clear any existing password-related errors
      setErrors({
        ...errors,
        password: "",
        confirmPassword: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if there are any errors, including password matching
    if (Object.values(errors).some((error) => error !== "")) {
      console.error("Form has errors. Please fix them before submitting.");
      return;
    }

    try {
      // Conditionally include password fields in the payload
      const payload = isPasswordEditable
        ? formData
        : {
            ...formData,
            password: undefined,
            confirmPassword: undefined,
          };

      await axios.put(`${Api_url}auth/updateUserById/${id}`, payload);
      navigate(-1);
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mt-4">
      <h2
        style={{
          backgroundColor: "#21b062",
          color: "white",
          fontWeight: "bold",
          padding: "5px",
          borderRadius: "4px",
          fontSize: "20px",
        }}
      >
        Edit User
      </h2>
      <form onSubmit={handleSubmit} style={{ marginBottom: "40px" }}>
        <div className="row">
          <div className="form-group col-md-6">
            <label>Name</label>
            <input
              type="text"
              className={`form-control ${errors.name && "is-invalid"}`}
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name}</div>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>Phone</label>
            <input
              type="text"
              className={`form-control ${errors.phone && "is-invalid"}`}
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {errors.phone && (
              <div className="invalid-feedback">{errors.phone}</div>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>Address</label>
            <input
              type="text"
              className={`form-control ${errors.address && "is-invalid"}`}
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
            {errors.address && (
              <div className="invalid-feedback">{errors.address}</div>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>City</label>
            <input
              type="text"
              className={`form-control ${errors.city && "is-invalid"}`}
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
            {errors.city && (
              <div className="invalid-feedback">{errors.city}</div>
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <label className="custom-radio">
            <input
              type="checkbox"
              checked={isPasswordEditable}
              onChange={handlePasswordEditToggle}
            />
            Edit Password
          </label>
        </div>
        {isPasswordEditable && (
          <div className="row">
            <div className="form-group col-md-6">
              <label>Password</label>
              <input
                type="password"
                className={`form-control ${errors.password && "is-invalid"}`}
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {errors.password && (
                <div className="invalid-feedback">{errors.password}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>Confirm Password</label>
              <input
                type="password"
                className={`form-control ${errors.confirmPassword && "is-invalid"}`}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              {errors.confirmPassword && (
                <div className="invalid-feedback">{errors.confirmPassword}</div>
              )}
            </div>
          </div>
        )}
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button type="submit" className="custom-button">
            Update
          </button>
          <button
            type="button"
            className="custom-button"
            style={{ marginLeft: "10px" }}
            onClick={handleBack}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditUser;
