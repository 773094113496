import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/button.css";
import { Api_url } from "../environent";

const EditProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Project_Name: "",
    project_site: "",
    project_location: "",
    project_type: "",
  });
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token"); // Ensure this matches your token storage logic

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        setLoading(true);
        // Fetch project data
        const projectResponse = await axios.get(
          `${Api_url}project/getProjectById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFormData(projectResponse.data);
      } catch (error) {
        console.error("Error fetching project data:", error);
        toast.error("Failed to fetch project data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [id, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${Api_url}project/updateProject/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Project updated successfully.");
      navigate("/dashboard/AllProjects");
    } catch (error) {
      console.error("Error editing project:", error);
      toast.error("Failed to update project data.");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mt-4">
      <h2
        style={{
          backgroundColor: "#21b062",
          color: "white",
          fontWeight: "bold",
          padding: "5px",
          borderRadius: "4px",
          fontSize: "20px",
        }}
      >
        Edit Project
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Project Name:</label>
              <input
                type="text"
                className="form-control"
                name="Project_Name"
                value={formData.Project_Name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Project Site:</label>
              <input
                type="text"
                className="form-control"
                name="project_site"
                value={formData.project_site}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Project Location:</label>
              <input
                type="text"
                className="form-control"
                name="project_location"
                value={formData.project_location}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Project Type:</label>
              <select
                className="form-select form-select-lg"
                name="project_type"
                value={formData.project_type}
                onChange={handleChange}
              >
                {/* <option value="">Select Project Type</option> */}
                <option value="Type1">Project Type 1</option>
                <option value="Type2">Project Type 2</option>
                <option value="Type3">Project Type 3</option>
              </select>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "40px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button type="submit" className="custom-button">
            Update
          </button>
          <button
            type="button"
            className="custom-button"
            onClick={handleBack}
            style={{ marginLeft: "10px" }}
          >
            Back
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditProject;
