import React, { useState, useEffect } from "react";
import axios from "axios";
import { Api_url } from "../environent"; // Assuming you have a correct path to your environment file
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./loader"; // Assuming you have a loader component

const TaskForm = () => {
  const [clientProjects, setClientProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [devicename, setDevicename] = useState("");
  const [devicemake, setDevicemake] = useState("");
  const [deviceMAC, setDeviceMAC] = useState("");
  const [deviceSLNO, setDeviceSLNO] = useState("");
  const [devicemodelno, setDevicemodelno] = useState("");
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");
  const [priority, setPriority] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const [errorType, setErrorType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientProjects();
  }, []);

  const fetchClientProjects = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const clientId = localStorage.getItem("id");

      if (!token || !clientId) {
        throw new Error("Token or Client ID not found in localStorage");
      }

      const response = await axios.get(
        `${Api_url}project/client/${clientId}/projects`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClientProjects(response.data || []);
    } catch (error) {
      console.error("Error fetching client projects:", error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleProjectSelect = (e) => {
    const projectId = e.target.value;
    const project = clientProjects.find((p) => p._id === projectId);
    setSelectedProject(project);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const supportedFormats = ["image/jpeg", "image/png", "application/pdf"];

    if (file) {
      if (!supportedFormats.includes(file.type)) {
        setImageError("Unsupported file format. Please upload JPG, PNG, or PDF.");
        setImage(null);
      } else if (file.size > 2 * 1024 * 1024) {
        setImageError("Image size should be less than 2MB.");
        setImage(null);
      } else {
        setImage(file);
        setImageError(""); // Clear any previous error message
      }
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedProject || !subject || !details || !priority || !errorType) {
      toast.error("Please fill in all the mandatory fields");
      return;
    }

    const formData = new FormData();
    formData.append(
      "location_details[project_site]",
      selectedProject.project_site
    );
    formData.append(
      "location_details[project_location]",
      selectedProject.project_location
    );
    formData.append(
      "location_details[project_type]",
      selectedProject.project_type
    );
    formData.append("location_details[error_type]", errorType);

    formData.append("device_details[device_name]", devicename);
    formData.append("device_details[make]", devicemake);
    formData.append("device_details[mac]", deviceMAC);
    formData.append("device_details[serial_no]", deviceSLNO);
    formData.append("device_details[model_no]", devicemodelno);

    formData.append("complain_details[subject]", subject);
    formData.append("complain_details[details]", details);

    formData.append("ticket_priority", priority);
    formData.append("remark", "Created");
    if (image) {
      formData.append("image", image);
    }

    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.post(`${Api_url}Task/createTask`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      toast.success("Task created successfully");
      setTimeout(() => {
        navigate(-1);
      }, 1000); // Delay navigation for 1 second after success
      console.log("Task created successfully:", response.data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating task:", error.response.data);
      toast.error("Failed to create task. Please try again.");
    }
  };

  return (
    <div className="container mt-4">
      <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : (
        <form className="row g-3 task-form " onSubmit={handleSubmit}>
          <div className="col-md-6">
            <label htmlFor="projectName" className="form-label">
              Project Name <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <select
                id="projectName"
                className="form-control pb-0 pt-0"
                value={selectedProject ? selectedProject._id : ""}
                onChange={handleProjectSelect}
                required
              >
                <span className="input-group-text">
                <i className="bi bi-caret-down-fill"></i>
              </span>
                <option value="">Select Project</option>
                {clientProjects.map((project) => (
                  <option key={project._id} value={project._id}>
                    {project.Project_Name}
                  </option>
                ))}
              </select>
              
            </div>
          </div>

          <div className="col-md-6">
            <label htmlFor="projectLocation" className="form-label">
              Project Location
            </label>
            <input
              type="text"
              id="projectLocation"
              className="form-control"
              value={selectedProject ? selectedProject.project_location : ""}
              readOnly
              placeholder="Project Location"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="projectSite" className="form-label">
              Project Site
            </label>
            <input
              type="text"
              id="projectSite"
              className="form-control"
              value={selectedProject ? selectedProject.project_site : ""}
              readOnly
              placeholder="Project Site"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="projectType" className="form-label">
              Project Type
            </label>
            <input
              type="text"
              id="projectType"
              className="form-control"
              value={selectedProject ? selectedProject.project_type : ""}
              readOnly
              placeholder="Project Type"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="deviceName" className="form-label">
              Device Name
            </label>
            <input
              type="text"
              id="deviceName"
              className="form-control"
              value={devicename}
              onChange={(e) => setDevicename(e.target.value)}
              placeholder="Enter Device Name"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="deviceMake" className="form-label">
              Device Make
            </label>
            <input
              type="text"
              id="deviceMake"
              className="form-control"
              value={devicemake}
              onChange={(e) => setDevicemake(e.target.value)}
              placeholder="Enter Device Make"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="deviceMAC" className="form-label">
              Device MAC
            </label>
            <input
              type="text"
              id="deviceMAC"
              className="form-control"
              value={deviceMAC}
              onChange={(e) => setDeviceMAC(e.target.value)}
              placeholder="Enter Device MAC"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="deviceSLNO" className="form-label">
              Device Serial Number
            </label>
            <input
              type="text"
              id="deviceSLNO"
              className="form-control"
              value={deviceSLNO}
              onChange={(e) => setDeviceSLNO(e.target.value)}
              placeholder="Enter Device Serial Number"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="deviceModelNo" className="form-label">
              Device Model Number
            </label>
            <input
              type="text"
              id="deviceModelNo"
              className="form-control"
              value={devicemodelno}
              onChange={(e) => setDevicemodelno(e.target.value)}
              placeholder="Enter Device Model Number"
            />
          </div>
          <div className="col-12">
            <div className="row g-3">
              <div className="col-md-6">
                <label htmlFor="subject" className="form-label">
                  Subject <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="subject"
                  className="form-control"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Enter Subject"
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="details" className="form-label">
                  Details <span className="text-danger">*</span>
                </label>
                <textarea
                  id="details"
                  className="form-control"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  placeholder="Enter Details"
                  required
                  rows="3"
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row g-3">
              <div className="col-md-6">
                <label htmlFor="priority" className="form-label">
                  Priority <span className="text-danger">*</span>
                </label>
                <select
                  id="priority"
                  className="form-control pb-0 pt-0"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  required
                >
                  <option value="">Select Priority</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="errorType" className="form-label">
                  Error Type <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="errorType"
                  className="form-control"
                  value={errorType}
                  onChange={(e) => setErrorType(e.target.value)}
                  placeholder="Enter Error Type"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <label htmlFor="image" className="form-label">
              Image
            </label>
            <input
              type="file"
              id="image"
              className="form-control"
              onChange={handleImageChange}
            />
          {imageError && <div className="text-danger mt-1">{imageError}</div>}
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-primary w-100">
              Create Task
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default TaskForm;
