import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/button.css";
import { Api_url } from "../environent";

const CreateProject = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    name: "",
    phone: "",
  });
  const [formData, setFormData] = useState({
    Project_Name: "",
    project_site: "",
    project_location: "",
    project_type: "",
    client: "",
  });

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    const fetchClients = async () => {
      try {
        const response = await axios.get(`${Api_url}Client/getAllClients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (Array.isArray(response.data.data)) {
          setClients(response.data.data);
        } else {
          setClients([]);
        }
      } catch (error) {
        setClients([]);
      }
    };

    fetchClients();
  }, [navigate]);

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "Project_Name":
      case "project_site":
      case "project_location":
        error = value.trim() === "" ? `${name.replace('_', ' ')} is required.` : "";
        break;
      case "project_type":
      case "client":
        error = value === "" ? `${name.replace('_', ' ')} is required.` : "";
        break;
      default:
        break;
    }

    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    return !error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    if (name === "client" && value) {
      fetchClientDetails(value);
    }

    validateField(name, value);
  };

  const fetchClientDetails = async (clientId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${Api_url}Client/getClientById/${clientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedClient({
        name: response.data.name || "",
        phone: response.data.phone || "",
      });
    } catch (error) {
      toast.error("Failed to fetch client details.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    Object.entries(formData).forEach(([name, value]) => {
      if (!validateField(name, value)) {
        errors[name] = "This field is required.";
      }
    });

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      toast.error("Please fix the form errors before submitting.");
      return;
    }

    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${Api_url}project/createProject`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setFormData({
        Project_Name: "",
        project_site: "",
        project_location: "",
        project_type: "",
        client: "",
      });
      setSelectedClient({
        name: "",
        phone: "",
      });
      navigate("/dashboard/AllProjects");
    } catch (error) {
      toast.error("Failed to create project. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/dashboard/AllProjects");
  };

  return (
    <div className="container mt-4">
      <ToastContainer />
      <h2
        style={{
          backgroundColor: "#21b062",
          color: "white",
          fontWeight: "bold",
          padding: "5px",
          borderRadius: "4px",
          fontSize: "20px",
        }}
      >
        Create Project
      </h2>
      <form onSubmit={handleSubmit} style={{ marginBottom: "40px" }}>
        <div className="row">
            <div className="form-group col-md-6">
              <label>
                Client<span className="required">*</span>
              </label>
              <select
                className={`form-select form-select-lg ${formErrors.client ? "is-invalid" : ""}`}
                name="client"
                value={formData.client}
                onChange={handleChange}
              >
                <option value="">Select Client</option>
                {clients.map((client) => (
                  <option key={client._id} value={client._id}>
                    {client.companyName}
                  </option>
                ))}
              </select>
              {formErrors.client && <div className="invalid-feedback">{formErrors.client}</div>}
            </div>
            <div className="form-group col-md-6">
              <label>
                Project Name<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.Project_Name ? "is-invalid" : ""}`}
                name="Project_Name"
                value={formData.Project_Name}
                onChange={handleChange}
              />
              {formErrors.Project_Name && <div className="invalid-feedback">{formErrors.Project_Name}</div>}
            </div>
            <div className="form-group col-md-6">
              <label>Client Name</label>
              <input
                type="text"
                className="form-control"
                value={selectedClient.name}
                readOnly
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Project Site<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.project_site ? "is-invalid" : ""}`}
                name="project_site"
                value={formData.project_site}
                onChange={handleChange}
              />
              {formErrors.project_site && <div className="invalid-feedback">{formErrors.project_site}</div>}
            </div>
            <div className="form-group col-md-6">
              <label>Client Phone</label>
              <input
                type="text"
                className="form-control"
                value={selectedClient.phone}
                readOnly
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Project Type<span className="required">*</span>
              </label>
              <select
                className={`form-select form-select-lg ${formErrors.project_type ? "is-invalid" : ""}`}
                name="project_type"
                value={formData.project_type}
                onChange={handleChange}
              >
                <option value="">Select Project Type</option>
                <option value="Type1">Type 1</option>
                <option value="Type2">Type 2</option>
                <option value="Type3">Type 3</option>
              </select>
              {formErrors.project_type && <div className="invalid-feedback">{formErrors.project_type}</div>}
            </div>
            <div className="form-group col-md-6">
              <label>
                Project Location<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.project_location ? "is-invalid" : ""}`}
                name="project_location"
                value={formData.project_location}
                onChange={handleChange}
              />
              {formErrors.project_location && <div className="invalid-feedback">{formErrors.project_location}</div>}
            </div>
        </div>
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            type="submit"
            className={`custom-button ${loading ? "loading" : ""}`}
            onClick={handleSubmit}
            disabled={loading}
          >
             {loading ? "Creating..." : "Create"}
          </button>
          <button
            type="button"
            className="custom-button"
            onClick={handleBack}
            style={{ marginLeft: "10px" }}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateProject;
