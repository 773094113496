import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./loader";
import { Button } from "react-bootstrap";
import { Api_url } from "../environent";
import axios from "axios";

function AllInternalTickets() {
  const navigate = useNavigate();
  const [allIntTkts, setAllIntTkts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 10; 
  
  const createInternalTask = () => {
    navigate("/dashboard/InternalTicket/createtkt");
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(Api_url + "internal/getAllInternalTickets", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setAllIntTkts(response.data.tickets);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleArrowClick = (ticketId) => {
    navigate(`/dashboard/InternalTicket/${ticketId}`);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate current tasks
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = allIntTkts.slice(indexOfFirstTask, indexOfLastTask);

  return (
    <div className="container-fluid full_div">
      <div className="d-flex justify-content-between">
        <div>
          <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
            Internal Tickets
          </p>
        </div>
        <div>
          <Button
            style={{
              backgroundColor: "#21b062",
              color: "#fff",
              fontSize: "14px",
            }}
            onClick={createInternalTask}
            variant="contained"
          >
            Create Ticket
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              {loading ? (
                <Loader />
              ) : (
                <div className="table-responsive project-list table-bordered">
                  <table className="table project-table table-centered table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "5%" }}>Sl No</th>
                        <th scope="col" style={{ width: "15%" }}>
                          Ticket Name
                          <input
                            type="text"
                            name="ticketName"
                            className="form-control"
                            placeholder="Filter"
                          />
                        </th>
                        <th scope="col" style={{ width: "15%" }}>
                          Employee Name
                          <input
                            type="text"
                            name="employeeName"
                            className="form-control"
                            placeholder="Filter"
                          />
                        </th>
                        <th scope="col" style={{ width: "15%" }}>
                          Client Name
                          <input
                            type="text"
                            name="clientName"
                            className="form-control"
                            placeholder="Filter"
                          />
                        </th>
                        <th scope="col" style={{ width: "15%" }}>
                          Project Name
                          <input
                            type="text"
                            name="projectName"
                            className="form-control"
                            placeholder="Filter"
                          />
                        </th>
                        <th scope="col" style={{ width: "10%" }}>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTasks.map((ticket, index) => (
                        <tr key={ticket._id}>
                          <td>{indexOfFirstTask + index + 1}</td>
                          <td>{ticket.subject}</td>
                          <td>{ticket.assignedEmployeeData && ticket.assignedEmployeeData.length > 0 ? ticket.assignedEmployeeData[0].name : 'NA'}</td>
                          <td>{ticket.clientName}</td>
                          <td>{ticket.projectName}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => handleArrowClick(ticket._id)}
                            >
                              <i className="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="pt-3">
                <ul
                  className="pagination justify-content-end mb-0"
                  style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    left: "0",
                    display: "flex",
                    justifyContent: "center",
                    listStyleType: "none",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  <li
                    className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                    style={{
                      transition: "background-color 0.3s",
                      marginRight: "5px",
                    }}
                  >
                    <button
                      onClick={() =>
                        currentPage > 1 && paginate(currentPage - 1)
                      }
                      className="page-link"
                      tabIndex="-1"
                      aria-disabled="true"
                      style={{
                        textDecoration: "none",
                        color: "#000",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        backgroundColor: currentPage === 1 ? "#ccc" : "#fff",
                      }}
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from(
                    { length: Math.ceil(allIntTkts.length / tasksPerPage) },
                    (_, i) => (
                      <li
                        key={i}
                        className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                        style={{
                          transition: "background-color 0.3s",
                          marginRight: "5px",
                        }}
                      >
                        <button
                          onClick={() => paginate(i + 1)}
                          className="page-link"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            backgroundColor: currentPage === i + 1 ? "#007bff" : "#fff",
                            borderColor: "#ccc",
                          }}
                        >
                          {i + 1}
                        </button>
                      </li>
                    )
                  )}
                  <li
                    className={`page-item ${
                      currentPage === Math.ceil(allIntTkts.length / tasksPerPage) ? "disabled" : ""
                    }`}
                    style={{
                      transition: "background-color 0.3s",
                      marginLeft: "5px",
                    }}
                  >
                    <button
                      onClick={() =>
                        currentPage < Math.ceil(allIntTkts.length / tasksPerPage) &&
                        paginate(currentPage + 1)
                      }
                      className="page-link"
                      style={{
                        textDecoration: "none",
                        color: "#000",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        backgroundColor: currentPage === Math.ceil(allIntTkts.length / tasksPerPage) ? "#ccc" : "#fff",
                      }}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllInternalTickets;
