import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../style/button.css";
import { Api_url } from '../environent';

const EditDevice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Device_name: '',
    make: '',
    mac: '',
    serial_no: '',
    model_no: '',
    category: '',
    ome_warranty_start_date: '',
    ome_warranty_end_date: ''
  });
  const [categories, setCategories] = useState([]);
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDeviceData = async () => {
      try {
        const response = await axios.get(`${Api_url}device/getDeviceById/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log('Fetched device data:', response.data);
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching device data:', error);
        toast.error('Failed to fetch device data.');
      }
    };

    fetchDeviceData();
  }, [id, token]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryResponse = await axios.get(`${Api_url}Category/AllCategories`);
        console.log("Categories response:", categoryResponse.data);
        if (Array.isArray(categoryResponse.data)) {
          setCategories(categoryResponse.data);
        } else {
          toast.error("Failed to fetch categories: Invalid data format.");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Failed to fetch categories.");
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { ome_warranty_start_date, ome_warranty_end_date } = formData;

    if (new Date(ome_warranty_end_date) < new Date(ome_warranty_start_date)) {
      toast.error('OME Warranty End Date cannot be earlier than Start Date.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.put(`${Api_url}device/updateDevice/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Response from update:', response);
      toast.success('Device updated successfully.');
      navigate('/dashboard/AllDevices');
    } catch (error) {
      console.error('Error editing device:', error);
      toast.error('Failed to update device data.');
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mt-4">
      <h2 style={{ backgroundColor: '#21b062', color: 'white', fontWeight: 'bold', padding: '5px', borderRadius: '4px', fontSize: '20px' }}>
        Edit Device
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Device Name</label>
              <input
                type="text"
                className="form-control"
                name="Device_name"
                value={formData.Device_name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Make</label>
              <input
                type="text"
                className="form-control"
                name="make"
                value={formData.make}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>MAC</label>
              <input
                type="text"
                className="form-control"
                name="mac"
                value={formData.mac}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Serial No</label>
              <input
                type="text"
                className="form-control"
                name="serial_no"
                value={formData.serial_no}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Model No</label>
              <input
                type="text"
                className="form-control"
                name="model_no"
                value={formData.model_no}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Category</label>
              <select
                className="form-control pb-0 pt-0"
                name="category"
                value={formData.category}
                onChange={handleChange}
              >
                <option value="">Select Category</option>
                {categories.map(category => (
                  <option key={category._id} value={category._id}>{category.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>OME Warranty Start Date</label>
              <input
                type="date"
                className="form-control"
                name="ome_warranty_start_date"
                value={formData.ome_warranty_start_date}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>OME Warranty End Date</label>
              <input
                type="date"
                className="form-control"
                name="ome_warranty_end_date"
                value={formData.ome_warranty_end_date}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            type="submit"
            className={`custom-button ${loading ? "loading" : ""}`}
            disabled={loading}
          >
            {loading ? "Updating..." : "Update"}
          </button>
          <button
            type="button"
            className="custom-button"
            onClick={handleBack}
            style={{ marginLeft: "10px" }}
          >
            Back
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditDevice;
