import React, { useState, useEffect  } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/CreateEmployees.css";
import { Api_url } from "../environent";

const CreateUsers = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
    city: "",
    role: "", // Removed default role
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    phone: "",
    role: "",
    password: "",
    confirmPassword: "",
  });
  const [currentUserRole, setCurrentUserRole] = useState("");

  useEffect(() => {
    const userRole = localStorage.getItem("role") || ""; 
    console.log(userRole);
    setCurrentUserRole(userRole);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = { ...formErrors };

    switch (name) {
      case "name":
        errors.name =
          value.length < 3 ? "Name must be at least 3 characters long" : "";
        break;
      case "email":
        errors.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? "Invalid email address"
          : "";
        break;
        case "password":
          errors.password = value.length < 6 ? "Password must be at least 6 characters long" : "";
    
          // Check if confirmPassword matches the updated password
          if (formData.confirmPassword && value !== formData.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
          } else {
            errors.confirmPassword = "";
          }
          break;
        case "confirmPassword":
          errors.confirmPassword = value !== formData.password ? "Passwords do not match" : "";
          break;
      case "phone":
        errors.phone = !/^\d{10}$/.test(value)
          ? "Please enter a valid 10-digit phone number"
          : "";
        break;
      case "address":
        errors.address = "";
        break;
      case "city":
        errors.city = "";
        break;
      case "role":
        errors.role = value === "" ? "Role is required" : "";
        break;
      default:
        break;
    }

    setFormErrors(errors);
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (Object.values(formErrors).some((error) => error !== "")) {
      toast.error("Please fix the form errors before submitting.");
      setLoading(false);
      return;
    }
    if (!formData.name) {
      toast.error("Name is required.");
      setLoading(false);
      return;
    }

    if (!formData.email) {
      toast.error("Email is required.");
      setLoading(false);
      return;
    }
    if (!formData.address) {
      toast.error("Address is required.");
      setLoading(false);
      return;
    }
    if (!formData.city) {
      toast.error("City is required.");
      setLoading(false);
      return;
    }
    if (!formData.phone) {
      toast.error("Phone number is required.");
      setLoading(false);
      return;
    }
  
    if (!formData.role) {
      toast.error("Role is required.");
      setLoading(false);
      return;
    }
    if (!formData.password) {
      toast.error("Password is required.");
      setLoading(false);
      return;
    }

    if (!formData.confirmPassword) {
      toast.error("Confirm Password is required.");
      setLoading(false);
      return;
    }
 
    try {
      const token = localStorage.getItem("token");
      const endpoint =
        formData.role === "manager"
          ? "auth/createManager"
          : "auth/createEmployee";
      const response = await axios.post(`${Api_url}${endpoint}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Success:", response.data);
      setFormData({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        address: "",
        city: "",
        role: "",
      });
      navigate("/dashboard/AllUsers");
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "Email already exists"
      ) {
        toast.error("Email already exists");
      } else {
        toast.error("Please fix the form errors before submitting.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mt-4">
      <ToastContainer />
      <h2 className="form-title">Create User</h2>
      <form onSubmit={handleSubmit} style={{ marginBottom: "40px" }}>
        <div className="row">
            <div className="form-group col-md-6">
              <label>
                Name<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.name && "is-invalid"}`}
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {formErrors.name && (
                <div className="invalid-feedback">{formErrors.name}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                Email<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.email && "is-invalid"}`}
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {formErrors.email && (
                <div className="invalid-feedback">{formErrors.email}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                Address<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.address && "is-invalid"}`}
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
              {formErrors.address && (
                <div className="invalid-feedback">{formErrors.address}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                City<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.city && "is-invalid"}`}
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
              {formErrors.city && (
                <div className="invalid-feedback">{formErrors.city}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                Phone<span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.phone && "is-invalid"}`}
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {formErrors.phone && (
                <div className="invalid-feedback">{formErrors.phone}</div>
              )}
            </div>
            <div className="form-group col-md-6">
            <label>
              Role<span className="required">*</span>
            </label>
            <select
              className={`form-select form-select-lg ${
                formErrors.role && "is-invalid"
              }`}
              name="role"
              value={formData.role}
              onChange={handleChange}
            >
              <option value="">Select Role</option>
              {currentUserRole === "manager" ? (
                <option value="employee">Employee</option>
              ) : (
                <>
                  <option value="manager">Manager</option>
                  <option value="employee">Employee</option>
                </>
              )}
            </select>
            {formErrors.role && (
              <div className="invalid-feedback">{formErrors.role}</div>
            )}
          </div>
            <div className="form-group col-md-6">
              <label>
                Password<span className="required">*</span>
              </label>
              <input
                type="password"
                className={`form-control ${
                  formErrors.password && "is-invalid"
                }`}
                name="password"
                onChange={handleChange}
                required
              />
              {formErrors.password && (
                <div className="invalid-feedback">{formErrors.password}</div>
              )}
            </div>
            <div className="form-group col-md-6">
              <label>
                Confirm Password<span className="required">*</span>
              </label>
              <input
                type="password"
                className={`form-control ${
                  formErrors.confirmPassword && "is-invalid"
                }`}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              {formErrors.confirmPassword && (
                <div className="invalid-feedback">
                  {formErrors.confirmPassword}
                </div>
              )}
            </div>
        </div>
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            type="submit"
            className={`custom-button ${loading ? "loading" : ""}`}
            onClick={handleSubmit}
            disabled={loading}
          >
             {loading ? "Creating..." : "Create"}
          </button>
          <button
            type="button"
            className="custom-button"
            onClick={handleBack}
            style={{ marginLeft: "10px" }}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUsers;
