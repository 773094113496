//Dashboard
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import vlaccessLogo from "./vlaccess_logo_tm.png";
import CreateEmployees from "./CreateEmployees";
import { jwtDecode } from "jwt-decode";
import CreateManager from "./createManager";
// import NotificationPage from "./NotificationPage";
import UpdateManager from "./UpdateManager";
import ClientCreateTicket from "./ClientCreateTicket";
import ProjectDevices from "./ProjectDevices";
// import ClientCreateTicket from "./ClientCreateTicket";
import AddCategories from "./AddCategories";
import ClientUserProjects from "./ClientUserProjects";
import CreateClientUsers from "./CreateClientUsers";
import ClientUserAllTask from "./ClientUserAllTask";
import DeviceDetails from "./DeviceDetails";
import AssignProject from "./AssignProject";
import CreateUsers from "./CreateUsers";
import AllUsers from "./AllUsers";
import ClientDevices from "./ClientDevices";
import ClientAllUsers from "./ClientAllUsers";
import ClientUsers from "./ClientUsers";
import AllEmployees from "./AllEmployees";
import AllManager from "./AllManager";
import AllDevices from "./AllDevices";
import EditDevice from "./EditDevice";
import CreateDevice from "./CreateDevice";
import AllProjects from "./AllProjects";
import ClientProjects from "./ClientProjects";
import CreateProject from "./CreateProject";
import CreateTicketPage from "./CreateTicketPage";
import EditProject from "./EditProject";
import EditAllEmployees from "./EditAllEmployees";
import EditUser from "./EditUser";
import EditClientUser from "./EditClientUser";
import ChangePassword from "./ChangePassword";
import AllTasks from "./AllTask";
import CreateClient from "./CreateClient";
import EditClient from "./EditClient";
import TableTas from "./TableTas";
import Map from "./Map";
import AllClient from "./AllClient";
import "../style/AllUsers.css";
import { Link } from "react-router-dom";
import TaskDetailsPage from "./TaskDetailsPage";
import CreateTask from "./CreateTask";
import CreateInternalTicket from "./CreateInternalTicket";
// import { Dropdown } from "react-bootstrap";
import "../style/HoverEffect.css";
import AllInternalTickets from "./AllInternalTickets";
import InternalDetails from "./InternalDetails";
const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 668);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [userData, setUserData] = useState({});

  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([
    { id: 1, type: "warning", message: "Lorem Ipsum", time: "30 min. ago" },
    {
      id: 2,
      type: "danger",
      message: "Atque rerum nesciunt",
      time: "1 hr. ago",
    },
    { id: 3, type: "success", message: "Sit rerum fuga", time: "2 hrs. ago" },
    {
      id: 4,
      type: "primary",
      message: "Dicta reprehenderit",
      time: "4 hrs. ago",
    },
  ]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decodeData = jwtDecode(token);
    localStorage.setItem("role", decodeData.role);
    localStorage.setItem("email", decodeData.email);
    localStorage.setItem("id", decodeData.id);
    setUserData(decodeData);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 668);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log(userData);

  const handleItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  const handleNotificationClick = () => {
    navigate("/dashboard/NotificationPage");
  };

  const handleNavigateToChangePassword = () => {
    navigate("/dashboard/ChangePassword");
  };
  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    window.location.reload();
  };

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <a href="" className="logo d-flex align-items-center">
            <h1>
              <img
                src={vlaccessLogo}
                alt="VLAcess Logo"
                className="logo-image"
              />
            </h1>
          </a>
        </div>

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            {/* <li className="nav-item dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  as="a"
                  className="nav-link nav-icon"
                  id="dropdown-notifications"
                  style={{ cursor: "pointer" }}
                  onClick={handleNotificationClick}
                >
                  <i className="bi bi-bell"></i>
                  <span className="badge bg-primary badge-number">
                    {notifications.length}
                  </span>
                </Dropdown.Toggle>
              </Dropdown>
            </li> */}

            <li class="nav-item dropdown pe-3">
              {userData.role === "admin" ? (
                <a
                  className="nav-link nav-profile d-flex align-items-center pe-0"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src="assets/img/admin2.png"
                    alt="Profile"
                    className="rounded-circle"
                  />
                  <span className="d-none d-md-block dropdown-toggle ps-2">
                    ADMIN
                  </span>
                </a>
              ) : userData.role === "manager" ? (
                <a
                  className="nav-link nav-profile d-flex align-items-center pe-0"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src="assets/img/manager.png"
                    alt="Profile"
                    className="rounded-circle"
                  />
                  <span className="d-none d-md-block dropdown-toggle ps-2">
                    MANAGER
                  </span>
                </a>
              ) : userData.role === "client_admin" ? (
                <a
                  className="nav-link nav-profile d-flex align-items-center pe-0"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src="assets/img/client.png"
                    alt="Profile"
                    className="rounded-circle"
                  />
                  <span className="d-none d-md-block dropdown-toggle ps-2">
                    CLIENT Admin
                  </span>
                </a>
              ):
              <a
                  className="nav-link nav-profile d-flex align-items-center pe-0"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src="assets/img/client.png"
                    alt="Profile"
                    className="rounded-circle"
                  />
                  <span className="d-none d-md-block dropdown-toggle ps-2">
                    CLIENT USER
                  </span>
                </a>
              }

              <ul className="dropdown-menu dropdown-menu-end">
                <li className="dropdown-header">
                  <span>Profile</span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="calander">
                  <h4>
                    {localStorage.getItem("role")}:
                    <span className="dropdown-item">
                      {localStorage.getItem("email")}
                    </span>
                  </h4>
                  {/* Conditionally render Change Password option if role is not admin */}
                  {localStorage.getItem("role") !== "admin" && (
                    <li>
                      <span
                        onClick={handleNavigateToChangePassword}
                        style={{ cursor: "pointer" }}
                        className="dropdown-item change-password-btn"
                      >
                        Change Password
                      </span>
                    </li>
                  )}
                  <span
                    onClick={logoutHandler}
                    style={{ cursor: "pointer" }}
                    className="dropdown-item"
                  >
                    <i
                      style={{ color: "red" }}
                      className="bi bi-box-arrow-right"
                    ></i>
                    Sign Out
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>

      <aside
        id="sidebar"
        className={`sidebar left-aligned ${sidebarOpen ? "open" : ""}`}
      >
        <ul className="sidebar-nav nav flex-column" id="sidebar-nav">
          {userData.role === "manager" && (
            <>
              <li
                className={`nav-item ${activeMenuItem === "task" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/"
                  onClick={() => handleItemClick("task")}
                >
                  <i className="bi bi-ticket-perforated-fill me-2"></i>
                  <span> Tickets</span>
                </Link>
              </li>

              <li
                className={`nav-item ${activeMenuItem === "users" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/AllUsers"
                  onClick={() => handleItemClick("users")}
                >
                  <i className="bi bi-person-circle me-2"></i>
                  <span>User</span>
                </Link>
              </li>

              <li
                className={`nav-item ${activeMenuItem === "client" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/AllClient"
                  onClick={() => handleItemClick("client")}
                >
                  <i className="bi bi-person-badge me-2"></i>
                  <span>Client</span>
                </Link>
              </li>

              <li
                className={`nav-item ${activeMenuItem === "projects" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/AllProjects"
                  onClick={() => handleItemClick("projects")}
                >
                  <i className="bi bi-geo me-2"></i>
                  <span>Project</span>
                </Link>
              </li>

              <li
                className={`nav-item ${activeMenuItem === "devices" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="#"
                  onClick={() => handleItemClick("devices")}
                  data-bs-toggle="collapse"
                  data-bs-target="#devicesSubmenu"
                  aria-expanded="false"
                  aria-controls="devicesSubmenu"
                >
                  <i className="bi bi-hdd-network-fill me-2"></i>
                  <span>Device</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
                <ul
                  className={`collapse ${activeMenuItem === "devices" ? "show" : ""
                    }`}
                  id="devicesSubmenu"
                >
                  <li
                    className={`nav-item ${activeMenuItem === "all-devices" ? "active" : ""
                      }`}
                  >
                    <Link
                      className="nav-link collapsed hover-effect d-flex align-items-center"
                      to="/dashboard/AllDevices"
                      onClick={() => handleItemClick("all-devices")}
                    >
                      <i className="bi bi-hdd-fill me-2"></i>
                      <span>All Devices</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${activeMenuItem === "device-categories" ? "active" : ""
                      }`}
                  >
                    <Link
                      className="nav-link collapsed hover-effect d-flex align-items-center"
                      to="/dashboard/AddCategories"
                      onClick={() => handleItemClick("device-categories")}
                    >
                      <i className="bi bi-list-ul me-2"></i>
                      <span>Add Category</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          )}

          {userData.role === "client_admin" && (
            <>
              <li
                className={`nav-item ${activeMenuItem === "task" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/"
                  onClick={() => handleItemClick("task")}
                >
                  <i className="bi bi-ticket-perforated-fill me-2"></i>
                  <span> Tickets</span>
                </Link>
              </li>
              <li
                className={`nav-item ${activeMenuItem === "users" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/ClientAllUsers"
                  onClick={() => handleItemClick("users")}
                >
                  <i className="bi bi-person-check me-2"></i>
                  <span>Users</span>
                </Link>
              </li>
              <li
                className={`nav-item ${activeMenuItem === "projects" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/ClientProjects"
                  onClick={() => handleItemClick("projects")}
                >
                  <i className="bi bi-geo me-2"></i>
                  <span>My Projects</span>
                </Link>
              </li>
              <li
                className={`nav-item ${activeMenuItem === "Client Devices" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/ClientDevices"
                  onClick={() => handleItemClick("CLient Devices")}
                >
                  <i className="bi bi-ticket-perforated-fill me-2"></i>
                  <span> Devices</span>
                </Link>
              </li>
            </>
          )}

          {userData.role === "client_user" && (
            <>
              <li
                className={`nav-item ${activeMenuItem === "task" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/"
                  onClick={() => handleItemClick("task")}
                >
                  <i className="bi bi-ticket-perforated-fill me-2"></i>
                  <span> Tickets</span>
                </Link>
              </li>

              <li
                className={`nav-item ${activeMenuItem === "projects" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/ClientUserProjects"
                  onClick={() => handleItemClick("projects")}
                >
                  <i className="bi bi-geo me-2"></i>
                  <span>My Projects</span>
                </Link>
              </li>
              <li
                className={`nav-item ${activeMenuItem === "Client Devices" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/ClientDevices"
                  onClick={() => handleItemClick("CLient Devices")}
                >
                  <i className="bi bi-ticket-perforated-fill me-2"></i>
                  <span> Devices</span>
                </Link>
              </li>
            </>
          )}


          {userData.role === "admin" && (
            <>
              <li
                className={`nav-item ${activeMenuItem === "task" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/"
                  onClick={() => handleItemClick("task")}
                >
                  <i className="bi bi-ticket-perforated-fill me-2"></i>
                  <span> Tickets</span>
                </Link>
              </li>

              <li
                className={`nav-item ${activeMenuItem === "users" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/AllUsers"
                  onClick={() => handleItemClick("users")}
                >
                  <i className="bi bi-person-check me-2"></i>
                  <span>Users</span>
                </Link>
              </li>

              <li
                className={`nav-item ${activeMenuItem === "client" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/AllClient"
                  onClick={() => handleItemClick("client")}
                >
                  <i className="bi bi-person-badge me-2"></i>
                  <span>Client</span>
                </Link>
              </li>

              <li
                className={`nav-item ${activeMenuItem === "projects" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="/dashboard/AllProjects"
                  onClick={() => handleItemClick("projects")}
                >
                  <i className="bi bi-geo me-2"></i>
                  <span>Project</span>
                </Link>
              </li>

              <li
                className={`nav-item ${activeMenuItem === "devices" ? "active" : ""
                  }`}
              >
                <Link
                  className="nav-link collapsed hover-effect d-flex align-items-center"
                  to="#"
                  onClick={() => handleItemClick("devices")}
                  data-bs-toggle="collapse"
                  data-bs-target="#devicesSubmenu"
                  aria-expanded="false"
                  aria-controls="devicesSubmenu"
                >
                  <i className="bi bi-hdd-network-fill me-2"></i>
                  <span>Device</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
                <ul
                  className={`collapse ${activeMenuItem === "devices" ? "show" : ""
                    }`}
                  id="devicesSubmenu"
                >
                  <li
                    className={`nav-item ${activeMenuItem === "all-devices" ? "active" : ""
                      }`}
                  >
                    <Link
                      className="nav-link collapsed hover-effect d-flex align-items-center"
                      to="/dashboard/AllDevices"
                      onClick={() => handleItemClick("all-devices")}
                    >
                      <i className="bi bi-hdd-fill me-2"></i>
                      <span>All Devices</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${activeMenuItem === "device-categories" ? "active" : ""
                      }`}
                  >
                    <Link
                      className="nav-link collapsed hover-effect d-flex align-items-center"
                      to="/dashboard/AddCategories"
                      onClick={() => handleItemClick("device-categories")}
                    >
                      <i className="bi bi-list-ul me-2"></i>
                      <span>Add Category</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          )}
        </ul>
      </aside>

      <main id="main" class="main">
        <Routes>
          <Route exact path="/" element={<AllTasks />} />
          <Route path="/CreateEmployees" element={<CreateEmployees />} />
          <Route path="/editAllEmployees/:id" element={<EditAllEmployees />} />
          <Route path="/editUser/:id" element={<EditUser />} />

          <Route path="/AllEmployees" element={<AllEmployees />} />
          <Route path="/AllClient" element={<AllClient />} />
          <Route path="/AllClient/ClientUsers/:id" element={<ClientUsers />} />
          <Route path="/CreateClient" element={<CreateClient />} />
          <Route
            path="/CreateClientUsers/:clientId"
            element={<CreateClientUsers />}
          />
          <Route path="/AllUsers" element={<AllUsers />} />
          <Route path="/ClientAllUsers" element={<ClientAllUsers />} />
          <Route path="/ClientUserAllTask" element={<ClientUserAllTask />} />
          <Route path="/ClientUserProjects" element={<ClientUserProjects />} />

          <Route path="/ClientDevices" element={<ClientDevices />} />

          <Route path="/CreateDevice" element={<CreateDevice />} />
          <Route path="/CreateUsers" element={<CreateUsers />} />
          <Route path="/CreateProject" element={<CreateProject />} />
          <Route path="/AllDevices" element={<AllDevices />} />
          <Route path="/AddCategories" element={<AddCategories />} />
          <Route path="/AllProjects" element={<AllProjects />} />
          <Route path="/ClientProjects" element={<ClientProjects />} />
          <Route path="/DeviceDetails/:deviceId" element={<DeviceDetails />} />
          <Route path="/EditDevice/:id" element={<EditDevice />} />
          <Route path="/AssignProject/:userId" element={<AssignProject />} />

          <Route path="/EditProject/:id" element={<EditProject />} />

          <Route path="/CreateTask" element={<CreateTask />} />
          <Route path="/CreateTicketPage" element={<CreateTicketPage />} />
          <Route path="/ClientCreateTicket" element={<ClientCreateTicket />} />
          <Route path="/InternalTicket" element={<AllInternalTickets />} />
          <Route path="/InternalTicket/:id" element={<InternalDetails />} />
          <Route
            path="/InternalTicket/createtkt"
            element={<CreateInternalTicket />}
          />
          <Route path="/EditClient/:id" element={<EditClient />} />
          <Route path="/projectDevices/:projectId" element={<ProjectDevices />} />
          <Route path="/EditClientUser/:id" element={<EditClientUser />} />
          <Route path="/TableTas" element={<TableTas />} />
          <Route path="/TaskDetailsPage/:id" element={<TaskDetailsPage />} />
          <Route path="/map" element={<Map />} />
          <Route path="/CreateManager" element={<CreateManager />} />
          <Route path="/AllManager" element={<AllManager />} />
          <Route path="/UpdateManager/:id" element={<UpdateManager />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
        </Routes>
        <Outlet />
      </main>
    </>
  );
};

export default Dashboard;
