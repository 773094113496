import React, { useEffect, useState } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import "../style/AllUsers.css";
import "../style/style2.css";
import { Api_url } from "../environent";
import EditIcon from "./edit.png";
import DeleteIcon from "./delete1.png";
import Loader from "./loader";

const AllEmployees = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [allManagers, setAllManagers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [managersPerPage] = useState(10);
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "clientName",
      text: "Client Name",
    },
    {
      dataField: "employeeName",
      text: "Employee Name",
    },
    {
      dataField: "city",
      text: "City",
    },
    {
      dataField: "address",
      text: "Address",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex">
          <Button onClick={() => editUser(row._id)}>
            <img src={EditIcon} alt="Edit" className="img-edit" />
          </Button>
          <Button onClick={() => deleteManager(row._id)}>
            <img src={DeleteIcon} alt="Delete" className="img-delete" />
          </Button>
        </div>
      ),
    },
  ];

  const getallManagers = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token"); // Assuming the token is stored in local storage

      if (!token) {
        throw new Error("No token found");
      }

      const response = await axios.get(Api_url + "man/getAllManagers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Fetched Managers:", response.data.managers); // Debugging step
      setAllManagers(response.data.managers);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteManager = async (id) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this Manager?"
      );
      if (!confirmed) {
        return;
      }

      setLoading(true);
      await axios.delete(Api_url + `man/deleteManagerById/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      getallManagers();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const editUser = (id) => {
    navigate(`/dashboard/updateManager/${id}`);
  };

  const createManager = () => {
    navigate("/dashboard/createManager");
  };

  useEffect(() => {
    getallManagers();
  }, []);

  const indexOfLastUser = currentPage * managersPerPage;
  const indexOfFirstUser = indexOfLastUser - managersPerPage;
  const currentManagers = allManagers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container-fluid full_div">
      <div className="d-flex justify-content-between">
        <div className="">
          <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
            All Managers
          </p>
        </div>
        <div className="">
          <Button
            style={{
              backgroundColor: "#21b062",
              color: "#fff",
              fontSize: "14px",
            }}
            onClick={createManager}
            variant="contained"
          >
            Create
          </Button>
        </div>
      </div>
      <div>
        <div className="col" style={{ width: "100%" }}>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive project-list table-bordered">
                      <table className="table project-table table-centered table-nowrap">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Address</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentManagers.map((manager) => (
                            <tr key={manager._id}>
                              <td>{manager.name}</td>
                              <td>{manager.email}</td>
                              <td>{manager.phone}</td>
                              <td>{manager.address}</td>
                              <td>
                                <div className="d-flex">
                                  <Link
                                    to={`/dashboard/updateManager/${manager._id}`}
                                    className="btn btn-sm btn-outline-success mr-2"
                                    title="Edit"
                                    style={{
                                      fontSize: "18px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </Link>
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() => deleteManager(manager._id)}
                                    title="Delete"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="pt-3">
                      <ul
                        className="pagination justify-content-end mb-0"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          left: "0",
                          display: "flex",
                          justifyContent: "center",
                          listStyleType: "none",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          style={{
                            transition: "background-color 0.3s",
                            marginRight: "5px",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage > 1 && paginate(currentPage - 1)
                            }
                            className="page-link"
                            tabIndex="-1"
                            aria-disabled="true"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor:
                                currentPage === 1 ? "#ccc" : "#fff",
                            }}
                          >
                            Previous
                          </button>
                        </li>
                        {Array.from(
                          {
                            length: Math.ceil(
                              allManagers.length / managersPerPage
                            ),
                          },
                          (_, i) => (
                            <li
                              key={i}
                              className={`page-item ${
                                currentPage === i + 1 ? "active" : ""
                              }`}
                              style={{
                                transition: "background-color 0.3s",
                                marginRight: "5px",
                              }}
                            >
                              <button
                                onClick={() => paginate(i + 1)}
                                className="page-link"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  backgroundColor:
                                    currentPage === i + 1 ? "#007bff" : "#fff",
                                  borderColor: "#ccc",
                                }}
                              >
                                {i + 1}
                              </button>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${
                            currentPage ===
                            Math.ceil(allManagers.length / managersPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          style={{
                            transition: "background-color 0.3s",
                            marginLeft: "5px",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage <
                                Math.ceil(
                                  allManagers.length / managersPerPage
                                ) && paginate(currentPage + 1)
                            }
                            className="page-link"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor:
                                currentPage ===
                                Math.ceil(
                                  allManagers.length / managersPerPage
                                )
                                  ? "#ccc"
                                  : "#fff",
                            }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AllEmployees;
