import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../style/mix.css";
import axios from "axios";
import { Api_url } from '../environent';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [manager, setManager] = useState("");
  const [role, setRole] = useState("");
  const [passShow, setPassShow] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(Api_url+'auth/register', {
        name,
        email,
        password,
        phone,
        address,
        manager,
        role
      })
      .then((res) => {
        Swal.fire({
          title: 'User Created!',
          text: 'You have been registered successfully.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Great!',
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            // You can add more actions if needed or redirect the user here
          }
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: 'Error!',
          text: 'Registration failed. Please try again.',
          icon: 'error',
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK',
        });
      });
  };

  return (
    <>
      <section>
        <div className="form_data">
          <div className="form_heading">
            <h1>Sign Up</h1>
            <p>Welcome back to the registration page. Please register.</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form_input">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
              />
            </div>
            <div className="form_input">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
              />
            </div>
            <div className="form_input">
              <label htmlFor="password">Password</label>
              <div className="two">
                <input
                  type={passShow ? "text" : "password"}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
                <div className="showpass" onClick={() => setPassShow(!passShow)}>
                  <FontAwesomeIcon icon={passShow ? faEye : faEyeSlash} />
                </div>
              </div>
            </div>
            <div className="form_input">
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                name="phone"
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter your phone number"
              />
            </div>
            <div className="form_input">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                name="address"
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter your address"
              />
            </div>
            <div className="form_input">
              <label htmlFor="manager">Manager</label>
              <input
                type="text"
                name="manager"
                onChange={(e) => setManager(e.target.value)}
                placeholder="Enter manager's name"
              />
            </div>
            <div className="form_input">
              <label htmlFor="role">Role</label>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <select
                name="role"
                onChange={(e) => setRole(e.target.value)}
                value={role}
              >
               
                <option value="manager">Manager</option>
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
            <button className="btn" type="submit">
              Sign up
            </button>
            <p>
              {" "}
              have an account? <NavLink to="/">Login</NavLink>{" "}
            </p>
          </form>
        </div>
      </section>
    </>
  );
};

export default Register;
