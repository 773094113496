import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { Api_url } from "../environent";
import "../style/AllUsers.css";
import "../style/style2.css";
import Loader from "./loader";
import moment from "moment"; // For calculating age of the ticket
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles
import { FaCalendarAlt } from 'react-icons/fa'; // Import calendar icon


const AllTickets = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");
  const userId = localStorage.getItem("id");

  const [allTickets, setAllTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 10;

  const [searchErrorDetails, setSearchErrorDetails] = useState("");
  const [searchPriority, setSearchPriority] = useState("");
  const [searchErrorType, setSearchErrorType] = useState("");
  const [searchClientName, setSearchClientName] = useState("");
  const [searchProjectName, setSearchProjectName] = useState("");
  const [searchDeviceMake, setSearchDeviceMake] = useState("");
  const [searchEmployeeName, setSearchEmployeeName] = useState('');
  const [searchManagerName, setSearchManagerName] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchDate, setSearchDate] = useState(null);


  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;

  // Convert a date to 'dd/MM/yyyy' format
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const filteredTickets = allTickets.filter((ticket) => {
    const ticketDate = formatDate(ticket.createdAt);
    const selectedDate = searchDate ? formatDate(searchDate) : '';

    return (
      (ticket.errorDetails?.toLowerCase() || "").includes(searchErrorDetails.toLowerCase()) &&
      (ticket.priority?.toLowerCase() || "").includes(searchPriority.toLowerCase()) &&
      (ticket.errorType?.toLowerCase() || "").includes(searchErrorType.toLowerCase()) &&
      (ticket.clientData?.name?.toLowerCase() || "").includes(searchClientName.toLowerCase()) &&
      (ticket.projectData?.projectName?.toLowerCase() || "").includes(searchProjectName.toLowerCase()) &&
      (ticket.deviceData?.make?.toLowerCase() || "").includes(searchDeviceMake.toLowerCase()) &&
      (ticket.managerData ? ticket.managerData.name.toLowerCase() : '').includes(searchManagerName.toLowerCase()) &&
      (ticket.employeeData ? ticket.employeeData.name.toLowerCase() : '').includes(searchEmployeeName.toLowerCase()) &&
      (ticket.status || '').toLowerCase().includes(searchStatus.toLowerCase() &&
        (selectedDate ? ticketDate === selectedDate : true)));
  });

  const currentTickets = filteredTickets.slice(indexOfFirstTicket, indexOfLastTicket);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getAllTickets = async () => {
    try {
      const token = localStorage.getItem("token");
      const userRole = localStorage.getItem("role");
      const userId = localStorage.getItem("id"); // Client user ID from local storage

      setLoading(true);

      let response;
      let tasks = [];

      if (userRole === "client_user") {
        // Fetch client user data to get assigned project IDs
        const clientUserResponse = await axios.get(Api_url + `ClientUser/getClientUserById/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const clientUser = clientUserResponse.data;
        const projectIds = clientUser.assighnProjectsId.join(','); // Join the array into a comma-separated string

        // Fetch tickets based on the assigned project IDs
        response = await axios.get(Api_url + "Ticket/getTicketsByProjectIds", { // Update path here
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            projectIds: projectIds
          }
        });

        tasks = response.data;
      } else {
        // Handle other roles (e.g., client_admin)
        response = await axios.get(Api_url + "Ticket/getAllTasks", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        tasks = response.data.tasks;

        // Filter tasks by clientId for client_admin
        if (userRole === "client_admin") {
          tasks = tasks.filter((task) => task.clientData.clientId === userId);
        }
      }

      setAllTickets(tasks);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const deleteTicket = async (ticketId) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this ticket?"
      );
      if (confirmDelete) {
        const token = localStorage.getItem("token");
        await axios.delete(Api_url + `Ticket/deleteTicketById/${ticketId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAllTickets((prevTickets) =>
          prevTickets.filter((ticket) => ticket._id !== ticketId)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTickets();
  }, []);

  const createTicket = () => {

    if (userRole === 'client_admin') {
      navigate("/dashboard/ClientCreateTicket");
    } else {
      navigate("/dashboard/CreateTicketPage");
    }
  };


  // Calculate ticket age
  const getTicketAge = (createdAt) => {
    const createdDate = new Date(createdAt);
    const today = new Date();
    const diffTime = today - createdDate;

    if (diffTime < 60000) { // Less than 1 minute
      return '0 h 0 m';
    }

    if (diffTime < 86400000) { // Less than 1 day
      const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
      return `${Math.max(diffHours, 0)} h ${Math.max(diffMinutes, 0)} m`;
    }

    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));

    return `${diffDays} d${diffDays > 1 ? 's' : ''} ${Math.max(diffHours, 0)} h ${Math.max(diffMinutes, 0)} m`;
  };


  // Ticket counts
  const todayTickets = allTickets.filter(ticket => moment(ticket.createdAt).isSame(new Date(), 'day')).length;
  const totalTickets = allTickets.length;
  const pendingTickets = allTickets.filter(ticket => (ticket.status || "").toLowerCase() === 'pending').length;
  const completeTickets = allTickets.filter(ticket => (ticket.status || "").toLowerCase() === 'complete').length;

  return (
    <div className="container-fluid full_div">
      <div className="d-flex justify-content-between mb-3">
        <div>
          <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
            All Tickets
          </p>
        </div>
        <div>
          {userRole !== 'client_user' && (
            <Button
              style={{
                backgroundColor: "#21b062",
                color: "#fff",
                fontSize: "14px",
              }}
              onClick={createTicket}
              variant="contained"
            >
              Create
            </Button>
          )}
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="card bg-c-blue order-card">
            <div class="card-block">
              <h5 class="m-b-10">Today's Tickets</h5>
              <h3 class="text-right"><i class="fa fa-solid fa-ticket f-left"></i><span>{todayTickets} </span></h3>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card bg-c-green order-card">
            <div class="card-block">
              <h5 class="m-b-20">Total Tickets</h5>
              <h3 class="text-right"><i class="fa fa-solid fa-ticket f-left"></i><span>{totalTickets}</span></h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card bg-c-yellow order-card">
            <div class="card-block">
              <h5 class="m-b-20">Pending Tickets</h5>
              <h3 class="text-right"><i class="fa fa-solid fa-ticket f-left"></i><span>{pendingTickets}</span></h3>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card bg-c-pink order-card">
            <div class="card-block">
              <h5 class="m-b-20">Complete Tickets</h5>
              <h3 class="text-right"><i class="fa fa-solid fa-ticket f-left"></i><span>{completeTickets}</span></h3>
            </div>
          </div>
        </div>
      </div>


      <div>
        <div className="col" style={{ width: "100%" }}>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive project-list table-bordered">
                      <table className="table table-striped table-centered table-nowrap">
                        <thead>
                          <tr>
                            <th scope="col">Sl. No.</th>
                            <th scope="col">
                              Ticket Name
                              <input
                                type="text"
                                placeholder="Search Ticket Name"
                                value={searchErrorDetails}
                                onChange={(e) => setSearchErrorDetails(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Company Name
                              <input
                                type="text"
                                placeholder="Search Company Name"
                                value={searchClientName}
                                onChange={(e) => setSearchClientName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Client Name
                              <input
                                type="text"
                                placeholder="Search Client Name"
                                value={searchClientName}
                                onChange={(e) => setSearchClientName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Project Name
                              <input
                                type="text"
                                placeholder="Search Project Name"
                                value={searchProjectName}
                                onChange={(e) => setSearchProjectName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Error Type
                              <input
                                type="text"
                                placeholder="Search Error Type"
                                value={searchErrorType}
                                onChange={(e) => setSearchErrorType(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Manager Name
                              <input
                                type="text"
                                placeholder="Search Manager Name"
                                value={searchManagerName}
                                onChange={(e) => setSearchManagerName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Employee Name
                              <input
                                type="text"
                                placeholder="Search Employee Name"
                                value={searchEmployeeName}
                                onChange={(e) => setSearchEmployeeName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Status
                              <input
                                type="text"
                                placeholder="Search Status"
                                value={searchStatus}
                                onChange={(e) => setSearchStatus(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Created Date
                              <div className="d-flex align-items-center">
                                <DatePicker
                                  selected={searchDate}
                                  onChange={(date) => setSearchDate(date)}
                                  dateFormat="dd/MM/yyyy"
                                  className="form-control"
                                  placeholderText="Select Date"
                                  customInput={<FaCalendarAlt className="calendar-icon" />}
                                />
                              </div>
                            </th>
                            {userRole !== 'client_admin' && userRole !== 'client_user' && (
                              <th scope="col">Age of Ticket</th>
                            )}
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTickets.length > 0 ? (
                            currentTickets.map((ticket, index) => (
                              <tr key={ticket._id}>
                                <td>{index + 1}</td>
                                <td>{ticket.ticNo || "NA"}</td>

                                <td>{ticket.clientData?.companyName || "NA"}</td>
                                <td>{ticket.clientData?.name || "NA"}</td>
                                <td>{ticket.projectData?.projectName || "NA"}</td>
                                <td>{ticket.errorType || "NA"}</td>
                                <td>{ticket.managerData ? ticket.managerData.name : "NA"}</td>
                                <td>{ticket.employeeData ? ticket.employeeData.name : "NA"}</td>
                                <td>{ticket.remark || "NA"}</td>
                                <td>{formatDate(ticket.createdAt)}</td>
                                {userRole !== 'client_admin' && userRole !== 'client_user' && (
                                  <td>{getTicketAge(ticket.createdAt)}</td>
                                )}                                <td>
                                  <div className="d-flex">
                                    <Link
                                      className="btn btn-sm btn-outline-primary mr-2"
                                      to={`/dashboard/TaskDetailsPage/${ticket._id}`}
                                      title="View"
                                      style={{
                                        fontSize: "18px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <i className="bi bi-eye"></i>
                                    </Link>
                                    <button
                                      className="btn btn-sm btn-outline-danger"
                                      onClick={() => deleteTicket(ticket._id)}
                                      title="Delete"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="11" style={{ textAlign: "center" }}>No tickets available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="pt-3">
                      <ul
                        className="pagination justify-content-end mb-0"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          left: "0",
                          display: "flex",
                          justifyContent: "center",
                          listStyleType: "none",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        <li
                          className={`page-item ${currentPage === 1 ? "disabled" : ""
                            }`}
                          style={{
                            transition: "background-color 0.3s",
                            marginRight: "5px",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage > 1 && paginate(currentPage - 1)
                            }
                            className="page-link"
                            tabIndex="-1"
                            aria-disabled="true"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor:
                                currentPage === 1 ? "#ccc" : "#fff",
                            }}
                          >
                            Previous
                          </button>
                        </li>
                        {Array.from(
                          { length: Math.ceil(filteredTickets.length / ticketsPerPage) },
                          (_, i) => (
                            <li
                              key={i + 1}
                              className={`page-item ${currentPage === i + 1 ? "active" : ""
                                }`}
                              style={{
                                transition: "background-color 0.3s",
                                marginLeft: "5px",
                              }}
                            >
                              <button
                                onClick={() => paginate(i + 1)}
                                className="page-link"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  backgroundColor:
                                    currentPage === i + 1 ? "#007bff" : "#fff",
                                  color: currentPage === i + 1 ? "#fff" : "#000",
                                }}
                              >
                                {i + 1}
                              </button>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${currentPage ===
                            Math.ceil(filteredTickets.length / ticketsPerPage)
                            ? "disabled"
                            : ""
                            }`}
                          style={{
                            transition: "background-color 0.3s",
                            marginLeft: "5px",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage <
                              Math.ceil(filteredTickets.length / ticketsPerPage) &&
                              paginate(currentPage + 1)
                            }
                            className="page-link"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor:
                                currentPage ===
                                  Math.ceil(filteredTickets.length / ticketsPerPage)
                                  ? "#ccc"
                                  : "#fff",
                            }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}
        </div>
      </div>
    </div>
  );
};

export default AllTickets;
