import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";
import "../style/AllUsers.css";
import "../style/style2.css";
import { Api_url } from "../environent";
import Loader from "./loader";

const AllEmployees = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [allEmployees, setAllEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeesPerPage] = useState(10);

  const getAllEmployees = async () => {
    try {
      setLoading(true);
      const response = await axios.get(Api_url + "emp/getAllEmployees");
      setAllEmployees(response.data.employees);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteEmployee = async (id) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this employee?"
      );
      if (!confirmed) {
        return;
      }

      setLoading(true);
      await axios.delete(Api_url + `emp/deleteEmployeeById/${id}`);
      getAllEmployees();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  const createEmployee = () => {
    navigate("/dashboard/CreateEmployees");
  };

  // Logic for pagination
  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = allEmployees.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container-fluid full_div">
      <div className="d-flex justify-content-between">
        <div>
          <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
            All Employees
          </p>
        </div>
        <div>
          <Button
            style={{
              backgroundColor: "#21b062",
              color: "#fff",
              fontSize: "14px",
            }}
            onClick={createEmployee}
            variant="contained"
          >
            Create
          </Button>
        </div>
      </div>

      <div>
        <div className="col" style={{ width: "100%" }}>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive project-list table-bordered">
                      <table className="table project-table table-centered table-nowrap">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Address</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentEmployees.map((employee) => (
                            <tr key={employee._id}>
                              <td>{employee.name}</td>
                              <td>{employee.email}</td>
                              <td>{employee.phone}</td>
                              <td>{employee.address}</td>
                              <td>
                                <div className="d-flex">
                                  <Link
                                    to={`/dashboard/EditAllEmployees/${employee._id}`}
                                    className="btn btn-sm btn-outline-success mr-2"
                                    title="Edit"
                                    style={{
                                      fontSize: "18px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </Link>
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() => deleteEmployee(employee._id)}
                                    title="Delete"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="pt-3">
                      <ul
                        className="pagination justify-content-end mb-0"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          left: "0",
                          display: "flex",
                          justifyContent: "center",
                          listStyleType: "none",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          style={{
                            transition: "background-color 0.3s",
                            marginRight: "5px",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage > 1 && paginate(currentPage - 1)
                            }
                            className="page-link"
                            tabIndex="-1"
                            aria-disabled="true"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor:
                                currentPage === 1 ? "#ccc" : "#fff",
                            }}
                          >
                            Previous
                          </button>
                        </li>
                        {Array.from(
                          {
                            length: Math.ceil(
                              allEmployees.length / employeesPerPage
                            ),
                          },
                          (_, i) => (
                            <li
                              key={i}
                              className={`page-item ${
                                currentPage === i + 1 ? "active" : ""
                              }`}
                              style={{
                                transition: "background-color 0.3s",
                                marginRight: "5px",
                              }}
                            >
                              <button
                                onClick={() => paginate(i + 1)}
                                className="page-link"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  backgroundColor:
                                    currentPage === i + 1 ? "#007bff" : "#fff",
                                  borderColor: "#ccc",
                                }}
                              >
                                {i + 1}
                              </button>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${
                            currentPage ===
                            Math.ceil(allEmployees.length / employeesPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          style={{
                            transition: "background-color 0.3s",
                            marginLeft: "5px",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage <
                                Math.ceil(
                                  allEmployees.length / employeesPerPage
                                ) && paginate(currentPage + 1)
                            }
                            className="page-link"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor:
                                currentPage ===
                                Math.ceil(
                                  allEmployees.length / employeesPerPage
                                )
                                  ? "#ccc"
                                  : "#fff",
                            }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllEmployees;
