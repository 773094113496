
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
// import resetPassword from './pages/resetPassword';
import Dashboard from "./pages/Dashboard";
import CreateEmployees from './pages/CreateEmployees';
import NotificationPage from './pages/NotificationPage';
import AllClient from './pages/AllClient';
import CreateClient from './pages/CreateClient';
import Otp from "./pages/Otp";
import EditAllEmployees from './pages/EditAllEmployees'
import AllTasks from './pages/AllTask';
import Error from "./pages/Error";
import Headers from "./components/Headers";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

const token = localStorage.getItem('token');
console.log(token);

function App() {
  return (
    <>

        <div className="App">
          <Routes>
            <Route path="/" exact element={token ? <Dashboard /> : <Login />} />
            <Route path="/ForgotPassword" exact element={token ? <Dashboard /> : <ForgotPassword />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="/dashboard/*" exact
              element={token ? (
                <Dashboard />
              )
                : (<Login />)}
            />
          </Routes>
        </div>

    </>






  );
}

export default App;
