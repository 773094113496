import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Pagination } from "@mui/material";
import Loader from "./loader";
import "../style/AllUsers.css";
import "../style/style2.css";
import { Api_url } from "../environent";

const ProjectDevices = () => {
  const { projectId } = useParams(); // Get the project ID from the URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [searchDeviceName, setSearchDeviceName] = useState("");
  const [searchModelNo, setSearchModelNo] = useState("");
  const [searchSerialNo, setSearchSerialNo] = useState("");
  const [searchMake, setSearchMake] = useState("");
  const [searchMac, setSearchMac] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage] = useState(10);

  useEffect(() => {
    const fetchProjectDevices = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axios.get(Api_url + `project/getProjectDevices/${projectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Filter out any null or undefined values in the response
        const filteredDevices = response.data.filter(device => device !== null && device !== undefined);
        setDevices(filteredDevices);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDevices();
  }, [projectId, navigate]);

  // Filter devices based on search inputs with typo tolerance
  const filteredDevices = devices.filter(device =>
    device?.Device_name?.toLowerCase().includes(searchDeviceName.toLowerCase()) &&
    device?.model_no?.toLowerCase().includes(searchModelNo.toLowerCase()) &&
    device?.serial_no?.toLowerCase().includes(searchSerialNo.toLowerCase()) &&
    device?.make?.toLowerCase().includes(searchMake.toLowerCase()) &&
    device?.mac?.toLowerCase().includes(searchMac.toLowerCase())
  );

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const currentDevices = filteredDevices.slice(indexOfFirstDevice, indexOfLastDevice);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="container-fluid full_div">
      <div>
        <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
          Devices Under Project
        </p>
      </div>

      <div>
        <div className="col" style={{ width: "100%" }}>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive project-list table-bordered">
                      <table className="table project-table table-centered table-nowrap">
                        <thead>
                          <tr>
                            <th scope="col">Sl. No.</th>
                            <th scope="col">
                              Device Name
                              <input
                                type="text"
                                placeholder="Search Device Name"
                                value={searchDeviceName}
                                onChange={(e) => setSearchDeviceName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Model No
                              <input
                                type="text"
                                placeholder="Search Model No"
                                value={searchModelNo}
                                onChange={(e) => setSearchModelNo(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Serial No
                              <input
                                type="text"
                                placeholder="Search Serial No"
                                value={searchSerialNo}
                                onChange={(e) => setSearchSerialNo(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Make
                              <input
                                type="text"
                                placeholder="Search Make"
                                value={searchMake}
                                onChange={(e) => setSearchMake(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Mac
                              <input
                                type="text"
                                placeholder="Search Mac"
                                value={searchMac}
                                onChange={(e) => setSearchMac(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">Warranty Start Date</th>
                            <th scope="col">Warranty End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredDevices.length > 0 ? (
                            currentDevices.map((device, index) => (
                              <tr key={device._id}>
                                <td>{index + 1}</td>
                                <td>{device.Device_name}</td>
                                <td>{device.model_no}</td>
                                <td>{device.serial_no}</td>
                                <td>{device.make}</td>
                                <td>{device.mac || "NA"}</td>
                                <td>{new Date(device.warranty?.ome_warranty_start_date).toLocaleDateString()}</td>
                                <td>{new Date(device.warranty?.ome_warranty_end_date).toLocaleDateString()}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="8" style={{ textAlign: "center" }}>
                                No devices found for this project.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                    <Pagination
                      count={Math.ceil(filteredDevices.length / devicesPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectDevices;
