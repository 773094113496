import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import "../style/button.css";
// Assuming 'environment.js' is in the same directory as 'EditAllEmployees.js'
import { Api_url } from "../environent";

const EditAllEmployees = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
  });
  const [isPasswordEditable, setIsPasswordEditable] = useState(false);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(
          Api_url + `emp/getEmployeeById?id=${id}`
        );
        setFormData(response.data);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchEmployeeData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validation
    switch (name) {
      case "name":
        setErrors({
          ...errors,
          name:
            value.length < 3 ? "Name must be at least 3 characters long" : "",
        });
        break;
      case "email":
        setErrors({
          ...errors,
          email: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ? "Invalid email address"
            : "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password:
            value.length < 6
              ? "Password must be at least 6 characters long"
              : "",
        });
        break;
      case "confirmPassword":
        setErrors({
          ...errors,
          confirmPassword:
            value !== formData.password ? "Passwords do not match" : "",
        });
        break;
      case "phone":
        setErrors({
          ...errors,
          phone: !/^\d{10}$/.test(value)
            ? "Please enter a valid 10-digit phone number"
            : "",
        });
        break;
      case "address":
        setErrors({ ...errors, address: "" });
        break;
      default:
        break;
    }
  };

  const handlePasswordEditToggle = () => {
    setIsPasswordEditable(!isPasswordEditable);

    // Clear the password field when entering edit mode
    if (!isPasswordEditable) {
      setFormData({
        ...formData,
        password: "", // Clear the password field
        confirmPassword: "", // Clear the confirmPassword field
      });

      // Clear any existing password-related errors
      setErrors({
        ...errors,
        password: "",
        confirmPassword: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if there are any errors, including password matching
    if (Object.values(errors).some((error) => error !== "")) {
      console.error("Form has errors. Please fix them before submitting.");
      return;
    }

    try {
      // Conditionally include password fields in the payload
      const payload = isPasswordEditable
        ? formData
        : {
            ...formData,
            password: undefined,
            confirmPassword: undefined,
          };

      await axios.put(Api_url + `emp/editAllEmployees/${id}`, payload);
      navigate(-1);
    } catch (error) {
      console.error("Error editing employee:", error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mt-4">
      <h2
        style={{
          backgroundColor: "#21b062",
          color: "white",
          fontWeight: "bold",
          padding: "5px",
          borderRadius: "4px",
          fontSize: "20px",
        }}
      >
        Edit Employee
      </h2>
      <form onSubmit={handleSubmit} style={{ marginBottom: "40px" }}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Name:</label>
              <input
                type="text"
                className={`form-control ${errors.name && "is-invalid"}`}
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                className={`form-control ${errors.email && "is-invalid"}`}
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
            <div className="form-group1">
              <label></label>
              <div className="edit-password-container">
                <Tooltip title="click chack box for change password" arrow>
                  <input
                    type="checkbox"
                    id="editPasswordCheckbox"
                    checked={isPasswordEditable}
                    onChange={() => handlePasswordEditToggle()}
                  />
                </Tooltip>
                <label
                  htmlFor="editPasswordCheckbox"
                  style={{ marginLeft: "5px" }}
                >
                  Edit Password
                </label>
                {isPasswordEditable && (
                  <div className="password-input-container">
                    <input
                      type={isPasswordEditable ? "text" : "password"}
                      className={`form-control ${
                        errors.password && "is-invalid"
                      }`}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {isPasswordEditable && (
              <div className="form-group">
                <label>Confirm Password:</label>
                <input
                  type={isPasswordEditable ? "text" : "password"}
                  className={`form-control ${
                    errors.confirmPassword && "is-invalid"
                  }`}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                {errors.confirmPassword && (
                  <div className="invalid-feedback">
                    {errors.confirmPassword}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Phone:</label>
              <input
                type="text"
                className={`form-control ${errors.phone && "is-invalid"}`}
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {errors.phone && (
                <div className="invalid-feedback">{errors.phone}</div>
              )}
            </div>

            <div className="form-group">
              <label>Address:</label>
              <input
                type="text"
                className={`form-control ${errors.address && "is-invalid"}`}
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
              {errors.address && (
                <div className="invalid-feedback">{errors.address}</div>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button type="submit" className="custom-button">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditAllEmployees;
