import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";
import "../style/AllUsers.css";
import "../style/style2.css";
import { Api_url } from "../environent";
import Loader from "./loader";

const AllDevices = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [allDevices, setAllDevices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const devicesPerPage = 10;

  // Search states
  const [searchDeviceName, setSearchDeviceName] = useState('');
  const [searchSerialNo, setSearchSerialNo] = useState('');
  const [searchModelNo, setSearchModelNo] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [searchProjectName, setSearchProjectName] = useState('');
  const [searchClientName, setSearchClientName] = useState('');
  const [searchAssignDate, setSearchAssignDate] = useState('');

  const getAllDevices = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }
      const response = await axios.get(Api_url + "device/getAllDevices", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllDevices(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        alert("Failed to fetch devices. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteDevice = async (id) => {
    try {
      const confirmed = window.confirm("Are you sure you want to delete this device?");
      if (!confirmed) return;

      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      await axios.delete(Api_url + `device/deleteDevice/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      getAllDevices();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        alert("Failed to delete device. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllDevices();
  }, []);

  const filterDevices = (devices) => {
    return devices.filter(device =>
      (device.Device_name?.toLowerCase().includes(searchDeviceName.toLowerCase()) || searchDeviceName === '') &&
      (device.serial_no?.toLowerCase().includes(searchSerialNo.toLowerCase()) || searchSerialNo === '') &&
      (device.model_no?.toLowerCase().includes(searchModelNo.toLowerCase()) || searchModelNo === '') &&
      (device.categoryDetails?.name?.toLowerCase().includes(searchCategory.toLowerCase()) || searchCategory === '') &&
      (device.projectDetails?.Project_Name?.toLowerCase().includes(searchProjectName.toLowerCase()) || searchProjectName === '') &&
      (device.projectClientDetails?.companyName?.toLowerCase().includes(searchClientName.toLowerCase()) || searchClientName === '') &&
      (!searchAssignDate || (device.timestamp && new Date(device.timestamp).toLocaleDateString().includes(searchAssignDate)))
    );
  };

  const filteredDevices = filterDevices(allDevices);

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const currentDevices = filteredDevices.slice(indexOfFirstDevice, indexOfLastDevice);
  const totalPages = Math.ceil(filteredDevices.length / devicesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container-fluid full_div">
      <div className="d-flex justify-content-between">
        <div style={{ marginBottom: "10px" }}>
          <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
            All Devices
          </p>
        </div>
        <div>
          <Button
            style={{
              backgroundColor: "#21b062",
              color: "#fff",
              fontSize: "14px",
            }}
            onClick={() => navigate("/dashboard/CreateDevice")}
            variant="contained"
          >
            Create
          </Button>
        </div>
      </div>

      <div>
        <div className="col" style={{ width: "100%" }}>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive project-list table-bordered">
                      <table className="table table-striped table-centered table-nowrap">
                        <thead>
                          <tr>
                            <th scope="col">Sl. No.</th>
                            <th scope="col">
                              Category
                              <input
                                type="text"
                                placeholder="Search Category"
                                value={searchCategory}
                                onChange={(e) => setSearchCategory(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Device Name
                              <input
                                type="text"
                                placeholder="Search Device Name"
                                value={searchDeviceName}
                                onChange={(e) => setSearchDeviceName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Serial No
                              <input
                                type="text"
                                placeholder="Search Serial No"
                                value={searchSerialNo}
                                onChange={(e) => setSearchSerialNo(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Model No
                              <input
                                type="text"
                                placeholder="Search Model No"
                                value={searchModelNo}
                                onChange={(e) => setSearchModelNo(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Project Name
                              <input
                                type="text"
                                placeholder="Search Project Name"
                                value={searchProjectName}
                                onChange={(e) => setSearchProjectName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Client Name
                              <input
                                type="text"
                                placeholder="Search Client Name"
                                value={searchClientName}
                                onChange={(e) => setSearchClientName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">
                              Assign Date
                              <input
                                type="text"
                                placeholder="Search Assign Date"
                                value={searchAssignDate}
                                onChange={(e) => setSearchAssignDate(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentDevices.map((device, index) => (
                            <tr key={device._id} className="table-row-hover">
                              <td>{index + 1}</td>
                              <td>{device.categoryDetails?.name || "N/A"}</td>
                              <td>{device.Device_name || "N/A"}</td>
                              <td>{device.serial_no || "N/A"}</td>
                              <td>{device.model_no || "N/A"}</td>
                              <td>{device.projectDetails?.Project_Name || "N/A"}</td>
                              <td>{device.projectClientDetails?.companyName || "N/A"}</td>
                              <td>
                                {device.warranty.assigned_date
                                  ? new Date(device.warranty.assigned_date).toLocaleDateString()
                                  : "N/A"}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <Link
                                    className="btn btn-sm btn-outline-primary mr-2"
                                    to={`/dashboard/DeviceDetails/${device._id}`}
                                    title="View"
                                    style={{
                                      fontSize: "18px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <i className="bi bi-eye"></i>
                                  </Link>
                                  <Link
                                    to={`/dashboard/EditDevice/${device._id}`}
                                    className="btn btn-sm btn-outline-success mr-2"
                                    title="Edit"
                                    style={{ fontSize: "18px", marginRight: "10px" }}
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </Link>
                                  <button
                                    onClick={() => deleteDevice(device._id)}
                                    className="btn btn-sm btn-outline-danger"
                                    title="Delete"
                                    style={{ fontSize: "18px" }}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="pt-3">
                      <ul
                        className="pagination justify-content-end mb-0"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          left: "0",
                          display: "flex",
                          justifyContent: "center",
                          listStyleType: "none",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        <li
                          className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                          style={{
                            transition: "background-color 0.3s",
                            marginRight: "5px",
                          }}
                        >
                          <button
                            onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                            className="page-link"
                            tabIndex="-1"
                            aria-disabled="true"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor: currentPage === 1 ? "#ccc" : "#fff",
                            }}
                          >
                            Previous
                          </button>
                        </li>

                        {Array.from({ length: totalPages }, (_, i) => (
                          <li
                            key={i}
                            className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                            style={{
                              transition: "background-color 0.3s",
                              marginRight: "5px",
                            }}
                          >
                            <button
                              onClick={() => paginate(i + 1)}
                              className="page-link"
                              style={{
                                textDecoration: "none",
                                color: "#000",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                backgroundColor: currentPage === i + 1 ? "#007bff" : "#fff",
                                borderColor: "#ccc",
                              }}
                            >
                              {i + 1}
                            </button>
                          </li>
                        ))}

                        <li
                          className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                          style={{
                            transition: "background-color 0.3s",
                            marginLeft: "5px",
                          }}
                        >
                          <button
                            onClick={() => currentPage < totalPages && paginate(currentPage + 1)}
                            className="page-link"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor: currentPage === totalPages ? "#ccc" : "#fff",
                            }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllDevices;
