import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const Map = ({ lat, long,postcode }) => {
  const mapStyles = {
    height: '300px',
    width: '100%',
  };

  const defaultCenter = {
    lat: parseFloat(lat),
    lng: parseFloat(long),
    postcode: parseFloat(postcode),
  };

  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const toggleInfoWindow = () => {
    setInfoWindowOpen(!infoWindowOpen);
  };


  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDpEqauXWZEF99qOZuKNkZKmQwJJ01s2l4"
      >
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={15}
        center={defaultCenter}
      >
        <Marker position={defaultCenter} onClick={toggleInfoWindow} />
        
       
        {infoWindowOpen && (
          <InfoWindow position={defaultCenter} onCloseClick={toggleInfoWindow}>
            <div>
              
              <h3>Location Details</h3>
              <p>Latitude: {lat}</p>
              <p>Longitude: {long}</p>
              
             
            </div>
          </InfoWindow>
        )}
        
        <lord-icon
          src="https://cdn.lordicon.com/tdtlrbly.json"
          trigger="hover"
          style={{
            position: 'absolute',
            width: '50px',
            height: '50px',
            transform: 'translate(-50%, -100%)',
            state:"in-jump-dynamic",
            left: '50%', 
            top: '50%',  
            cursor: 'pointer',
            colors:"primary:#e83a30,secondary:#30e8bd", // Set the color to red
          }}
          onClick={toggleInfoWindow}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;