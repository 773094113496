import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import { Api_url } from "../environent";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./loader";

const InternalTicket = () => {
  const [subject, setSubject] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectSite, setProjectSite] = useState("");
  const [projectLocation, setProjectLocation] = useState("");
  const [projectType, setProjectType] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorDetails, setErrorDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const navigate = useNavigate();
  const createdBy = localStorage.getItem("id");
  const role = localStorage.getItem("role");

  useEffect(() => {
    const fetchClientsProjectsAndEmployees = async () => {
      try {
        setIsLoading(true);
        const token = localStorage.getItem("token");
        const [clientsResponse, projectsResponse, employeesResponse] =
          await Promise.all([
            axios.get(`${Api_url}Client/fetchClient`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
            axios.get(`${Api_url}project/getAllProjects`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
            axios.get(`${Api_url}emp/getAllEmployees`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
          ]);

        console.log("Clients Response:", clientsResponse.data);
        console.log("Projects Response:", projectsResponse.data);
        console.log("Employees Response:", employeesResponse.data);

        setClients(clientsResponse.data);
        setProjects(projectsResponse.data);
        setEmployees(employeesResponse.data.employees); // Assuming employees are under employeesResponse.data. Adjust as per your API response structure.
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(
          "Error fetching clients, projects, and employees:",
          error
        );
      }
    };

    fetchClientsProjectsAndEmployees();
  }, []);

  const handleProjectChange = (e) => {
    const selectedProject = projects.find(
      (project) => project.Project_Name === e.target.value
    );

    if (selectedProject) {
      setProjectName(selectedProject.Project_Name);
      setProjectSite(selectedProject.project_site);
      setProjectLocation(selectedProject.project_location);
      setProjectType(selectedProject.project_type);
    } else {
      setProjectName("");
      setProjectSite("");
      setProjectLocation("");
      setProjectType("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (
      !subject ||
      !clientName ||
      !projectName ||
      !projectSite ||
      !projectLocation ||
      !projectType ||
      !errorType ||
      !errorDetails
    ) {
      toast.error("Please fill in all the fields");
      return;
    }
  
    const formData = {
      subject,
      clientName,
      projectName,
      projectSite,
      projectLocation,
      projectType,
      errorType,
      errorDetails,
      createdBy,
      role,
      assignedEmployee: selectedEmployee ? selectedEmployee.value : null // Include assignedEmployee in the form data
    };
  
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${Api_url}internal/createInternalTicket`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log("Ticket creation response:", response);
  
      setIsLoading(false);
      navigate(-1);
      console.log("Internal Ticket created and employee assigned successfully:", response.data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating internal ticket or assigning employee:", error);
      toast.error("Error creating internal ticket or assigning employee");
    }
  };
  

  const employeeOptions = employees.map(employee => ({ value: employee._id, label: employee.name }));

  return (
    <div className="container mt-5">
      <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : (
        <form className="row g-3" onSubmit={handleSubmit}>
          <div className="col-md-6">
            <label className="form-label">
              Subject <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Subject"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">
              Client Name <span className="text-danger">*</span>
            </label>
            <select
              className="form-control pb-0 pt-0"
              name="clientName"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            >
              <option value="">Select Client</option>
              {clients.map((client) => (
                <option key={client._id} value={client.name}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <label className="form-label">
              Project Name <span className="text-danger">*</span>
            </label>
            <select
              className="form-control pt-0 pb-0"
              name="projectName"
              value={projectName}
              onChange={handleProjectChange}
            >
              <option value="">Select Project</option>
              {projects.map((project) => (
                <option key={project._id} value={project.Project_Name}>
                  {project.Project_Name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <label className="form-label">
              Project Site <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="projectSite"
              value={projectSite}
              placeholder="Project Site"
              readOnly
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">
              Project Location <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="projectLocation"
              value={projectLocation}
              placeholder="Project Location"
              readOnly
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">
              Project Type <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="projectType"
              value={projectType}
              placeholder="Project Type"
              readOnly
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">
              Error Type <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="errorType"
              value={errorType}
              onChange={(e) => setErrorType(e.target.value)}
              placeholder="Error Type"
            />
          </div>
          <div className="col-md-12">
            <label className="form-label">
              Error Details <span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control"
              name="errorDetails"
              value={errorDetails}
              onChange={(e) => setErrorDetails(e.target.value)}
              placeholder="Error Details"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">
              Assign Employee <span className="text-danger">*</span>
            </label>
            <Select
              options={employeeOptions}
              value={selectedEmployee}
              onChange={setSelectedEmployee}
              placeholder="Select Employee"
            />
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-primary">
              Create Internal Ticket
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default InternalTicket;
