import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, TextField, Popover, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import "../style/AllUsers.css";
import { Api_url } from '../environent';
const TableTas = () => {
  const navigate = useNavigate();

  const [allTasks, setAllTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarAnchor, setCalendarAnchor] = useState(null);

  const swipeRight = (taskId) => {
    // Implement swipeRight logic
    console.log(`Swipe Right for task with ID ${taskId}`);
  };

  const swipeLeft = (taskId) => {
    // Implement swipeLeft logic
    console.log(`Swipe Left for task with ID ${taskId}`);
  };

  const editTask = (taskId) => {
    // Implement editTask logic
    console.log(`Edit Task with ID ${taskId}`);
  };

  const deleteTask = async (taskId) => {
    try {
      await axios.delete(Api_url+`Task/deleteTask/${taskId}`);
      getAllTasks(); // Refresh the tasks after deletion
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const getClosestLandmark = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCRX-XON_xbD4h-FFDl8p_bIaiiDkzNIJg`
      );

      if (response.data.results && response.data.results.length > 0) {
        const landmark = response.data.results[0].formatted_address;
        return landmark;
      } else {
        return 'No Landmark Found';
      }
    } catch (error) {
      console.error('Error fetching landmark:', error);
      return 'Error Fetching Landmark';
    }
  };

  const getAllTasks = async () => {
    try {
      setLoading(true);
      const response = await axios.get('Api_url+Task/getAllTasks', {
        params: {
          startDate,
          endDate,
        },
      });

      if (response.data.tasks) {
        const tasksWithLandmarks = await Promise.all(
          response.data.tasks.map(async (task) => {
            if (task.location && task.location.coordinates) {
              const [longitude, latitude] = task.location.coordinates;
              const landmark = await getClosestLandmark(latitude, longitude);
              return { ...task, landmark };
            } else {
              return task;
            }
          })
        );
        setAllTasks(tasksWithLandmarks);
      } else {
        console.error('Tasks data is undefined:', response.data);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTasks();
  }, [startDate, endDate]);

  const handleCalendarClick = (event) => {
    setCalendarAnchor(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setCalendarAnchor(null);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCalendarAnchor(null);
  };

  const columns = [
    { accessorKey: 'date', header: 'Date', size: 200 },
    { accessorKey: 'task', header: 'Task', size: 200 },
    { accessorKey: 'from_time', header: 'From Time', size: 200 },
    { accessorKey: 'to_time', header: 'To Time', size: 200 },
    { accessorKey: 'client', header: 'Client', size: 200 },
    { accessorKey: 'remark', header: 'Remark', size: 200 },
    {
      accessorKey: 'location',
      header: 'Location',
      size: 200,
      Cell: ({ row }) => (
        <div>
          {row.original.location ? (
            <span>
              Type: {row.original.location.type}, Coordinates: {row.original.location.coordinates.join(', ')}
              <br />
              Landmark: {row.original.landmark}
            </span>
          ) : (
            <span>No Location</span>
          )}
        </div>
      ),
    },
    {
      accessorKey: 'is_completed',
      header: 'Is Completed',
      Cell: ({ row }) => (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          {row.original.is_completed ? (
            <Button
              style={{ color: 'green' }}
              onClick={() => swipeRight && swipeRight(row.original._id)}
            >
              <FontAwesomeIcon icon={faToggleOn} size="lg" style={{ fontSize: '40px' }} />
            </Button>
          ) : (
            <Button
              style={{ color: 'red' }}
              onClick={() => swipeLeft(row.original._id)}
            >
              <FontAwesomeIcon icon={faToggleOff} size="lg" style={{ fontSize: '40px' }} />
            </Button>
          )}
        </div>
      ),
      size: 20,
    },
    {
      header: 'Action',
      Cell: ({ row }) => (
        <>
          <Button
            style={{ backgroundColor: '#012970', color: 'white', marginRight: '8px' }}
            onClick={() => editTask(row.original._id)}
          >
            Edit
          </Button>
          <Button
            style={{ backgroundColor: '#FF0000', color: 'white', marginRight: '8px' }}
            onClick={() => deleteTask(row.original._id)}
          >
            Delete
          </Button>
        </>
      ),
      size: 10,
    },
    {
      header: 'Selected Date',
      Cell: () => (
        <Typography variant="body2">
          {selectedDate ? selectedDate.toLocaleDateString() : 'No date selected'}
        </Typography>
      ),
      size: 10,
    },
  ];

  const filteredTasks = allTasks.map((task) => {
    const filteredData = {};
    columns.forEach((column) => {
      filteredData[column.accessorKey] = task[column.accessorKey];
    });
    return filteredData;
  });
  filteredTasks.reverse();

  return (
    <div className='container-fluid full_div'>
      <div className='row'>
        <h3 className='text-center h3_addAsset'>ALL TASKS</h3>
        <Button onClick={handleCalendarClick}>
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
        </Button>
        <Popover
          open={Boolean(calendarAnchor)}
          anchorEl={calendarAnchor}
          onClose={handleCalendarClose}
        >
          <Box p={2}>
            <TextField
              type="date"
              label="Select Date"
              value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
              onChange={(e) => handleDateChange(new Date(e.target.value))}
            />
          </Box>
        </Popover>
        {loading ? (
          <div className='row align-items-center justify-content-center'>
            <div className='custom-loader col-6 align-self-center fw-bold mt-5'></div>
          </div>
        ) : (
          <MaterialReactTable
            columns={columns}
            data={filteredTasks}
            enableRowSelection
            positionToolbarAlertBanner='bottom'
          />
        )}
      </div>
    </div>
  );
};

export default TableTas;
