import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../style/AllUsers.css";
import "../style/style2.css";
import { Api_url } from "../environent";
import Loader from "./loader";

const ClientDevices = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [clientDevices, setClientDevices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage] = useState(5);

  // Search state for each column
  const [searchDeviceName, setSearchDeviceName] = useState("");
  const [searchProjectName, setSearchProjectName] = useState("");
  const [searchMake, setSearchMake] = useState("");
  const [searchSerialNumber, setSearchSerialNumber] = useState("");
  const [searchModelNumber, setSearchModelNumber] = useState("");

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const clientId = localStorage.getItem("id");
        const userRole = localStorage.getItem("role"); // Get the user role
        const clientUserId = localStorage.getItem("id"); // Get the client user ID

        if (!clientId) {
          throw new Error("No client ID found in localStorage");
        }

        if (userRole === "client_user" && clientUserId) {
          // Make the API call for client users
          const response = await axios.get(`${Api_url}ClientUser/getDevicesByClientUserId/${clientUserId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setClientDevices(response.data);
        } else {
          // Default API call for other roles
          const response = await axios.get(`${Api_url}Client/getAllDevicesForClient/${clientId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setClientDevices(response.data);
        }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, [navigate]);

  // Filter devices
  const filteredDevices = clientDevices.flatMap(device =>
    device.deviceDetails
      .filter(detail =>
        (detail.Device_name?.toLowerCase() || "").includes(searchDeviceName.toLowerCase()) &&
        (device.Project_Name?.toLowerCase() || "").includes(searchProjectName.toLowerCase()) &&
        (detail.make?.toLowerCase() || "").includes(searchMake.toLowerCase()) &&
        (detail.serial_no?.toLowerCase() || "").includes(searchSerialNumber.toLowerCase()) &&
        (detail.model_no?.toLowerCase() || "").includes(searchModelNumber.toLowerCase())
      )
      .map(detail => ({ ...detail, Project_Name: device.Project_Name }))
  );

  // Pagination logic
  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const currentDevices = filteredDevices.slice(indexOfFirstDevice, indexOfLastDevice);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="container-fluid full_div">
      <div>
        <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
          Devices for Client Projects
        </p>
      </div>

      <div>
        <div className="col" style={{ width: "100%" }}>
          {loading ? (
            <Loader />
          ) : (
            clientDevices.length > 0 ? (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive project-list table-bordered">
                        <table className="table project-table table-centered table-nowrap">
                          <thead>
                            <tr>
                              <th>Sl. No.</th>
                              <th>
                                Device Name
                                <input
                                  type="text"
                                  placeholder="Search Device Name"
                                  value={searchDeviceName}
                                  onChange={(e) => setSearchDeviceName(e.target.value)}
                                  className="form-control"
                                />
                              </th>
                              <th>
                                Project Name
                                <input
                                  type="text"
                                  placeholder="Search Project Name"
                                  value={searchProjectName}
                                  onChange={(e) => setSearchProjectName(e.target.value)}
                                  className="form-control"
                                />
                              </th>
                              <th>
                                Make
                                <input
                                  type="text"
                                  placeholder="Search Make"
                                  value={searchMake}
                                  onChange={(e) => setSearchMake(e.target.value)}
                                  className="form-control"
                                />
                              </th>
                              <th>
                                Serial Number
                                <input
                                  type="text"
                                  placeholder="Search Serial Number"
                                  value={searchSerialNumber}
                                  onChange={(e) => setSearchSerialNumber(e.target.value)}
                                  className="form-control"
                                />
                              </th>
                              <th>
                                Model Number
                                <input
                                  type="text"
                                  placeholder="Search Model Number"
                                  value={searchModelNumber}
                                  onChange={(e) => setSearchModelNumber(e.target.value)}
                                  className="form-control"
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentDevices.length > 0 ? (
                              currentDevices.map((device, index) => (
                                <tr key={device._id}>
                                  <td>{indexOfFirstDevice + index + 1}</td>
                                  <td>{device.Device_name || "N/A"}</td>
                                  <td>{device.Project_Name || "N/A"}</td>
                                  <td>{device.make || "N/A"}</td>
                                  <td>{device.serial_no || "N/A"}</td>
                                  <td>{device.model_no || "N/A"}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6" style={{ textAlign: "center" }}>No devices found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <Pagination
                          count={Math.ceil(filteredDevices.length / devicesPerPage)}
                          page={currentPage}
                          onChange={handlePageChange}
                          color="primary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <p>No devices available for this client.</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientDevices;
