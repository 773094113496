import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/button.css";
import { Api_url } from "../environent";

const CreateDevice = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    Device_name: "",
    make: "",
    mac: "",
    serial_no: "",
    model_no: "",
    category: "",
    documents: [],
    ome_warranty_start_date: "",
    ome_warranty_end_date: "",
  });

  const [formErrors, setFormErrors] = useState({
    Device_name: "",
    make: "",
    mac: "",
    serial_no: "",
    model_no: "",
    category: "",
    ome_warranty_start_date: "",
    ome_warranty_end_date: "",
  });

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(Api_url + "Category/AllCategories");
        if (Array.isArray(categoryResponse.data)) {
          setCategories(categoryResponse.data);
        } else {
          toast.error("Failed to fetch categories: Invalid data format.");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Failed to fetch categories.");
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = { ...formErrors };

    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);

    switch (name) {
        case "Device_name":
            errors.Device_name =
                value.length < 3
                    ? "Device name must be at least 3 characters long"
                    : "";
            break;
        case "serial_no":
            errors.serial_no = value === "" ? "Serial number is required" : "";
            break;
        case "model_no":
            errors.model_no = value === "" ? "Model number is required" : "";
            break;
        case "category":
            errors.category = value === "" ? "Category is required" : "";
            break;
        case "ome_warranty_start_date":
        case "ome_warranty_end_date":
            const startDate = new Date(updatedFormData.ome_warranty_start_date);
            const endDate = new Date(updatedFormData.ome_warranty_end_date);

            // Normalize both dates to midnight to compare only the date parts
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            if (updatedFormData.ome_warranty_start_date && updatedFormData.ome_warranty_end_date) {
                if (endDate < startDate) {
                    errors.ome_warranty_end_date = "OME Warranty End Date cannot be earlier than Start Date";
                    errors.ome_warranty_start_date = "";
                } else if (endDate.getTime() === startDate.getTime()) {
                    errors.ome_warranty_end_date = "OME Warranty End Date cannot be the same as Start Date";
                    errors.ome_warranty_start_date = "";
                } else {
                    errors.ome_warranty_end_date = "";
                    errors.ome_warranty_start_date = "";
                }
            } else {
                if (name === "ome_warranty_start_date") {
                    errors.ome_warranty_start_date = value === "" ? "OME Warranty Start Date is required" : "";
                }
                if (name === "ome_warranty_end_date") {
                    errors.ome_warranty_end_date = value === "" ? "OME Warranty End Date is required" : "";
                }
            }
            break;
            case "mac":
              const standardPattern = /^([0-9A-Fa-f]{12})$/;
              const hyphenPattern = /^([0-9A-Fa-f]{2}[-]){5}([0-9A-Fa-f]{2})$/;
              const colonPattern = /^([0-9A-Fa-f]{2}:){5}([0-9A-Fa-f]{2})$/;
              const periodPattern = /^([0-9A-Fa-f]{4}\.){2}([0-9A-Fa-f]{4})$/;
            
              if (standardPattern.test(value) || hyphenPattern.test(value) || colonPattern.test(value) || periodPattern.test(value)) {
                errors.mac = "";
              } else {
                errors.mac = "Invalid MAC address format. It must be a 12-character hexadecimal code, or in hyphen, colon, or period-separated format.";
              }
              break;
            break;
    }

    setFormErrors(errors);
};


  const handleCategoryChange = (e) => {
    setFormData({ ...formData, category: e.target.value });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newDocuments = [...formData.documents];

    files.forEach((file) => {
      if (file.size > 2 * 1024 * 1024) {
        toast.error(`File ${file.name} exceeds the 2 MB size limit.`);
      } else if (newDocuments.length < 3) {
        newDocuments.push(file);
      } else {
        toast.error("You can only upload up to 3 files.");
      }
    });

    setFormData({ ...formData, documents: newDocuments });
    //clear the field 
    e.target.value = null;
  };

  const removeDocument = (fileToRemove) => {
    setFormData({
      ...formData,
      documents: formData.documents.filter(file => file !== fileToRemove)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formData.category.trim() === "") {
      toast.error("Category is required");
      setLoading(false);
      return;
    }
    if (formData.Device_name.trim() === "") {
      toast.error("Device name is required");
      setLoading(false);
      return;
    }

    // if (formData.serial_no.trim() === "") {``
    //   toast.error("Serial number is required");
    //   setLoading(false);
    //   return;
    // }

    if (formData.model_no.trim() === "") {
      toast.error("Model number is required");
      setLoading(false);
      return;
    }

    if (formData.ome_warranty_start_date.trim() === "") {
      toast.error("OME Warranty Start Date is required");
      setLoading(false);
      return;
    }

    if (formData.ome_warranty_end_date.trim() === "") {
      toast.error("OME Warranty End Date is required");
      setLoading(false);
      return;
    }

    if (Object.values(formErrors).some((error) => error !== "")) {
      toast.error("Please fix the form errors before submitting.");
      setLoading(false);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('category', formData.category);
    formDataToSend.append('Device_name', formData.Device_name);
    formDataToSend.append('make', formData.make);
    formDataToSend.append('mac', formData.mac);
    formDataToSend.append('serial_no', formData.serial_no);
    formDataToSend.append('model_no', formData.model_no);
    formDataToSend.append('ome_warranty_start_date', formData.ome_warranty_start_date);
    formDataToSend.append('ome_warranty_end_date', formData.ome_warranty_end_date);

   formData.documents.forEach((file, index) => {
    formDataToSend.append('documents', file);
  });
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Authentication failed: No token provided");
        setLoading(false);
        return;
      }

      const response = await axios.post(
        Api_url + "device/createDevice",
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Device created successfully!");
      setFormData({
        Device_name: "",
        make: "",
        mac: "",
        serial_no: "",
        model_no: "",
        category: "",
        documents: [],
        ome_warranty_start_date: "",
        ome_warranty_end_date: "",
      });
      navigate("/dashboard/AllDevices");
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.data) {
        toast.error(`Failed to create device: ${error.response.data.error || error.response.data.message}`);
      } else {
        toast.error("Failed to create device. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/dashboard/AllDevices");
  };

  return (
    <div className="container mt-4">
      <ToastContainer />
      <h2
        style={{
          backgroundColor: "#21b062",
          color: "white",
          fontWeight: "bold",
          padding: "10px",
          borderRadius: "4px",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        Create Device
      </h2>
      <form onSubmit={handleSubmit} style={{ marginBottom: "40px" }} encType="multipart/form-data">
        <div className="row">
        <div className="form-group col-md-6">
            <label>
              Category<span className="text-danger">*</span>
            </label>
            <select
              className="form-select form-select-lg"
              name="category"
              value={formData.category}
              onChange={handleCategoryChange}
              required
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            {formErrors.category && (
              <span className="text-danger">{formErrors.category}</span>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>
              Device Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="Device_name"
              value={formData.Device_name}
              onChange={handleChange}
              required
            />
            {formErrors.Device_name && (
              <span className="text-danger">{formErrors.Device_name}</span>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>Make</label>
            <input
              type="text"
              className="form-control"
              name="make"
              value={formData.make}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-6">
            <label>MAC Address</label>
            <input
              type="text"
              className="form-control"
              name="mac"
              value={formData.mac}
              onChange={handleChange}
            />
            {formErrors.mac && (
              <span className="text-danger">{formErrors.mac}</span>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>
              Serial Number<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="serial_no"
              value={formData.serial_no}
              onChange={handleChange}
              required
            />
            {formErrors.serial_no && (
              <span className="text-danger">{formErrors.serial_no}</span>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>
              Model Number<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="model_no"
              value={formData.model_no}
              onChange={handleChange}
              required
            />
            {formErrors.model_no && (
              <span className="text-danger">{formErrors.model_no}</span>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>
              OME Warranty Start Date<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              name="ome_warranty_start_date"
              value={formData.ome_warranty_start_date}
              onChange={handleChange}
              required
            />
            {formErrors.ome_warranty_start_date && (
              <span className="text-danger">
                {formErrors.ome_warranty_start_date}
              </span>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>
              OME Warranty End Date<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              name="ome_warranty_end_date"
              value={formData.ome_warranty_end_date}
              onChange={handleChange}
              required
            />
            {formErrors.ome_warranty_end_date && (
              <span className="text-danger">
                {formErrors.ome_warranty_end_date}
              </span>
            )}
          </div>
          <div className="form-group col-md-6">
            <label>Upload Documents</label>
            <input
              type="file"
              className="form-control"
              name="documents"
              onChange={handleFileChange}
              multiple
              accept=".jpg, .png, .doc, .pdf"
            />
            {formData.documents.length > 0 && (
              <div className="mt-2">
                <h6>Selected Documents:</h6>
                <ul className="list-group">
                  {formData.documents.map((file, index) => (
                    <li key={index} className="list-group-item">
                      {file.name}
                      <button
                        type="button"
                        className="btn btn-sm btn-danger float-right"
                        onClick={() => removeDocument(file)}
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              type="submit"
              className={`custom-button ${loading ? "loading" : ""}`}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Creating..." : "Create"}
            </button>
            <button
              type="button"
              className="custom-button"
              onClick={handleBack}
              style={{ marginLeft: "10px" }}
            >
              Back
            </button>
          </div>
      </form>
    </div>
  );
};

export default CreateDevice;
