import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Api_url } from "../environent";
import Loader from "./loader";
import "bootstrap/dist/css/bootstrap.min.css";

const ClientUsers = () => {
  const { id } = useParams(); // The client ID from URL
  const navigate = useNavigate();
  const [clientDetails, setClientDetails] = useState({});
  const [clientUsers, setClientUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchRole, setSearchRole] = useState("");
  const [searchAddress, setSearchAddress] = useState("");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Adjust items per page as needed

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const token = localStorage.getItem("token");
        setLoading(true);

        // Fetch client details
        const clientResponse = await axios.get(
          `${Api_url}Client/getClientById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setClientDetails(clientResponse.data || {});

        // Fetch client users
        const usersResponse = await axios.get(
          `${Api_url}ClientUser/client/ClientUsers/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setClientUsers(usersResponse.data || []);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchClientData();
  }, [id]);

  const deleteUser = async (userId) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this user?"
      );
      if (confirmDelete) {
        const token = localStorage.getItem("token");
        await axios.delete(`${Api_url}ClientUser/deleteClientUserById/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClientUsers((prevUsers) =>
          prevUsers.filter((user) => user._id !== userId)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Filtered and paginated users
  const filteredUsers = clientUsers.filter((user) => {
    return (
      user.name.toLowerCase().includes(searchName.toLowerCase()) &&
      user.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
      user.phone.toLowerCase().includes(searchPhone.toLowerCase()) &&
      user.role.toLowerCase().includes(searchRole.toLowerCase()) &&
      user.address.toLowerCase().includes(searchAddress.toLowerCase())
    );
  });

  // Paginate users
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Handle page change
  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total pages
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  return (
    <div className="container-fluid">
      {loading ? (
        <Loader />
      ) : (
        <div>
          {/* Client Details Summary */}
          <div
            className="card mb-4 shadow-sm"
            style={{ height: "auto", padding: "0.5rem" }}
          >
            <div className="card-body">
              <div className="d-flex justify-content-between mb-4">
                <h5
                  className="card-title mb-4"
                  style={{
                    borderBottom: "2px solid #21b062",
                    paddingBottom: "10px",
                    color: "#21b062",
                    fontSize: "16px", // Adjust font size if needed
                  }}
                >
                  Client Summary
                </h5>
                <Button
                  style={{
                    backgroundColor: "#21b062",
                    color: "#fff",
                    fontSize: "14px",
                    height: "40px",
                    marginTop: "10px"
                  }}
                  onClick={() => navigate(`/dashboard/CreateClientUsers/${id}`)}
                  variant="contained"
                >
                  Create user
                </Button>
              </div>
              <div className="row">
                <div className="col-md-4 mb-2">
                  <strong className="text-muted" style={{ color: "#6c757d" }}>
                    Company Name:
                  </strong>
                  <p className="mb-0" style={{ color: "#343a40" }}>
                    {clientDetails.companyName || "NA"}
                  </p>
                </div>
                <div className="col-md-4 mb-2">
                  <strong className="text-muted" style={{ color: "#6c757d" }}>
                    City:
                  </strong>
                  <p className="mb-0" style={{ color: "#343a40" }}>
                    {clientDetails.city || "NA"}
                  </p>
                </div>
                <div className="col-md-4 mb-2">
                  <strong className="text-muted" style={{ color: "#6c757d" }}>
                    Address:
                  </strong>
                  <p className="mb-0" style={{ color: "#343a40" }}>
                    {clientDetails.address || "NA"}
                  </p>
                </div>
                <div className="col-md-4 mb-2">
                  <strong className="text-muted" style={{ color: "#6c757d" }}>
                    Client Name:
                  </strong>
                  <p className="mb-0" style={{ color: "#343a40" }}>
                    {clientDetails.name || "NA"}
                  </p>
                </div>
                <div className="col-md-4 mb-2">
                  <strong className="text-muted" style={{ color: "#6c757d" }}>
                    Email:
                  </strong>
                  <p className="mb-0" style={{ color: "#343a40" }}>
                    {clientDetails.email || "NA"}
                  </p>
                </div>
                <div className="col-md-4 mb-2">
                  <strong className="text-muted" style={{ color: "#6c757d" }}>
                    Phone:
                  </strong>
                  <p className="mb-0" style={{ color: "#343a40" }}>
                    {clientDetails.phone || "NA"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h5 className="mb-4" style={{ fontSize: "18px" }}>
            Client User List
          </h5>

          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">Sl. No.</th>
                  <th scope="col">
                    Name
                    <input
                      type="text"
                      placeholder="Search Name"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      className="form-control"
                    />
                  </th>
                  <th scope="col">
                    Email
                    <input
                      type="text"
                      placeholder="Search Email"
                      value={searchEmail}
                      onChange={(e) => setSearchEmail(e.target.value)}
                      className="form-control"
                    />
                  </th>
                  <th scope="col">
                    Phone
                    <input
                      type="text"
                      placeholder="Search Phone"
                      value={searchPhone}
                      onChange={(e) => setSearchPhone(e.target.value)}
                      className="form-control"
                    />
                  </th>
                  <th scope="col">
                    Role
                    <input
                      type="text"
                      placeholder="Search Role"
                      value={searchRole}
                      onChange={(e) => setSearchRole(e.target.value)}
                      className="form-control"
                    />
                  </th>
                  <th scope="col">
                    Address
                    <input
                      type="text"
                      placeholder="Search Address"
                      value={searchAddress}
                      onChange={(e) => setSearchAddress(e.target.value)}
                      className="form-control"
                    />
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.length > 0 ? (
                  currentUsers.map((user, index) => (
                    <tr key={user._id}>
                      <td>{index + 1}</td>
                      <td>{user.name || "NA"}</td>
                      <td>{user.email || "NA"}</td>
                      <td>{user.phone || "NA"}</td>
                      <td>{user.role || "NA"}</td>
                      <td>{user.address || "NA"}</td>
                      <td>
                        <div className="d-flex">
                          <Link
                            className="btn btn-sm btn-outline-primary mr-2"
                            to={`/dashboard/AssignProject/${user._id}`} // Ensure the path matches your routing configuration
                            title="View"
                            style={{
                              fontSize: "18px",
                              marginRight: "10px",
                            }}
                          >
                            <i className="bi bi-eye"></i>
                          </Link>

                          <Link
                            className="btn btn-sm btn-outline-primary me-2"
                            to={`/dashboard/EditClientUser/${user._id}`}
                            title="Edit"
                            style={{ fontSize: "18px" }}
                          >
                            <i className="bi bi-pencil"></i>
                          </Link>
                          <button
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => deleteUser(user._id)}
                            title="Delete"
                            style={{ fontSize: "18px" }}
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-center"
                      style={{ fontSize: "15px" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="d-flex justify-content-center mt-4">
            <nav>
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(null, currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${currentPage === index + 1 ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(null, index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(null, currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientUsers;
