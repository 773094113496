import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import vlaccessLogo from "./vlaccess_logo_tm.png";
import backgroundVideo from "../style/copywrite.mov";
import Headers from "../components/Headers";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import "../style/mix.css";
import { Api_url } from "../environent";
import { useNavigate, useLocation } from "react-router-dom";
import ForgetPassword from "./ForgotPassword";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passShow, setPassShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);

  const toggleForgetPasswordModal = () => {
    setShowForgetPasswordModal(!showForgetPasswordModal);
  };

  const parseJwt = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Invalid JWT", e);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (email === "") {
      toast.error("Enter your email!");
      setLoading(false);
      return;
    } else if (!email.includes("@")) {
      toast.error("Enter a correct email!");
      setLoading(false);
      return;
    } else if (password === "") {
      toast.error("Enter your password!");
      setLoading(false);
      return;
    } else if (password.length < 6) {
      toast.error("Password should be at least 6 characters long!");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(Api_url + "auth/login", {
        email: email,
        password: password,
      });
      console.log(response.data);

      const token = response.data.token;
      localStorage.setItem("token", token);
      const decodeData = parseJwt(token);
      if (decodeData) {
        localStorage.setItem("role", decodeData.role);
        localStorage.setItem("id", decodeData.id);
        window.location.reload();
      } else {
        toast.error("Error decoding token");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error("Error: incorrect email and password");
      }
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    
    if (location !== "/") {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Headers />
      <section style={{ position: "relative" }}>
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        >
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="form_data">
          <div className="form_heading">
            <h1>
              <img
                src={vlaccessLogo}
                alt="VLAcess Logo"
                className="logo-image"
              />
            </h1>
            <div className="App">
              <h3
                style={{
                  fontFamily: "Roboto Slab",
                  fontSize: "24px",
                  paddingTop: "10px",
                  color: "green",
                }}
              >
                LOGIN
              </h3>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form_input">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
              />
            </div>
            <div className="form_input">
              <label htmlFor="password">Password</label>
              <div className="two">
                <input
                  type={passShow ? "text" : "password"}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
                <div
                  className="showpass"
                  onClick={() => setPassShow(!passShow)}
                >
                  <FontAwesomeIcon icon={passShow ? faEye : faEyeSlash} />
                </div>
              </div>
            </div>
            <button className="btn" type="submit" style={{ fontSize: "bold" }}>
              {loading ? <BeatLoader color="red" size={15} /> : "Login"}
            </button>
            <p
              type="button"
              style={{
                color: "black",
                fontWeight: "bold",
                cursor: "pointer",
                marginTop: "10px",
              }}
              onClick={() => navigate("/ForgotPassword")}
            >
              Forgot Password
            </p>
          </form>

          {/* Forget Password Modal */}
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <ForgetPassword />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </section>
    </>
  );
};

export default Login;
