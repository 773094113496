import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";
import "../style/AllUsers.css";
import "../style/style2.css";
import { Api_url } from "../environent";
import Loader from "./loader";
import "../style/button.css";

const AllUsers = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    role: "",
    city:"",
  });

  const getAllUsers = async () => {
    try {
      setLoading(true);
      // Retrieve the role from local storage
      const userRole = localStorage.getItem("role");
  
      // Send the role as a query parameter
      const response = await axios.get(`${Api_url}auth/getAllUsers`, {
        params: { role: userRole },
      });
  
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  

  const deleteUser = async (id) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this user?"
      );
      if (!confirmed) {
        return;
      }

      setLoading(true);
      await axios.put(Api_url + `auth/deactivateUser/${id}`);
      getAllUsers();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const createUser = () => {
    navigate("/dashboard/CreateUsers");
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Logic for pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  // Filtered and paginated users
  const filteredUsers = users.filter((user) => {
    return (
      user.name.toLowerCase().includes(filters.name.toLowerCase()) &&
      user.email.toLowerCase().includes(filters.email.toLowerCase()) &&
      user.phone.toLowerCase().includes(filters.phone.toLowerCase()) &&
      user.address.toLowerCase().includes(filters.address.toLowerCase()) &&
      user.role.toLowerCase().includes(filters.role.toLowerCase()) &&
      user.city.toLowerCase().includes(filters.city.toLowerCase())
    );
  });
  
  
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container-fluid full_div">
      <div className="d-flex justify-content-between">
        <div style={{marginBottom:"10px"}}>
          <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
            All Users
          </p>
        </div>
        <div>
          <Button
            style={{
              backgroundColor: "#21b062",
              color: "#fff",
              fontSize: "14px",
            }}
            onClick={createUser}
            variant="contained"
          >
            Create
          </Button>
        </div>
      </div>

      <div>
        <div className="col" style={{ width: "100%" }}>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive project-list table-bordered">
                      <table className="table table-striped table-centered table-nowrap">
                        <thead>
                          <tr>
                          <th scope="col">Sl. No.</th>
                            <th scope="col" style={{ width: "15%" }}>
                              Name
                              <input
                                type="text"
                                name="name"
                                value={filters.name}
                                onChange={handleFilterChange}
                                className="form-control"
                                placeholder="Search Name"
                              />
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              Email
                              <input
                                type="text"
                                name="email"
                                value={filters.email}
                                onChange={handleFilterChange}
                                className="form-control"
                                placeholder="Search Email"
                              />
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              Phone
                              <input
                                type="text"
                                name="phone"
                                value={filters.phone}
                                onChange={handleFilterChange}
                                className="form-control"
                                placeholder="Search Phone"
                              />
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              City
                              <input
                                type="text"
                                name="city"
                                value={filters.city}
                                onChange={handleFilterChange}
                                className="form-control"
                                placeholder="Search City"
                              />
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              Address
                              <input
                                type="text"
                                name="address"
                                value={filters.address}
                                onChange={handleFilterChange}
                                className="form-control"
                                placeholder="Search Address"
                              />
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              Role
                              <input
                                type="text"
                                name="role"
                                value={filters.role}
                                onChange={handleFilterChange}
                                className="form-control"
                                placeholder="Search Role"
                              />
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers.map((user,index) => (
                            <tr key={user._id}>
                              <td>{index + 1}</td>
                              <td>{user.name}</td>
                              <td>{user.email}</td>
                              <td>{user.phone}</td>
                              <td>{user.city}</td>
                              <td>{user.address}</td>
                              <td>{user.role}</td>
                              <td>
                                <div className="d-flex">
                                  <Link
                                    to={`/dashboard/EditUser/${user._id}`}
                                    className="btn btn-sm btn-outline-success mr-2"
                                    title="Edit"
                                    style={{
                                      fontSize: "18px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </Link>
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() => deleteUser(user._id)}
                                    title="Delete"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="pt-3">
                      <ul
                        className="pagination justify-content-end mb-0"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          left: "0",
                          display: "flex",
                          justifyContent: "center",
                          listStyleType: "none",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          style={{
                            transition: "background-color 0.3s",
                            marginRight: "5px",
                          }}
                        >
                          <button
                            onClick={() =>
                              currentPage > 1 && paginate(currentPage - 1)
                            }
                            className="page-link"
                            tabIndex="-1"
                            aria-disabled="true"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor:
                                currentPage === 1 ? "#ccc" : "#fff",
                            }}
                          >
                            Previous
                          </button>
                        </li>
                        {Array.from(
                          {
                            length: Math.ceil(filteredUsers.length / usersPerPage),
                          },
                          (_, i) => (
                            <li
                              key={i}
                              className={`page-item ${
                                currentPage === i + 1 ? "active" : ""
                              }`}
                              style={{
                                transition: "background-color 0.3s",
                                marginRight: "5px",
                              }}
                            >
                              <button
                                onClick={() => paginate(i + 1)}
                                className="page-link"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  backgroundColor:
                                    currentPage === i + 1 ? "#ccc" : "#fff",
                                }}
                              >
                                {i + 1}
                              </button>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${
                            currentPage ===
                            Math.ceil(filteredUsers.length / usersPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          style={{ transition: "background-color 0.3s" }}
                        >
                          <button
                            onClick={() =>
                              currentPage <
                                Math.ceil(filteredUsers.length / usersPerPage) &&
                              paginate(currentPage + 1)
                            }
                            className="page-link"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor:
                                currentPage ===
                                Math.ceil(filteredUsers.length / usersPerPage)
                                  ? "#ccc"
                                  : "#fff",
                            }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
