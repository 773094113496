import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import vlaccessLogo from './vlaccess_logo_tm.png';
import backgroundVideo from '../style/copywrite.mov';
import Headers from "../components/Headers";
import axios from "axios";
import { NavLink,useNavigate } from "react-router-dom";

import { Api_url } from '../environent';
import "../style/mix.css";
const BallsLoader = () => (
  <div className="balls">
    <div></div>
    <div></div>
    <div></div>
  </div>
);

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(""); 
  const [loading, setLoading] = useState(false); 
  const Navigate= useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      toast.error("Enter your email!");
    } else if (!email.includes("@")) {
      toast.error("Enter a correct email!");
    } else {
      setLoading(true);
      try {
        const response = await axios.post(Api_url + 'auth/ForgotPassword', {
          email: email
        });
  
        if (response.status === 200) {
          const data = response.data;
          setEmail("");
          setMessage(data.message);
          toast.success("Email was sent successfully!");
          setTimeout(() => Navigate('/'), 1000);
        } else if (response.status === 404 && response.data.error === "User not found") {
          toast.error("Email not found in our records. Please enter a valid email ID.");
        } else {
          toast.error("Failed to send email. Please try again later.");
        }
      } catch (error) {
        toast.error("Please enter a valid email ID");
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  


  return (
    <>
      <Headers />
      <section style={{ position: "relative" }}>
        <video autoPlay loop muted style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover", zIndex: -1 }}>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="form_data">
          <div className="form_heading">
            <h1>
              <img src={vlaccessLogo} alt="VLAcess Logo" className="logo-image" />
            </h1>
            &amp;
            <div className="App">
              <h3 style={{ fontFamily: 'Roboto Slab', fontSize: '24px' ,color:"red"}}>FORGOT PASSWORD</h3>
            </div>
          </div>

          {loading && <BallsLoader />} 

          {message && (
            <p style={{ color: "green", fontWeight: "bold" }}>{message}</p>
          )}

          <form onSubmit={handleSubmit}>
            <div className="form_input">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
              />
              
            </div>

            <button className="btn" type="submit" disabled={loading}>
              {loading ? "Loading..." : "Send"}
            </button>
            <p style={{ color: "black", fontWeight: "bold" }}>
              Back to login <NavLink to="/">Click Here</NavLink>
            </p>
          </form>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default ForgetPassword;
