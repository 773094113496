import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Api_url } from "../environent";
import Loader from "./loader";
import "bootstrap/dist/css/bootstrap.min.css";

const ClientAllUsers = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const [searchName, setSearchName] = useState("");
    const [searchEmail, setSearchEmail] = useState("");
    const [searchPhone, setSearchPhone] = useState("");
    const [searchRole, setSearchRole] = useState("");
    const [searchAddress, setSearchAddress] = useState("");

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Adjust items per page as needed

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = localStorage.getItem("token");
                setLoading(true);
                // Decode the token to get the logged-in user's ID
                const clientId = localStorage.getItem("id");                // Fetch all users belonging to the logged-in client
                const response = await axios.get(
                    `${Api_url}ClientUser/client/ClientUsers/${clientId}`, // Adjust API endpoint as needed
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setUsers(response.data || []);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);
    const createUser = () => {
        const clientId = localStorage.getItem("id");
        navigate(`/dashboard/CreateClientUsers/${clientId}`);
    };

    const deleteUser = async (userId) => {
        try {
            const confirmDelete = window.confirm(
                "Are you sure you want to delete this user?"
            );
            if (confirmDelete) {
                const token = localStorage.getItem("token");
                await axios.delete(`${Api_url}ClientUser/deleteClientUserById/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUsers((prevUsers) =>
                    prevUsers.filter((user) => user._id !== userId)
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Filtered and paginated users
    const filteredUsers = users.filter((user) => {
        return (
            user.name.toLowerCase().includes(searchName.toLowerCase()) &&
            user.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
            user.phone.toLowerCase().includes(searchPhone.toLowerCase()) &&
            user.role.toLowerCase().includes(searchRole.toLowerCase()) &&
            user.address.toLowerCase().includes(searchAddress.toLowerCase())
        );
    });

    // Paginate users
    const indexOfLastUser = currentPage * itemsPerPage;
    const indexOfFirstUser = indexOfLastUser - itemsPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    // Handle page change
    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate total pages
    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

    return (
        <div className="container-fluid">
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <div className="d-flex justify-content-between">
                        <div style={{ marginBottom: "10px" }}>
                            <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
                                All Users
                            </p>
                        </div>
                        <div>
                            <Button
                                style={{
                                    backgroundColor: "#21b062",
                                    color: "#fff",
                                    fontSize: "14px",
                                }}
                                onClick={createUser}
                                variant="contained"
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Sl. No.</th>
                                    <th scope="col">
                                        Name
                                        <input
                                            type="text"
                                            placeholder="Search Name"
                                            value={searchName}
                                            onChange={(e) => setSearchName(e.target.value)}
                                            className="form-control"
                                        />
                                    </th>
                                    <th scope="col">
                                        Email
                                        <input
                                            type="text"
                                            placeholder="Search Email"
                                            value={searchEmail}
                                            onChange={(e) => setSearchEmail(e.target.value)}
                                            className="form-control"
                                        />
                                    </th>
                                    <th scope="col">
                                        Phone
                                        <input
                                            type="text"
                                            placeholder="Search Phone"
                                            value={searchPhone}
                                            onChange={(e) => setSearchPhone(e.target.value)}
                                            className="form-control"
                                        />
                                    </th>
                                    {/* <th scope="col">
                                        Role
                                        <input
                                            type="text"
                                            placeholder="Search Role"
                                            value={searchRole}
                                            onChange={(e) => setSearchRole(e.target.value)}
                                            className="form-control"
                                        />
                                    </th> */}
                                    <th scope="col">
                                        Address
                                        <input
                                            type="text"
                                            placeholder="Search Address"
                                            value={searchAddress}
                                            onChange={(e) => setSearchAddress(e.target.value)}
                                            className="form-control"
                                        />
                                    </th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentUsers.length > 0 ? (
                                    currentUsers.map((user, index) => (
                                        <tr key={user._id}>
                                            <td>{index + 1}</td>
                                            <td>{user.name || "NA"}</td>
                                            <td>{user.email || "NA"}</td>
                                            <td>{user.phone || "NA"}</td>
                                            {/* <td>{user.role || "NA"}</td> */}
                                            <td>{user.address || "NA"}</td>
                                            <td>
                                                <div className="d-flex">
                                                    <Link
                                                        className="btn btn-sm btn-outline-primary mr-2"
                                                        to={`/dashboard/AssignProject/${user._id}`} // Ensure the path matches your routing configuration
                                                        title="View"
                                                        style={{
                                                            fontSize: "18px",
                                                            marginRight: "10px",
                                                        }}
                                                    >
                                                        <i className="bi bi-eye"></i>
                                                    </Link>
 
                                                    <Link
                                                        className="btn btn-sm btn-outline-primary me-2"
                                                        to={`/dashboard/EditClientUser/${user._id}`}
                                                        title="Edit"
                                                        style={{ fontSize: "18px" }}
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </Link>
                                                    <button
                                                        className="btn btn-sm btn-outline-danger"
                                                        onClick={() => deleteUser(user._id)}
                                                        title="Delete"
                                                        style={{ fontSize: "18px" }}
                                                    >
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="6"
                                            className="text-center"
                                            style={{ fontSize: "15px" }}
                                        >
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination Controls */}
                    <div className="d-flex justify-content-center mt-4">
                        <nav>
                            <ul className="pagination">
                                <li
                                    className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                                >
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(null, currentPage - 1)}
                                    >
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <li
                                        key={index}
                                        className={`page-item ${currentPage === index + 1 ? "active" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageChange(null, index + 1)}
                                        >
                                            {index + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(null, currentPage + 1)}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClientAllUsers;
