import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Api_url } from "../environent";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const DeviceDetails = () => {
  const { deviceId } = useParams();
  const [device, setDevice] = useState(null);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [isAssigned, setIsAssigned] = useState(false);
  const [unassignReason, setUnassignReason] = useState("");
  const [warrantyStartDate, setWarrantyStartDate] = useState("");
  const [warrantyEndDate, setWarrantyEndDate] = useState("");
  const [errors, setErrors] = useState({});
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        // Fetch device details
        const deviceResponse = await axios.get(
          `${Api_url}device/getDeviceById/${deviceId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setDevice(deviceResponse.data);
        console.log("Device Details:", deviceResponse.data);

        // Fetch device history
        const historyResponse = await axios.get(
          `${Api_url}device/getDeviceHistory/${deviceId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setHistory(historyResponse.data);

        // Check if the device is already assigned
        if (deviceResponse.data.assignedProjectId) {
          setSelectedProject(deviceResponse.data.assignedProjectId);
          setIsAssigned(true);

          // Set warranty dates if they exist
          if (deviceResponse.data.assigned_warranty_start_date) {
            setWarrantyStartDate(
              deviceResponse.data.assigned_warranty_start_date.split("T")[0]
            );
          }
          if (deviceResponse.data.assigned_warranty_end_date) {
            setWarrantyEndDate(
              deviceResponse.data.assigned_warranty_end_date.split("T")[0]
            );
          }
        }

        // Fetch clients
        const clientsResponse = await axios.get(
          `${Api_url}Client/getAllClients`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setClients(clientsResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch device details.");
      }
    };

    if (deviceId) {
      fetchData();
    }
  }, [deviceId]);

  const validateDates = (startDateStr, endDateStr) => {
    let newErrors = {};

    if (startDateStr && endDateStr) {
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (endDate < startDate) {
        newErrors.warrantyEndDate =
          "Warranty End Date cannot be earlier than Start Date";
      } else if (endDate.getTime() === startDate.getTime()) {
        newErrors.warrantyEndDate =
          "Warranty End Date cannot be the same as Start Date";
      } else if (endDate < today) {
        newErrors.warrantyEndDate = "Warranty End Date cannot be in the past";
      }
    } else {
      if (!startDateStr) {
        newErrors.warrantyStartDate = "Warranty Start Date is required";
      }
      if (!endDateStr) {
        newErrors.warrantyEndDate = "Warranty End Date is required";
      }
    }

    setErrors(newErrors);
  };

  const handleWarrantyStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setWarrantyStartDate(newStartDate);
    validateDates(newStartDate, warrantyEndDate);
  };

  const handleWarrantyEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setWarrantyEndDate(newEndDate);
    validateDates(warrantyStartDate, newEndDate);
  };

  const isFormValid = () => {
    return Object.keys(errors).length === 0;
  };

  const handleAssignDevice = async () => {
    // Check if the form is valid before proceeding
    if (!isAssigned && !isFormValid()) {
      toast.error("Please fix the errors before submitting.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("id"); // Assume userId is stored in localStorage

      // Perform the assign or unassign action based on the current state
      if (isAssigned) {
        // Unassign the device from the project
        await axios.post(
          `${Api_url}device/unassignFromProject`,
          { deviceId, reason: unassignReason, userId }, // Include userId here
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        // Update state after successful unassignment
        setIsAssigned(false);
        setSelectedProject("");
        setUnassignReason("");
        setWarrantyStartDate("");
        setWarrantyEndDate("");
        toast.success("Device unassigned successfully!");
      } else {
        // Assign the device to a project
        await axios.post(
          `${Api_url}device/assignToProject`,
          {
            deviceId,
            projectId: selectedProject,
            warrantyStartDate,
            warrantyEndDate,
            userId, // Include userId here
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        // Update state after successful assignment
        setIsAssigned(true);
        setSelectedClient(""); // Clear selected client if needed
        setSelectedProject("");
        setWarrantyStartDate("");
        setWarrantyEndDate("");
        toast.success("Device assigned successfully!");
      }

      // Fetch the updated device details and history
      const [deviceResponse, historyResponse] = await Promise.all([
        axios.get(`${Api_url}device/getDeviceById/${deviceId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${Api_url}device/getDeviceHistory/${deviceId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      // Update state with fetched data
      setDevice(deviceResponse.data);
      setHistory(historyResponse.data);
    } catch (error) {
      // Log error and show a toast notification
      console.error("Error assigning/unassigning device:", error);
      toast.error("Failed to assign/unassign device.");
    }
  };

  const handleClientChange = async (clientId) => {
    setSelectedClient(clientId);
    setSelectedProject("");
    try {
      const token = localStorage.getItem("token");

      const projectsResponse = await axios.get(
        `${Api_url}Client/getProjectsByClientId/${clientId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProjects(projectsResponse.data.projects);
    } catch (error) {
      console.error("Error fetching projects:", error);
      toast.error("Failed to fetch projects.");
    }
  };

  return (
    <>
      <div className="container mt-4">
        <ToastContainer />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-success text-white text-center">
                <h4>Device Details</h4>
              </div>
              <div className="card-body">
                {device ? (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card mb-3">
                        <div className="card-body text-left">
                          <h5 className="card-title">Device Details</h5>
                          <p className="card-text">
                            Name: {device.Device_name || "N/A"}
                          </p>
                          <p className="card-text">
                            Make: {device.make || "N/A"}
                          </p>
                          <p className="card-text">
                            MAC Address: {device.mac || "N/A"}
                          </p>
                          <p className="card-text">
                            Serial Number: {device.serial_no || "N/A"}
                          </p>
                          <p className="card-text">
                            Model Number: {device.model_no || "N/A"}
                          </p>
                          <p className="card-text">
                            Category: {device.categoryDetails.name || "N/A"}
                          </p>
                          <p className="card-text">
                            OME Warranty Start Date:{" "}
                            {device.warranty &&
                            device.warranty.ome_warranty_start_date
                              ? new Date(
                                  device.warranty.ome_warranty_start_date
                                ).toLocaleDateString()
                              : "N/A"}
                          </p>
                          <p className="card-text">
                            OME Warranty End Date:{" "}
                            {device.warranty &&
                            device.warranty.ome_warranty_end_date
                              ? new Date(
                                  device.warranty.ome_warranty_end_date
                                ).toLocaleDateString()
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* device photo and documents */}
                    <div className="col-md-6">
                      <div className="card mb-3">
                        <div className="card-body">
                          <h5 className="card-title">
                            Device Photos & Documents
                          </h5>
                          <div className="row">
                            {
                              // Validate if device and history data exist
                              device &&
                              device.history &&
                              device.history.length > 0 ? (
                                // Validate if documents array exists and has items
                                device.history[0].new_data.documents &&
                                device.history[0].new_data.documents.length >
                                  0 ? (
                                  device.history[0].new_data.documents.map(
                                    (doc, index) => {
                                      // Construct the file URL
                                      const fileUrl = `${Api_url}${doc.filePath.replace(
                                        /\\/g,
                                        "/"
                                      )}`;

                                      return (
                                        <div
                                          className="col-md-4 mb-3"
                                          key={index}
                                        >
                                          <div
                                            className="card"
                                            style={{ height: "100px" }}
                                          >
                                            <div className="card-body p-2">
                                              {doc.fileType.includes(
                                                "image/"
                                              ) ? (
                                                <button
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    border: "none",
                                                    backgroundColor: "#f8f9fa",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    window.open(
                                                      fileUrl,
                                                      "_blank"
                                                    )
                                                  }
                                                >
                                                  <p
                                                    className="text-center"
                                                    style={{ margin: 0 }}
                                                  >
                                                    Image
                                                  </p>
                                                </button>
                                              ) : doc.fileType ===
                                                "application/pdf" ? (
                                                <button
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    border: "none",
                                                    backgroundColor: "#f8f9fa",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    window.open(
                                                      fileUrl,
                                                      "_blank"
                                                    )
                                                  }
                                                >
                                                  <p
                                                    className="text-center"
                                                    style={{ margin: 0 }}
                                                  >
                                                    PDF
                                                  </p>
                                                </button>
                                              ) : (
                                                <p>Unsupported file type</p>
                                              )}
                                            </div>
                                            <div className="card-body p-1">
                                              <p
                                                className="text-truncate"
                                                style={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                }}
                                              >
                                                {/* {doc.fileName || "No file name"} */}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <p>No photos or documents available</p>
                                )
                              ) : (
                                <p>
                                  Invalid device or no history data available
                                </p>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>Loading device details...</p>
                )}
                {/* unassighn div */}
                <div className="mt-3">
                  {isAssigned ? (
                    <div>
                      <div className="form-group">
                        <label htmlFor="unassignReason">
                          Reason for Unassigning
                        </label>
                        <input
                          type="text"
                          id="unassignReason"
                          className="form-control"
                          value={unassignReason}
                          onChange={(e) => setUnassignReason(e.target.value)}
                        />
                      </div>
                      <button
                        className="btn btn-danger mt-3"
                        onClick={handleAssignDevice}
                      >
                        Unassign
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div className="row" style={{ marginBottom: "20px" }}>
                        <div className="col-md-6">
                          <div className="form-select">
                            <label htmlFor="clientSelect">Client</label>
                            <select
                              id="clientSelect"
                              className="form-control pb-0 pt-0"
                              value={selectedClient}
                              onChange={(e) =>
                                handleClientChange(e.target.value)
                              }
                            >
                              <option value="">Select Client</option>
                              {Array.isArray(clients) &&
                                clients.map((client) => (
                                  <option key={client._id} value={client._id}>
                                    {client.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-select">
                            <label htmlFor="projectSelect">Project</label>
                            <select
                              id="projectSelect"
                              className="form-control pb-0 pt-0"
                              value={selectedProject}
                              onChange={(e) =>
                                setSelectedProject(e.target.value)
                              }
                              disabled={!selectedClient}
                            >
                              <option value="">Select Project</option>
                              {Array.isArray(projects) &&
                                projects.map((project) => (
                                  <option key={project._id} value={project._id}>
                                    {project.Project_Name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* warenty calender */}
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="warrantyStartDate">
                            Warranty Start Date
                          </label>
                          <input
                            type="date"
                            id="warrantyStartDate"
                            className="form-control"
                            value={warrantyStartDate}
                            onChange={handleWarrantyStartDateChange}
                            required
                          />
                          {errors.warrantyStartDate && (
                            <div className="text-danger">
                              {errors.warrantyStartDate}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="warrantyEndDate">
                            Warranty End Date
                          </label>
                          <input
                            type="date"
                            id="warrantyEndDate"
                            className="form-control"
                            value={warrantyEndDate}
                            onChange={handleWarrantyEndDateChange}
                            required
                          />
                          {errors.warrantyEndDate && (
                            <div className="text-danger">
                              {errors.warrantyEndDate}
                            </div>
                          )}
                        </div>
                      </div>
                      <button
                        className="btn btn-primary mt-3"
                        onClick={handleAssignDevice}
                      >
                        Assign
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="card mb-12">
          <div className="card-header bg-info text-white text-center">
            <h5>Device History</h5>
          </div>
          <div className="card-body text-left">
            <div
              className="overflow-auto"
              style={{ maxHeight: "400px", position: "relative" }}
            >
              {history.length > 0 ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Action</th>
                      <th>Device Name</th>
                      <th>Assigned Warranty Start Date</th>
                      <th>Assigned Warranty End Date</th>
                      <th>Assigned By</th>
                      <th>Unassigned By</th>
                      <th>Reason for Unassignment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((entry, index) => (
                      <tr key={index}>
                        <td>{new Date(entry.date).toLocaleDateString()}</td>
                        <td>{entry.action}</td>
                        <td>{entry.new_data?.Device_name || "N/A"}</td>
                        <td>
                          {entry.new_data?.assigned_warranty_start_date
                            ? new Date(
                                entry.new_data.assigned_warranty_start_date
                              ).toLocaleDateString()
                            : "N/A"}
                        </td>
                        <td>
                          {entry.new_data?.assigned_warranty_end_date
                            ? new Date(
                                entry.new_data.assigned_warranty_end_date
                              ).toLocaleDateString()
                            : "N/A"}
                        </td>
                        <td>{entry.assigned_by?.name || "N/A"}</td>
                        <td>{entry.unassigned_by?.name || "N/A"}</td>
                        <td>
                          {entry.action === "Unassigned" &&
                          entry.new_data?.unassign_reason
                            ? entry.new_data.unassign_reason
                            : "N/A"}
                        </td>{" "}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No history available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceDetails;
