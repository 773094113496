import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Pagination } from "@mui/material";
import { useNavigate , Link} from "react-router-dom";
import "../style/AllUsers.css";
import "../style/style2.css";
import { Api_url } from "../environent";
import Loader from "./loader";

const ClientUserProjects = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [projectsPerPage] = useState(5); // Projects per page

  // Search state for each column
  const [searchProjectName, setSearchProjectName] = useState("");
  const [searchProjectSite, setSearchProjectSite] = useState("");
  const [searchProjectLocation, setSearchProjectLocation] = useState("");
  const [searchProjectType, setSearchProjectType] = useState("");

  useEffect(() => {
    const fetchClientProjects = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const clientUserId = localStorage.getItem("id");
        if (!clientUserId) {
          throw new Error("No client ID found in localStorage");
        }

        const response = await axios.get(`${Api_url}project/client-user/${clientUserId}/projects`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Access the projects array from the response
        setProjects(response.data.projects || []);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchClientProjects();
  }, [navigate]);

  // Filtered projects based on search fields
  const filteredProjects = projects.filter((project) =>
    project.Project_Name.toLowerCase().includes(searchProjectName.toLowerCase()) &&
    project.project_site.toLowerCase().includes(searchProjectSite.toLowerCase()) &&
    project.project_location.toLowerCase().includes(searchProjectLocation.toLowerCase()) &&
    project.project_type.toLowerCase().includes(searchProjectType.toLowerCase())
  );

  // Calculate the indices of the projects to show on the current page
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  // Handle pagination change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="container-fluid full_div">
      <div>
        <p style={{ color: "#21b062", fontWeight: "bold", fontSize: "20px" }}>
          Your Projects
        </p>
      </div>

      <div>
        <div className="col" style={{ width: "100%" }}>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive project-list table-bordered">
                      <table className="table project-table table-centered table-nowrap">
                        <thead>
                          <tr>
                            <th>Sl. No.</th>
                            <th>
                              Project Name
                              <input
                                type="text"
                                placeholder="Search Project Name"
                                value={searchProjectName}
                                onChange={(e) => setSearchProjectName(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th>
                              Project Site
                              <input
                                type="text"
                                placeholder="Search Project Site"
                                value={searchProjectSite}
                                onChange={(e) => setSearchProjectSite(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th>
                              Project Location
                              <input
                                type="text"
                                placeholder="Search Project Location"
                                value={searchProjectLocation}
                                onChange={(e) => setSearchProjectLocation(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th>
                              Project Type
                              <input
                                type="text"
                                placeholder="Search Project Type"
                                value={searchProjectType}
                                onChange={(e) => setSearchProjectType(e.target.value)}
                                className="form-control"
                              />
                            </th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentProjects.map((project, index) => (
                            <tr key={project._id}>
                              <td>{indexOfFirstProject + index + 1}</td>
                              <td>{project.Project_Name}</td>
                              <td>{project.project_site}</td>
                              <td>{project.project_location}</td>
                              <td>{project.project_type}</td>
                              <td>
                                <Link
                                  to={`/dashboard/projectDevices/${project._id}`}
                                  className="btn btn-sm btn-outline-success mr-2"
                                  title="Edit"
                                  style={{
                                    fontSize: "18px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <i className="bi bi-eye"></i>
                                </Link>
                                <Link
                                  to={`/dashboard/EditProject/${project._id}`}
                                  style={{
                                    fontSize: "18px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <i className="bi bi-pencil"></i>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Pagination Component */}
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                      <Pagination
                        count={Math.ceil(filteredProjects.length / projectsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientUserProjects;
