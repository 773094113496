import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  IconButton,
  Grid,
  AppBar,
  Toolbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Api_url } from '../environent';
import CloseIcon from '@mui/icons-material/Close';

const AddCategories = () => {
  const [name, setName] = useState('');
  const [categories, setCategories] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  // Fetch all categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${Api_url}Category/AllCategories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Add the category
      await axios.post(`${Api_url}Category/createCategory`, { name });

      // Fetch the updated list of categories
      const updatedCategories = await axios.get(`${Api_url}Category/AllCategories`);
      setCategories(updatedCategories.data); // Update the state with new categories

      toast.success('Category added successfully');
      setName('');
    } catch (error) {
      console.error('Error adding category:', error);
      toast.error('Error adding category');
    }
  };

  // Open delete confirmation dialog
  const handleDeleteClick = (id) => {
    setCategoryToDelete(id);
    setOpenDialog(true);
  };

  // Handle category deletion
  const handleDelete = async () => {
    try {
      await axios.delete(`${Api_url}Category/DeleteCategoriesById/${categoryToDelete}`);
      
      // Fetch the updated list of categories after deletion
      const updatedCategories = await axios.get(`${Api_url}Category/AllCategories`);
      setCategories(updatedCategories.data); // Update the state with new categories

      toast.success('Category deleted successfully');
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Error deleting category');
    } finally {
      setOpenDialog(false);
      setCategoryToDelete(null);
    }
  };

  // Close dialog without deleting
  const handleClose = () => {
    setOpenDialog(false);
    setCategoryToDelete(null);
  };

  return (
    <div style={{ display: 'flex', padding: '20px' }}>
      <Card style={{ flex: 1, marginRight: '20px', height: '200px' }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Add Category
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Category Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              fullWidth
              margin="normal"
              InputProps={{
                style: {
                  fontSize: '16px',
                  color: 'green',
                  backgroundColor: 'white'
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: '16px',
                  color: 'green',
                },
              }}
            />
            <Button type="submit" variant="contained" color="primary" style={{ backgroundColor: 'green', color: 'white' }}>
              Add Category
            </Button>
          </form>
        </CardContent>
      </Card>
      <Card style={{ flex: 2, height: '200px', overflowY: 'auto', position: 'relative' }}>
        <CardContent>
          <AppBar position="sticky" style={{ backgroundColor: 'white', boxShadow: 'none', borderBottom: '1px solid #ddd' }}>
            <Toolbar>
              <Typography variant="h5" component="div" style={{ fontSize: '30px', color: 'green' }}>
                Category List
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            {categories.map((category) => (
              <Grid item xs={12} sm={6} md={4} key={category._id}>
                <Card style={{ padding: '10px', backgroundColor: 'lightgreen', borderRadius: '5px', position: 'relative' }}>
                  <IconButton
                    onClick={() => handleDeleteClick(category._id)}
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      backgroundColor: 'white',
                      color: 'red',
                      borderRadius: '50%',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                      padding: '5px'
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" component="div">
                    {category.name}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="confirm-delete-dialog"
      >
        <DialogTitle id="confirm-delete-dialog">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
};

export default AddCategories;
