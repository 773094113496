import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../style/button.css";
import { Api_url } from '../environent';

const EditClientUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    city: '',
    phone: '',
    address: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    city: '',
    phone: '',
    address: '',
  });

  // Retrieve the token from local storage (or from any other secure storage)
  const token = localStorage.getItem('token'); // Ensure this matches your token storage logic

  useEffect(() => {
    const fetchClientUserData = async () => {
      try {
        const response = await axios.get(`${Api_url}ClientUser/getClientUserById/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: { id }
        });
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching client user data:', error);
        toast.error('Failed to fetch client user data.');
      }
    };

    fetchClientUserData();
  }, [id, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validation
    switch (name) {
      case 'name':
        setErrors({ ...errors, name: value.length < 3 ? 'Name must be at least 3 characters long' : '' });
        break;
      case 'city':
        setErrors({ ...errors, city: '' });
        break;
      case 'phone':
        setErrors({
          ...errors,
          phone: !/^\d{10}$/.test(value) ? 'Please enter a valid 10-digit phone number' : '',
        });
        break;
      case 'address':
        setErrors({ ...errors, address: '' });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty required fields
    const requiredFields = ['name', 'email', 'city', 'phone', 'address'];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      toast.error(`Please enter ${missingFields.join(', ')}.`);
      return;
    }

    // Check if there are any errors
    if (Object.values(errors).some((error) => error !== '')) {
      toast.error('Form has errors. Please fix them before submitting.');
      return;
    }

    try {
      await axios.put(`${Api_url}ClientUser/updateClientUserById/${id}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      toast.success('Client user updated successfully.');
      navigate(-1);
    } catch (error) {
      console.error('Error editing client user:', error);
      toast.error('Failed to update client user data.');
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mt-4">
      <h2 style={{ backgroundColor: '#21b062', color: 'white', fontWeight: 'bold', padding: '5px', borderRadius: '4px', fontSize: '20px' }}>
        Edit Client User
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-md-6">
            <label>
              Name<span className="required">*</span>:
            </label>
            <input
              type="text"
              className={`form-control ${errors.name && 'is-invalid'}`}
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
          </div>
          <div className="form-group col-md-6">
            <label>
              City<span className="required">*</span>:
            </label>
            <input
              type="text"
              className={`form-control ${errors.city && 'is-invalid'}`}
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
            {errors.city && <div className="invalid-feedback">{errors.city}</div>}
          </div>
          <div className="form-group col-md-6">
            <label>
              Phone<span className="required">*</span>:
            </label>
            <input
              type="text"
              className={`form-control ${errors.phone && 'is-invalid'}`}
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
          </div>
          <div className="form-group col-md-6">
            <label>
              Address<span className="required">*</span>:
            </label>
            <input
              type="text"
              className={`form-control ${errors.address && 'is-invalid'}`}
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
            {errors.address && <div className="invalid-feedback">{errors.address}</div>}
          </div>
        </div>

        <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
          <button type="submit" className="custom-button">
            Update
          </button>
          <button
            type="button"
            className="custom-button"
            style={{ marginLeft: '10px' }}
            onClick={handleBack}
          >
            Back
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditClientUser;
