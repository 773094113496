import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Api_url } from "../environent";

const CreateTicket = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    description: "",
    priority: "",
    file: null,
  });
  const [formErrors, setFormErrors] = useState({
    description: "",
    priority: "",
    file: "",
  });

  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [devices, setDevices] = useState([]);

  const [selectedClient, setSelectedClient] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");
  const [errorType, setErrorType] = useState("");
  const [clientData, setClientData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [deviceData, setDeviceData] = useState({});
  const [image, setImage] = useState(null);

  const [categories, setCategories] = useState([]);
  const [resdevices, setResDevices] = useState([]);
  const [categoryids, setCategoryids] = useState("");
  const [deviceids, setDeviceids] = useState("");
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(Api_url + "Client/getAllClients", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClients(response.data.data);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchClientData();
  }, []);

  useEffect(() => {
    if (selectedProject) {
      const fetchCategories = async () => {
        try {
          const response = await axios.get(Api_url + "Category/getCategoriesInProject/" + selectedProject);
          setCategories(response.data);
        } catch (error) {
          console.error("Error fetching client data:", error);
        }
      };

      fetchCategories();
    } else {
      setCategories([]);
    }
  }, [selectedProject]);


  useEffect(() => {
    if (selectedProject && categoryids) {
      const fetchDevices = async () => {
        try {
          const response = await axios.get(Api_url + "device/getDevicesByProjectAndCategory/" + selectedProject + "/" + categoryids);
          setResDevices(response.data);
        } catch (error) {
          console.error("Error fetching client data:", error);
        }
      };

      fetchDevices();
    } else {
      setResDevices([]);
    }
  }, [selectedProject, categoryids]);

  useEffect(() => {
    if (selectedClient) {
      const fetchProjectData = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(Api_url + `Client/getProjectsByClientId/${selectedClient}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setProjects(response.data.projects);
        } catch (error) {
          console.error("Error fetching project data:", error);
        }
      };

      const fetchClientDetails = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(Api_url + `Client/getClientById/${selectedClient}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setClientData(response.data);
        } catch (error) {
          console.error("Error fetching client details:", error);
        }
      };

      fetchProjectData();
      fetchClientDetails();
    } else {
      setProjects([]);
      setClientData({});
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedProject) {
      const fetchDeviceData = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(Api_url + `device/getDevicesByProject/${selectedProject}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setDevices(response.data);
        } catch (error) {
          console.error("Error fetching device data:", error);
        }
      };

      const fetchProjectDetails = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(Api_url + `Project/getProjectById/${selectedProject}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setProjectData(response.data);
        } catch (error) {
          console.error("Error fetching project details:", error);
        }
      };

      fetchDeviceData();
      fetchProjectDetails();
    } else {
      setDevices([]);
      setProjectData({});
    }
  }, [selectedProject]);

  useEffect(() => {
    if (selectedDevice) {
      const fetchDeviceDetails = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(Api_url + `Device/getDeviceById/${selectedDevice}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setDeviceData(response.data);
        } catch (error) {
          console.error("Error fetching device details:", error);
        }
      };

      fetchDeviceDetails();
    } else {
      setDeviceData({});
    }
  }, [selectedDevice]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;

    if (name === "clientName") {
      setSelectedClient(value);
      setSelectedProject("");
      setSelectedDevice("");
    } else if (name === "projectName") {
      setSelectedProject(value);
      setSelectedDevice("");
    } else if (name === "deviceName") {
      setSelectedDevice(value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = { ...formErrors };

    switch (name) {
      case "description":
        errors.description = value.length < 10 ? "Description must be at least 10 characters long" : "";
        break;
      case "priority":
        errors.priority = value === "" ? "Priority is required" : "";
        break;
      default:
        break;
    }

    setFormErrors(errors);
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
    setFormErrors({ ...formErrors, file: "" }); // Clear file error if file is selected
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newErrors = { ...formErrors };

    // Validate form
    if (formData.description.length < 10) {
      newErrors.description = "Description must be at least 10 characters long";
    }

    if (formData.priority === "") {
      newErrors.priority = "Priority is required";
    }

    if (!image) {
      newErrors.file = "Image is required";
    }

    setFormErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== "")) {
      toast.error("Please fix the form errors before submitting.");
      setLoading(false);
      return;
    }

    if (!selectedClient || !selectedProject || !formData.description || !formData.priority || !errorType) {
      toast.error("Please fill in all required fields.");
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const formDataObject = new FormData();
      formDataObject.append("companyId", selectedClient);
      formDataObject.append("projectId", selectedProject);
      formDataObject.append("deviceId", selectedDevice);
      formDataObject.append("errorDetails", formData.description);
      formDataObject.append("priority", formData.priority);
      formDataObject.append("errorType", errorType);
      formDataObject.append("createdBy", localStorage.getItem("id") || "");
      formDataObject.append("role", localStorage.getItem("role") || "");
      if (image) {
        formDataObject.append("image", image);
      }

      const response = await axios.post(Api_url + "Ticket/createTicket", formDataObject, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Success:", response.data);

      setFormData({
        description: "",
        priority: "",
        file: null,
      });
      setSelectedClient("");
      setSelectedProject("");
      setSelectedDevice("");
      setErrorType("");
      setImage(null);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while creating the ticket.");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mt-4">
      <ToastContainer />
      <h2
        style={{
          backgroundColor: "#21b062",
          color: "white",
          fontWeight: "bold",
          padding: "5px",
          borderRadius: "4px",
          fontSize: "20px",
        }}
      >
        Create Ticket
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-md-6">
            <label>
              Company Name<span className="required">*</span>
            </label>
            <select
              className="form-select form-select-lg"
              name="clientName"
              value={selectedClient}
              onChange={handleDropdownChange}
            >
              <option value="" disabled>Select Client</option>
              {clients.map((client) => (
                <option key={client._id} value={client._id}>
                  {client.companyName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-6">
            <label>
              Project Name<span className="required">*</span>
            </label>
            <select
              className="form-select form-select-lg"
              name="projectName"
              value={selectedProject}
              onChange={handleDropdownChange}
            >
              <option value="" disabled>Select Project</option>
              {projects.map((project) => (
                <option key={project._id} value={project._id}>
                  {project.Project_Name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-6">
            <label>
              Category Name<span className="required">*</span>
            </label>
            <select
              className="form-select form-select-lg"
              name="Category"
              value={categoryids}
              onChange={(e) => { setCategoryids(e.target.value) }}
            >
              <option value="" disabled>Select Category</option>
              {categories.map((cat) => (
                <option key={cat._id} value={cat._id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-6">
            <label>
              Device Name<span className="required">*</span>
            </label>
            <select
              className="form-select form-select-lg"
              name="deviceName"
              value={selectedDevice}
              onChange={handleDropdownChange}
            >
              <option value="" disabled>Select Device</option>
              {devices.map((device) => (
                <option key={device._id} value={device._id}>
                  {device.Device_name}
                </option>
              ))}
            </select>
          </div>
          {selectedDevice && (
            <div className="device-details">
              {/* <h3>Device Details</h3> */}

              <div className="form-group col-md-6">
                <label htmlFor="deviceMake">Make</label>
                <input
                  type="text"
                  className="form-control"
                  id="deviceMake"
                  value={deviceData.make || ''}
                  readOnly
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="deviceModelNo">Model No</label>
                <input
                  type="text"
                  className="form-control"
                  id="deviceModelNo"
                  value={deviceData.model_no || ''}
                  readOnly
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="deviceSerialNo">Serial No</label>
                <input
                  type="text"
                  className="form-control"
                  id="deviceSerialNo"
                  value={deviceData.serial_no || ''}
                  readOnly
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="deviceMAC">MAC Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="deviceMAC"
                  value={deviceData.mac || 'N/A'}
                  readOnly
                />
              </div>
              {/* Add more fields as needed */}
            </div>
          )}
          <div className="form-group col-md-6">
            <label>
              Error Type<span className="required">*</span>
            </label>
            <select
              className="form-select form-select-lg"
              name="errorType"
              value={errorType}
              onChange={(e) => setErrorType(e.target.value)}
            >
              <option value="" disabled>Select Error Type</option>
              <option value="Hardware">Hardware</option>
              <option value="Software">Software</option>
              <option value="Network">Network</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group col-md-6">
            <label>
              Description<span className="required">*</span>
            </label>
            <textarea
              className="form-control"
              rows="3"
              name="description"
              value={formData.description}
              onChange={handleChange}
            ></textarea>
            {formErrors.description && <p className="text-danger">{formErrors.description}</p>}
          </div>
          <div className="form-group col-md-6">
            <label>
              Priority<span className="required">*</span>
            </label>
            <select
              className="form-select form-select-lg"
              name="priority"
              value={formData.priority}
              onChange={handleChange}
            >
              <option value="" disabled>Select Priority</option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
            {formErrors.priority && <p className="text-danger">{formErrors.priority}</p>}
          </div>
          <div className="form-group col-md-6">
            <label>
              Image<span className="required">*</span>
            </label>
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              className="form-control"
              name="file"
              onChange={handleFileChange}
            />
            {formErrors.file && <p className="text-danger">{formErrors.file}</p>}
          </div>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              type="submit"
              className={`custom-button ${loading ? "loading" : ""}`}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Creating..." : "Create"}
            </button>
            <button
              type="button"
              className="custom-button"
              onClick={handleBack}
              style={{ marginLeft: "10px" }}
            >
              Back
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateTicket;
