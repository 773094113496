import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import vlaccessLogo from './vlaccess_logo_tm.png';
import Headers from '../components/Headers';
import axios from 'axios';
import { Api_url } from '../environent';
import '../style/mix.css';
import backgroundVideo from '../style/copywrite.mov';


const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passShow, setPassShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    const token = new URLSearchParams(location.search).get('token');

    try {
      const response = await axios.post(`${Api_url}auth/updatePassword`, {
        token,
        password,
      });

      if (response.status === 200) {
        toast.success('Password updated successfully');
        navigate('/');
      } else {
        toast.error('Error updating password');
      }
    } catch (error) {
      toast.error('Error updating password');
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Headers />
      <section>
      <video autoPlay loop muted style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover", zIndex: -1 }}>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="form_data">
          <div className="form_heading">
            <h1>
              <img src={vlaccessLogo} alt="VLAcess Logo" className="logo-image" />
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form_input">
              <label htmlFor="password">New Password</label>
              <div className="two">
                <input
                  type={passShow ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your new password"
                  minLength={6}
                />
                <div className="showpass" onClick={() => setPassShow(!passShow)}>
                  <FontAwesomeIcon icon={passShow ? faEye : faEyeSlash} />
                </div>
              </div>
            </div>
            <div className="form_input">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <div className="two">
                <input
                  type={passShow ? 'text' : 'password'}
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm your new password"
                  minLength={6}
                />
                <div className="showpass" onClick={() => setPassShow(!passShow)}>
                  <FontAwesomeIcon icon={passShow ? faEye : faEyeSlash} />
                </div>
              </div>
            </div>
            <button className="btn" type="submit">
              Save Password
            </button>
          </form>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default ResetPassword;
